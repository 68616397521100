// 推送管理
import Request from '../utils/requestAxios';

class PushManage extends Request {
  /**
   * 推送列表
   * @param {*} params
   */
  getPushList(params) {
    return this.fetch({
      url: '/push/list',
      method: 'get',
      params,
    });
  }

  /**
   * 脑卒中推送列表
   * @param {*} params
   */
  getStrokePushList(params) {
    return this.fetch({
      url: '/push/ncz_list',
      method: 'get',
      params,
    });
  }

  /**
   * 推送至脑卒中
   * @param {*} params
   */
  pushStroke(params) {
    return this.fetch({
      url: '/push/ncz_push',
      method: 'post',
      params,
    });
  }
}

export default new PushManage();

import {
  lazy,
} from 'react';

const Role = lazy(() => import('../page/system/role'));
const Person = lazy(() => import('../page/system/person'));
const PersonEdit = lazy(() => import('../page/system/person/detail'));
const Offline = lazy(() => import('../page/system/offline'));
const MyInformationList = lazy(() => import('../page/information/myInfoIndex'));
const RealTImeCapture = lazy(() => import('../page/information/realTImeCapture'));
const mediaCapture = lazy(() => import('../page/information/mediaCapture'));
const Audit = lazy(() => import('../page/information/audit'));
const Settings = lazy(() => import('../page/information/settings'));
const Article = lazy(() => import('../page/information/article'));
const Video = lazy(() => import('../page/information/video'));
const HealthNumberList = lazy(() => import('../page/healthNumber/healthNumberList'));
const HealthNumberAuditList = lazy(() => import('../page/healthNumber/healthNumberAuditList'));
const HealthNumberHospAuditList = lazy(() => import('../page/healthNumber/healthNumberHospAuditList'));
const HealthHospDetail = lazy(() => import('../page/healthNumber/healthNumberHospAuditList/detail'));
const Integrallist = lazy(() => import('../page/healthNumber/integrallist'));
const Authentication = lazy(() => import('../page/healthNumber/authentication'));
const MyNumber = lazy(() => import('../page/healthNumber/mynumber'));
const CheckMediaList = lazy(() => import('../page/healthNumber/checkmediaLists'));
const CheckNumber = lazy(() => import('../page/healthNumber/checkhealth'));
const HealthDetail = lazy(() => import('../page/healthNumber/healthdetail'));
const EditHealthDetail = lazy(() => import('../page/healthNumber/editHealthNumber'));
const EditHospInfo = lazy(() => import('../page/healthNumber/hospInfoEdit.jsx'));
const ShowHospInfo = lazy(() => import('../page/healthNumber/hospInfoShow.jsx'));
const MyShowHospInfo = lazy(() => import('../page/healthNumber/myHospInfoShow.jsx'));
const MyEditHospInfo = lazy(() => import('../page/healthNumber/myHospInfoEdit.jsx'));
const SetLevel = lazy(() => import('../page/healthNumber/setLevel'));
const CreateAction = lazy(() => import('../page/action/create'));
const MyAction = lazy(() => import('../page/action/myaction'));
const Select = lazy(() => import('../page/action/select'));
const PreviewAction = lazy(() => import('../page/action/previewAction'));
const CheckAction = lazy(() => import('../page/action/checkaction'));
const CheckActionDetail = lazy(() => import('../page/action/checkactiondetail'));
const CheckPeople = lazy(() => import('../page/action/checkpeople'));
const CreateWork = lazy(() => import('../page/action/myWorks/detail')); // 创建作品
const MyWorkList = lazy(() => import('../page/action/myWorks/list')); // 作品列表
const CheckWorkList = lazy(() => import('../page/action/checkWorks/list')); // 作品列表
const LocalChannelList = lazy(() => import('../page/channel/list/list')); // 地方频道
const LocalChannelDetail = lazy(() => import('../page/channel/detail/detail'));
const LocalChannelSetting = lazy(() => import('../page/channel/setting'));
const LocalChannelPreview = lazy(() => import('../page/channel/preview'));
const Complaint = lazy(() => import('../page/complaint')); // 申诉管理
// const HandleComplaint = lazy(() => import('../page/complaint/handle')); // 申诉管理
// const ComplaintDetail = lazy(() => import('../page/complaint/complaintdetail')); // 申诉详情
const TopicList = lazy(() => import('../page/topic/list'));
const TopicDetail = lazy(() => import('../page/topic/detail'));
const TopicContentList = lazy(() => import('../page/topic/contentList'));
const TopicArticleSource = lazy(() => import('../page/topic/sourceList'));
const ChannelArticleSource = lazy(() => import('../page/channel/information/addList'));
const Userinfo = lazy(() => import('../page/user')); // 个人资料
const DoctorRank = lazy(() => import('../page/productSetting/doctorRank')); // 健康中国医者先行 排行榜
const PopIns = lazy(() => import('../page/productSetting/popIns')); // 热门机构
const PopDoctor = lazy(() => import('../page/productSetting/popDoctor')); // 科普达人
const CarouselList = lazy(() => import('../page/productSetting/carousel')); // 轮播图列表
const CarouselDetail = lazy(() => import('../page/productSetting/carousel/detail')); // 轮播图详情页
const AppPosterList = lazy(() => import('../page/productSetting/app-poster')); // App开屏图列表
const AppPosterDetail = lazy(() => import('../page/productSetting/app-poster/detail')); // App开屏图详情页
const TopicConfig = lazy(() => import('../page/productSetting/topic-config')); // 主题切换
const BeltlList = lazy(() => import('../page/productSetting/belt/list')); // 专网腰封列表
const BeltlDetail = lazy(() => import('../page/productSetting/belt/detail')); // 专网腰封详情页

const PushManageList = lazy(() => import('../page/productSetting/pushManage/index')); // 推送管理页
const StrokeManageList = lazy(() => import('../page/push-manage/stroke')); // 卒中推送管理页
const Welcome = lazy(() => import('../page/welcome')); // 欢迎页
const InternalMessage = lazy(() => import('../page/internalMessage/index')); // 站内信页
const Feedback = lazy(() => import('../page/complaint/feedback/index')); // 反馈列表页
// const FeedbackDetail = lazy(() => import('../page/complaint/feedback/detail')); // 反馈详情页
const LinkUs = lazy(() => import('../page/linkUs')); // 反馈详情页
const LinkUsHistory = lazy(() => import('../page/linkUs/history')); // 反馈详情页
const CarouselDetailEdit = lazy(() => import('../page/topic/components/carouselDetail')); // 专题轮播图新建（编辑）页
const CarouselChannel = lazy(() => import('../page/channel/carousel/carouselDetail')); // 地方频道轮播图新建（编辑）页
const Log = lazy(() => import('../page/system/log/index')); // 操作日志页
const LiveList = lazy(() => import('../page/live/liveList'));// 直播列表
const LivePlayer = lazy(() => import('../page/live/livePlayer'));// 直播页
const LiveAudit = lazy(() => import('../page/live/liveAudit'));// 直播审批
const LiveCreate = lazy(() => import('../page/live/liveCreate'));// 直播创建

export const common = [
  { // 欢迎页
    path: '/mis/welcome',
    name: '欢迎页',
    component: Welcome,
  },
  {
    path: '/mis/linkus',
    name: '反馈中心',
    component: LinkUs,
  },
  {
    path: '/mis/linkushistory',
    name: '反馈历史',
    component: LinkUsHistory,
  },
];
export const systemModule = [{ // 系统管理模块
  path: '/mis/system/role',
  name: '角色管理',
  permissions: [
    'system_manage',
  ],
  component: Role,
}, {
  path: '/mis/system/person',
  name: '人员管理',
  permissions: [
    'system_manage',
  ],
  component: Person,
}, {
  path: '/mis/system/log',
  name: '操作日志',
  permissions: [
    'system_manage', // 操作日志
  ],
  component: Log,
},
{
  path: '/mis/person/detail/:id?',
  name: '人员详情',
  permissions: [
    'system_manage',
  ],
  component: PersonEdit,
},
{
  path: '/mis/system/offline',
  name: '临时下线',
  permissions: [
    'application_switch_manage',
  ],
  component: Offline,
}];

export const informationModule = [ // 资讯管理模块
  {
    path: '/mis/information/myinfo',
    name: '我的资讯',
    permissions: [],
    component: MyInformationList,
  },
  {
    path: '/mis/information/audit',
    name: '我的审核',
    // permissions: [],
    component: Audit,
  },
  {
    path: '/mis/information/settings',
    name: '资讯设置',
    // permissions: [],
    component: Settings,
  },
  {
    path: '/mis/information/mediaCapture',
    name: '自助聚合',
    component: mediaCapture,
  },
  {
    path: '/mis/information/capture',
    name: '实时聚合',
    component: RealTImeCapture,
  },
  {
    path: '/mis/information/article/:id?',
    name: '资讯文章新建/编辑',
    component: Article,
  },
  {
    path: '/mis/information/video/:id?',
    name: '资讯视屏新建/编辑',
    component: Video,
  },
];

export const healthModule = [ // 健康号管理模块
  {
    path: '/mis/health/list/medialists',
    name: '',
    component: CheckMediaList,
  },
  // 审核健康号
  {
    path: '/mis/health/list/checknumber/',
    name: '',
    component: CheckNumber,
  },
  {
    path: '/mis/health/list',
    name: '健康号列表',
    component: HealthNumberList,
  },
  // TODO 路由存在问题，1. 无法配置子路由  2. 路径相同时会只模糊匹配第一条，所以子路径需要写在最上面
  {
    path: '/mis/health/auditlist/checknumber/',
    name: '',
    component: CheckNumber,
  },
  {
    path: '/mis/health/auditlist/detail/:id?',
    name: '',
    component: HealthDetail,
  },
  {
    path: '/mis/health/auditlist',
    name: '健康号审核列表',
    component: HealthNumberAuditList,
  },
  {
    path: '/mis/health/hospauditlist/detail/:id?',
    name: '',
    component: HealthHospDetail,
  },
  {
    path: '/mis/health/hospauditlist',
    name: '医院黄页审核列表',
    component: HealthNumberHospAuditList,
  },
  {
    path: '/mis/doctor/integrals',
    name: '积分动态',
    component: Integrallist,
  },
  {
    path: '/mis/health/authentication',
    name: '',
    component: Authentication,
  },
  {
    path: '/mis/health/mynumber/medialists', // 原 我的媒体号列表 目前状态是废弃的 防止其他地方有依赖 先留存。
    name: '',
    component: MyShowHospInfo,
  },
  {
    path: '/mis/health/myshowhospinfo', // 新 媒体号列表 包含主体信息
    name: '',
    component: MyShowHospInfo,
  },
  {
    path: '/mis/health/myedithospinfo', // 编辑 媒体号列表 包含主体信息
    name: '',
    component: MyEditHospInfo,
  },
  {
    path: '/mis/health/mynumber',
    name: '',
    component: MyNumber,
  },
  {
    path: '/mis/health/detail',
    name: '',
    component: MyNumber,
  },
  {
    path: '/mis/health/healthlist/detail/:id?',
    name: '',
    component: HealthDetail,
  },
  {
    path: '/mis/health/level/:id?',
    name: '',
    component: SetLevel,
  },
  {
    path: '/mis/health/healthdetail/:id?',
    name: '',
    component: EditHealthDetail,
  },
  {
    path: '/mis/health/edithospinfo/:id?',
    name: '',
    component: EditHospInfo,
  },
  {
    path: '/mis/health/showhospinfo/:id?',
    name: '',
    component: ShowHospInfo,
  },
];

export const actionModule = [
  {
    path: '/mis/action/create',
    name: '',
    component: CreateAction,
  },
  {
    path: '/mis/action/preview',
    name: '',
    component: PreviewAction,
  },
  {
    path: '/mis/action/myaction/checkpeople/:id',
    name: '',
    component: CheckPeople,
  },
  {
    path: '/mis/action/myaction/select/:id',
    name: '',
    component: Select,
  },
  {
    path: '/mis/action/myaction',
    name: '我的活动',
    component: MyAction,
  },
  {
    path: '/mis/action/checkaction/detail',
    name: '活动审核详情',
    component: CheckActionDetail,
  },
  {
    path: '/mis/action/checkaction',
    name: '活动审核列表',
    component: CheckAction,
  },
  {
    path: '/mis/works/detail/:id?',
    name: '上传活动/编辑/查看',
    component: CreateWork,
  },
  {
    path: '/mis/works/list',
    name: '我的作品',
    component: MyWorkList,
  },
  {
    path: '/mis/check/works/:acid?',
    name: '活动下的作品审核',
    component: CheckWorkList,
  },
];

export const complaintModule = [
  {
    path: '/mis/complaint/list',
    name: '',
    component: Complaint,
  },
  // {
  //   path: '/mis/complaint/handle/:id?',
  //   name: '',
  //   component: HandleComplaint,
  // },
  // {
  //   path: '/mis/complaint/detail/:id?',
  //   name: '',
  //   component: ComplaintDetail,
  // },
  {
    path: '/mis/feedback/list',
    name: '',
    component: Feedback,
  },
  // {
  //   path: '/mis/feedback/detail/:type',
  //   name: '',
  //   component: FeedbackDetail,
  // },
];

export const topicModule = [
  {
    path: '/mis/topic/list',
    name: '专题列表',
    component: TopicList,
  },
  {
    path: '/mis/topic/detail',
    name: '专题新建编辑',
    component: TopicDetail,
  },
  {
    path: '/mis/topic/article',
    name: '专题资讯列表',
    component: TopicContentList,
  },
  {
    path: '/mis/topic/add/:id',
    name: '添加资讯',
    component: TopicArticleSource,
  },
  {
    path: '/mis/topic/carousel/detail/:id',
    name: '添加资讯',
    component: CarouselDetailEdit,
  },
];
export const localChannelModule = [
  {
    path: '/mis/channel/list',
    name: '地方频道列表',
    component: LocalChannelList,
  },
  {
    path: '/mis/channel/detail',
    name: '编辑/新建地方频道',
    component: LocalChannelDetail,
  },
  {
    path: '/mis/channel/setting/:id',
    name: '地方频道管理/xx频道',
    component: LocalChannelSetting,
  },
  {
    path: '/mis/channel/preview/:id',
    name: '地方频道管理/xx频道',
    component: LocalChannelPreview,
  },
  {
    path: '/mis/channel/add/:channel_id/:id',
    name: '添加资讯',
    component: ChannelArticleSource,
  },
  {
    path: '/mis/channel/carousel/detail/:id',
    name: '新建轮播图',
    component: CarouselChannel,
  },
];
export const userinfoModule = [
  {
    path: '/mis/user/userinfo',
    name: '',
    component: Userinfo,
  },
];

export const productSettingModule = [
  {
    path: '/mis/product/popins',
    name: '',
    permissions: [
      'pop_institution_ranking_manage',
    ],
    component: PopIns,
  },
  {
    path: '/mis/product/popdoctor',
    name: '',
    permissions: [
      'doctor_ranking_manage',
    ],
    component: PopDoctor,
  },
  {
    path: '/mis/product/rankdoctor',
    name: '',
    component: DoctorRank,
  },
  {
    path: '/mis/product/carousel/list',
    name: '',
    component: CarouselList,
  },
  {
    path: '/mis/product/carousel/detail',
    name: '',
    component: CarouselDetail,
  },
  {
    path: '/mis/product/appposter/list',
    name: '',
    component: AppPosterList,
  },
  {
    path: '/mis/product/topic',
    name: '',
    component: TopicConfig,
  },
  {
    path: '/mis/product/appposter/detail',
    name: '',
    component: AppPosterDetail,
  },
  {
    path: '/mis/product/belt/list',
    name: '',
    component: BeltlList,
  },
  {
    path: '/mis/product/belt/detail',
    name: '',
    component: BeltlDetail,
  },
  {
    path: '/mis/product/push/list',
    name: '',
    component: PushManageList,
  },
];

export const pushManageModule = [
  {
    path: '/mis/push/stroke',
    name: '',
    component: StrokeManageList,
  },
];

export const internalMessageModule = [
  {
    path: '/mis/message',
    name: '',
    component: InternalMessage,
  },
];

export const liveModule = [
  {
    path: '/mis/live/list',
    name: '',
    component: LiveList,
  },
  {
    path: '/mis/live/player/:id',
    name: '',
    component: LivePlayer,
  },
  {
    path: '/mis/live/audit',
    name: '',
    component: LiveAudit,
  },
  {
    path: '/mis/live/create/:id?',
    name: '',
    component: LiveCreate,
  },
];

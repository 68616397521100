// 专网腰封模块
import Request from '../utils/requestAxios';

class ApiBelt extends Request {
  /**
   * 获取专网腰封配置列表
   * @param {*} params
   */
  getGirdleList(params) {
    return this.fetch({
      url: '/action/girdle_conf/list',
      method: 'get',
      params,
    });
  }

  /**
   * 专网腰封上下线
   * @param {*} params
   */
  postActionGirdleConfOnline(params) {
    return this.fetch({
      url: '/action/girdle_conf/online',
      method: 'POST',
      params,
    });
  }

  /**
   * 新增专网腰封配置
   * @param {*} params
   */
  postActionGirdleConfAdd(params) {
    return this.fetch({
      url: '/action/girdle_conf/add',
      method: 'POST',
      params,
    });
  }

  /**
   * 编辑专网腰封配置
   * @param {*} params
   */
  postActionGirdleConfEdit(params) {
    return this.fetch({
      url: '/action/girdle_conf/edit',
      method: 'POST',
      params,
    });
  }

  /**
   * 查看专网腰封详情
   * @param {*} params
   */
  getActionGirdleConfDetail(params) {
    return this.fetch({
      url: '/action/girdle_conf/detail',
      method: 'GET',
      params,
    });
  }
}

export default new ApiBelt();

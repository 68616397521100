/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import './App.css';
import routes, { RouteWithSubRoutes } from './router/index';
import ErrorBoundary from './component/ErrorBoundary';

moment.locale('zh-cn');

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Router forceRefresh>
          <ConfigProvider locale={zhCN}>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </ConfigProvider>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;

class Token {
    saveKey = 'access_token';

    reqKey = 'X-Access-Token';

    resNewKey = 'x-access-token-new';

    getToken() {
      return localStorage.getItem(this.saveKey);
    }

    setToken(token) {
      localStorage.setItem(this.saveKey, token);
    }

    delToken() {
      localStorage.removeItem(this.saveKey);
    }
}

export default new Token();

/* eslint-disable max-len */
/*
健康中国-C端互动详情页
*/
import React, { PureComponent } from 'react';
import {
  Tabs, Input, Icon, Spin, Button, Pagination, Select, Cascader,
} from 'antd';
import moment from 'moment';
import { apiActivityProduct, apiActivity } from '../../../API';
import util from '../../../utils';
import Banner from '../components/banner';
import styles from './index.module.scss';
import './index.scss';

const { TabPane } = Tabs;
const { Option } = Select;

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      acid: '',
      level: undefined, // 作品入围、获奖
      category_id: '', // 作品入围阶段
      category: '', // 分类
      order: '', // 作品排序
      activityInfo: '', // 活动信息
      dataSource: [],
      total: 0,
      pageSize: 9,
      worksConfig: {},
      isPreview: false, // 预览，从后台页面跳入
    };

    this.methods = this.getMethods();

    this.domEvents = this.getDomEvents();

    this.domEvents.handleSearch = util.debounce(this.domEvents.handleSearch, 300);
  }

  componentDidMount() {
    const { match, location } = this.props;
    const { params = {} } = match || {};
    const { search } = location || {};
    const { acid = '' } = params || {};
    const searchParams = util.getUrlParams(search);
    const { status } = searchParams || {};
    const isPreview = status === 'preview';
    document.title = '健康中国管理系统';
    this.setState({
      acid,
      isPreview,
    }, () => {
      this.methods.getActivity().then(() => {
        if (!isPreview) {
          this.methods.getList();
        }
      });
    });
  }

  getMethods() {
    return {
      // 获取列表数据
      getList: () => {
        const {
          page = 1, pageSize = 9, audit_status, name, level, category, acid, order, category_id,
        } = this.state;
        this.setState({
          loading: true,
        });
        apiActivityProduct.getDisplayList({
          page,
          page_size: pageSize,
          audit_status,
          name,
          level: level === 'defau' ? undefined : level,
          category_id,
          order,
          // type: category,
          cate_one: category?.[0],
          cate_two: category?.[1],
          activity_id: +acid,
        }).then((data) => {
          const { list, total = 0, config = {} } = data;
          const { level: configLevel = [] } = config || {};
          const { activityInfo = {} } = this.state;
          const { finalist_categories = [] } = activityInfo || {};
          // 去掉"已入围"，加入活动的入围阶段
          configLevel.splice(1, 1);
          Array.isArray(finalist_categories) && finalist_categories.forEach((item) => {
            configLevel.splice(1, 0, {
              name: item.name,
              value: `qualified-${item.id}`,
            });
          });
          // 若没有入围阶段则也不要已获奖
          if (!Array.isArray(finalist_categories) || !finalist_categories.length) {
            configLevel.pop();
          }
          this.setState({
            dataSource: list,
            total,
            worksConfig: config,
          });
        }).finally(() => {
          this.setState({
            loading: false,
          });
        });
      },
      // 获取活动详情
      getActivity: () => new Promise((resolve) => {
        const { acid, isPreview } = this.state;
        // getOpenDetail
        let psm = null;
        if (isPreview) {
          psm = apiActivity.getDetail({ id: acid });
        } else { // C端调用
          psm = apiActivity.getOpenDetail({ id: acid });
        }
        psm.then((data) => {
          this.setState({
            activityInfo: data,
          }, () => {
            resolve();
          });
        });
      }),
    };
  }

  getDomEvents() {
    return {
      onChange: (page) => {
        this.setState({
          page,
        }, () => {
          this.methods.getList();
        });
      },
      onShowSizeChange: (current, size) => { // pageSize 变化的回调
        this.setState({
          pageSize: size,
          page: current,
        }, () => {
          this.methods.getList();
        });
      },
      goCreateWork: () => {
        const { acid } = this.state;
        this.props.history.push({
          pathname: '/mis/works/detail',
          search: `?aid=${acid}`,
        });
      },
      handleSearch: (event) => {
        const { target } = event;
        const { value } = target;
        this.setState({
          name: value,
        }, () => {
          this.methods.getList();
        });
      },
      tabChange: (v = '') => {
        const level = v.split('-')[0];
        const category_id = v.split('-')[1] || '';
        this.setState({
          level,
          category_id,
        }, () => {
          this.methods.getList();
        });
      },
      // 分类选择
      handleCategoryChange: (value) => {
        this.setState({
          category: value,
        }, () => {
          this.methods.getList();
        });
      },
      handleOrderChange: (value) => {
        this.setState({
          order: value,
        }, () => {
          this.methods.getList();
        });
      },
      // 下载附件
      downloadFile: ({
        store_key, name, vid, url,
      }) => {
        const { data, key } = util.encrypt(vid, name);
        util.downFile({
          store_key, vid, file_name: name, file_url: url, query: { data, key },
        });
      },
      goWorksDetail: (wid) => {
        const { acid } = this.state;
        // this.props.history.push(`/activity/works/${wid}?acid=${acid}`);
        window.open(`/#/activity/works/${wid}?acid=${acid}`, '_blank');
      },
    };
  }

  render() {
    const { domEvents } = this;
    const {
      loading = false, activityInfo = {}, dataSource, total, worksConfig, isPreview, pageSize,
    } = this.state;
    const { level = [], order = [], type: categorys = [] } = worksConfig;
    const {
      content = '', end_time, start_time, collect_end_time, collect_start_time, vote_end_time, vote_start_time, organizer, need_vote = true,
      phase, attachments = [], tos_attachments = [], name, thumbnail,
    } = activityInfo;
    // 附件字段 如果有新字段就用新的，没有的话就用旧的。
    let editAttachments = [];
    if (tos_attachments && Array.isArray(tos_attachments)) {
      editAttachments = tos_attachments;
    } else {
      editAttachments = attachments;
    }
    const noDataSource = Array.isArray(dataSource) && dataSource.length === 0;
    // const phaseTxt = {
    //   0: '全部阶段',
    //   1: '活动进行中，复合状态，可能为报名未开始，主要用来过滤搜索',
    //   2: '报名未开始',
    //   3: '报名进行中',
    //   4: '投票进行中',
    //   5: '活动已结束',
    // };
    return (
      <div className={`${styles.wrap} detail-page`}>
        <Banner {...this.props} />
        <div className={styles.sectionTop}>
          <div className={styles.sTitle}>
            {name}
          </div>
          <div className={`${styles.topCard} clearfix`}>
            <div className={`${styles.leftBox} fl`}>
              <img src={thumbnail} alt="" />
            </div>
            <div className={`${styles.rBox} fl`}>
              <div>
                时间：
                {start_time && moment(+start_time * 1000).format('YYYY-MM-DD')}
                &nbsp;至&nbsp;
                {end_time && moment(+end_time * 1000).format('YYYY-MM-DD')}
              </div>
              <div>
                作品征集时间：
                {collect_start_time && moment(+collect_start_time * 1000).format('YYYY-MM-DD')}
                &nbsp;至&nbsp;
                {collect_end_time && moment(+collect_end_time * 1000).format('YYYY-MM-DD')}
              </div>
              {
                need_vote && (
                  <div>
                    作品投票时间：
                    {vote_start_time && moment(+vote_start_time * 1000).format('YYYY-MM-DD')}
                    &nbsp;至&nbsp;
                    {vote_end_time && moment(+vote_end_time * 1000).format('YYYY-MM-DD')}
                  </div>
                )
              }
              <div>
                主办方：
                {organizer}
              </div>
              <div className={styles.btnBox}>
                {phase === 2
                  ? (
                    <Button disabled>
                      报名未开始
                    </Button>
                  )
                  : null}
                {phase === 3 // 报名中
                  ? (
                    <Button type="primary" onClick={domEvents.goCreateWork}>
                      立即报名
                    </Button>
                  )
                  : null}
                {!(phase === 2 || phase === 3)
                  ? (
                    <Button disabled>
                      报名结束
                    </Button>
                  )
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionWrap}>
          <div className={styles.section}>
            <div className={styles.sTitle}>活动介绍</div>
            <div className={styles.txtConent} dangerouslySetInnerHTML={{ __html: content }} onError="console.log('xss')" />
            <div className={styles.fileBox}>
              {
                Array.isArray(editAttachments) && editAttachments.map((item, index) => (
                  <div className={`${styles.fileLi} clearfix`} key={index} onClick={() => domEvents.downloadFile(item)}>
                    <span className="fl"><Icon type="file-text" /></span>
                    <div className={`${styles.fileName} fl`}>{item.name}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        {
          !isPreview && (
            <div className={styles.sectionWrap}>
              <div className={styles.section}>
                <div className={styles.sTitle}>作品展示</div>
                <div className={styles.searchWrap}>
                  <div className={styles.searchBox}>
                    <div className={`fl ${styles.leftBox}`}>
                      <Tabs defaultActiveKey="defau" onChange={domEvents.tabChange}>
                        {
                          Array.isArray(level) && level.map((item) => <TabPane tab={item.name} key={item.value || 'defau'} />)
                        }
                      </Tabs>
                    </div>
                    <div className={`fr ${styles.rBox}`}>
                      <Select
                        className={styles.select}
                        style={{ width: 120 }}
                        onChange={domEvents.handleOrderChange}
                        placeholder="作品排序"
                      >
                        {
                          Array.isArray(order) && order.map((item, index) => <Option value={item.value} key={index}>{item.name}</Option>)
                        }
                      </Select>
                      <Cascader
                        className={styles.select}
                        style={{ width: 120 }}
                        onChange={domEvents.handleCategoryChange}
                        placeholder="选择分类"
                        fieldNames={{
                          label: 'name',
                          value: 'value',
                          children: 'childrenTab',
                        }}
                        options={categorys}
                      />
                      <Input
                        placeholder="请输入关键词"
                        onChange={domEvents.handleSearch}
                        style={{ width: 190 }}
                        prefix={(
                          <Icon type="search" />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.btmContent}>
                  <Spin tip="加载中..." spinning={loading}>
                    {
                      Array.isArray(dataSource) && util.arrTrans(dataSource, 3).map((itemArr, index) => (
                        <div className={`${styles.itemLine} clearfix`} key={index}>
                          {
                            itemArr.map((item, subIndex) => (
                              <div
                                className={`${styles.card} ${subIndex === 1 ? styles.centerItem : ''} fl`}
                                key={index + subIndex}
                                onClick={() => { domEvents.goWorksDetail(item.id); }}
                              >
                                <div className={styles.topImg}>
                                  <img src={item.cover_url} alt="" loading="lazy" />
                                </div>
                                {item.win_status && <div className={styles.reward}>获奖作品</div>}
                                <div className={styles.cTitle}>{item.title}</div>
                                <div className={`${styles.btmBox} clearfix`}>
                                  <div className="fl">
                                    阅读
                                    &nbsp;
                                    {item.read_cnt || 0}
                                    &nbsp;
                                    &nbsp;
                                  </div>
                                  <div className="fr">
                                    得票
                                    &nbsp;
                                    {item.vote_count || 0}
                                  </div>
                                  <div className="fr">
                                    点赞
                                    &nbsp;
                                    {item.fab_cnt || 0}
                                    &nbsp;
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      ))
                    }
                    {
                      noDataSource && <div className={styles.noDataTip}>- 暂无作品 -</div>
                    }
                  </Spin>
                </div>
                {
                  !noDataSource && (
                    <div className={styles.paginationBox}>
                      <Pagination
                        showSizeChanger
                        showQuickJumper
                        total={total}
                        pageSize={pageSize}
                        showTotal={(_total) => `共 ${_total} 条`}
                        onChange={domEvents.onChange}
                        onShowSizeChange={domEvents.onShowSizeChange}
                        pageSizeOptions={['9', '18', '27', '36']}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default Home;

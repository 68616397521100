// 通用接口
import Request from '../utils/requestAxios';

class Complaint extends Request {
  /**
     * 获取申诉列表
     * @param {*} params
     */
  getComplaintList(params) {
    return this.fetch({
      url: '/complaint/list',
      method: 'get',
      params,
    });
  }

  /**
     * 获取申诉详情
     * @param {*} params
     */
  getComplaintDetail(params) {
    return this.fetch({
      url: '/complaint/detail',
      method: 'get',
      params,
    });
  }

  /**
     * 申诉
     * @param {*} params
     */
  complaint(params) {
    return this.fetch({
      url: '/complaint/submit',
      method: 'post',
      params,
    });
  }

  /**
     * 处理申诉请求
     * @param {*} params
     */
  handleComplaint(params) {
    return this.fetch({
      url: '/complaint/handle',
      method: 'post',
      params,
    });
  }

  /**
   * 获取反馈列表
   * @param {*} params
   */
  getFeedbackList(params) {
    return this.fetch({
      url: '/feedback/list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取反馈详情
   * @param {*} params
   */
  getFeedbackDetail(params) {
    return this.fetch({
      url: '/feedback/detail',
      method: 'get',
      params,
    });
  }

  /**
   * 提交反馈意见
   * @param {*} params
   */
  handleFeedback(params) {
    return this.fetch({
      url: '/feedback/handle',
      method: 'post',
      params,
    });
  }

  /**
   * 创建反馈
   * @param {*} params
   */
  createFeedback(params) {
    return this.fetch({
      url: '/feedback/create',
      method: 'post',
      params,
    });
  }

  /**
   * 反馈历史消息
   * @param {*} params
   */
  getFeedbackHistory(params) {
    return this.fetch({
      url: '/feedback/user_list',
      method: 'get',
      params,
    });
  }

  /**
   * 反馈历史消息未读状态
   * @param {*} params
   */
  getFeedbackReplyState(params) {
    return this.fetch({
      url: '/feedback/reply_read_state',
      method: 'get',
      params,
    });
  }

  /**
   * 反馈历史消息未读状态
   * @param {*} params
   */
  setFeedbackReplyRead(params) {
    return this.fetch({
      url: '/feedback/reply_read',
      method: 'post',
      params,
    });
  }
}

export default new Complaint();

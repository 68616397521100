import { decorate, observable } from 'mobx';
import { apiCommon } from '../API';

class MenuBadgesStore {
  menuBadges = {};

  async setMenuBadges(config) {
    if (config) {
      this.menuBadges = config;
    } else {
      try {
        const auditConfig = await apiCommon.getNeedAuditNum({ type: 2 });
        this.menuBadges = {
          // 健康号
          health: {
            badges: auditConfig.urgent_num,
            children: {
              // 健康号审核列表路由 path
              '/mis/health/auditlist': {
                badges: auditConfig.urgent_num,
              },
            },
          },
        };
      } catch (error) {
        console.log('auditConfigError', error);
      }
    }
  }
}

decorate(MenuBadgesStore, {
  menuBadges: observable,
});

export default new MenuBadgesStore();

import React, { Component } from 'react';
import {
  Modal, Card, Avatar, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import './switchrole.scss';
import UserStore from '../../store/user';
import { apiCommon } from '../../API';
import default_avatar from '../../static/images/default.png';
import { checkType } from '../../page/healthNumber/data';
import GlobalNotify from '@/utils/globalNotify';

class SwitchRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
    };
  }

  componentDidMount() {
  }

  handleCancel = () => {
    const { onClose } = this.props;
    onClose && onClose(false);
  }

  handleSwitchRole = (id = '') => {
    this.setState({
      spinning: true,
    });
    apiCommon.SwitchHealthNumber({ id }).then(() => UserStore.update().then(() => {
      this.props.history.push('/mis/user/userinfo');
      this.props.onClose(false);
    })).finally(() => {
      this.setState({
        spinning: false,
      });
      GlobalNotify.initPushAuditHealthNumberTips();
    });
  }

  roleList = (items) => {
    items = items.filter((item) => item.health_number_id !== 0);
    return (
      <div>
        {
          items && items.map((item, index) => (
            <Card
              className={UserStore.userInfo.health_number_name === item.health_number_name ? 'active-card' : 'normal-card'}
              key={index}
              onClick={() => this.handleSwitchRole(item.health_number_id)}
            >
              <Avatar
                src={UserStore.userInfo.avatar_url ? UserStore.userInfo.avatar_url : default_avatar}
                style={{ width: 60, height: 60, marginRight: 10 }}
              />
              <div className="role-info">
                <span style={{ color: '#333333', fontWeight: 500, fontSize: 16 }}>{item.health_number_name}</span>
                <span style={{ color: '#666', fontSize: 14 }}>{item.role_name}</span>
              </div>
              {/* 当健康号处于不可用状态 审核中、 审核失败、 临时下线 时显示 */}
              <div className="role-ins">
                {(item.health_number_status === 2 || item.health_number_status === 3 || item.health_number_status === 5) && (
                  <span style={{ color: 'red', fontSize: 14 }}>
                    {`当前健康号处于‘${checkType[item.health_number_status]}’状态`}
                  </span>
                )}
              </div>
            </Card>
          ))
        }
      </div>
    );
  }

  render() {
    const { spinning } = this.state;
    const { userInfo } = UserStore;
    return (
      <Modal
        title="请选择身份"
        visible
        className="switch-modal"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: true, style: { display: 'none' } }}
        onCancel={this.handleCancel}
      >
        <Spin spinning={spinning}>
          {
            userInfo.identities && (
              <>
                <h5>健康号对应人员身份</h5>
                {userInfo.identities && this.roleList(userInfo.identities)}
              </>
            )
          }
          {/* 个人身份 */}
          <br />
          <h5>个人身份</h5>
          <Card
            className={userInfo.role_name === '个人用户' ? 'active-card' : 'normal-card'}
            onClick={() => { this.handleSwitchRole(); }}
          >
            <Avatar
              src={userInfo.avatar_url ? userInfo.avatar_url : default_avatar}
              style={{ width: 60, height: 60, marginRight: 10 }}
            />
            <div className="role-info">
              <span style={{ color: '#333333', fontWeight: 500, fontSize: 16 }}>{userInfo.nick_name}</span>
            </div>
          </Card>

        </Spin>
      </Modal>
    );
  }
}
SwitchRole.propTypes = {
  onClose: PropTypes.func,
};
SwitchRole.defaultProps = {
  onClose: () => {},
};
export default SwitchRole;

/* eslint-disable max-len */
const Regular = {
  IdCard18: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, // 18位身份证
  IdCard15: /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/, // 15位身份证
  IdCard: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/, // 15或者18位身份证
  TelPhone: /^(\d{3,4}-\d{7,8})$|^(1[3-9][0-9]\d{8})$|^(\d{7,8})$/, // 固话或者手机
  Email: /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/ig, // 邮箱
  cnEnNum: /^[\u4E00-\u9FA5A-Za-z0-9]*$/, // 中文、英文、数字但不包括下划线等符号
};
export default Regular;

import React from 'react';
import { Result, Button } from 'antd';
import { apmPlusReport, Level } from '@/utils/apm';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let eventName = 'custom_blank_screen';
    if (error?.message?.startsWith?.('Loading ')) {
      eventName = 'custom_loading_blank_screen';
    }
    apmPlusReport({
      eventName,
      params: { message: error.message, info: errorInfo.componentStack, type: 'react_error' },
      level: Level.ERROR,
    });
  }

reload = () => {
  window.location.reload();
}

render() {
  const { hasError } = this.state;
  if (hasError) {
    return (
      <Result
        status="warning"
        title="页面加载出错了"
        extra={(
          <Button type="primary" key="console" onClick={this.reload}>
            重新加载
          </Button>
        )}
      />
    );
  }
  return this.props.children; // 正常页面，渲染子组件
}
}
export default ErrorBoundary;

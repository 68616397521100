// 医者名片的机构类型
export const orgTypeOptions = [
  { value: 14, label: '三级特等' },
  { value: 8, label: '三级甲等' },
  { value: 9, label: '三级乙等' },
  { value: 10, label: '三级丙等' },
  { value: 2, label: '三级' },
  { value: 3, label: '二级' },
  { value: 4, label: '一级' },
  { value: 5, label: '社区卫生服务中心' },
  { value: 6, label: '乡镇卫生院' },
  { value: 7, label: '专业公共卫生服务机构' },
  { value: 1, label: '其他' },
];
// !!! 机构类型（医院等级）相关逻辑 o
// 医疗机构的机构类型 分为四列
export const otherOrgTypeOptions = [
  { value: 5, label: '社区卫生服务中心' },
  { value: 6, label: '乡镇卫生院' },
  { value: 7, label: '专业公共卫生服务机构' },
  { value: 1, label: '其他' },
];

export const firstLevelHospitalOptions = [
  { value: 4, label: '一级' },
  { value: 15, label: '一级甲等' },
  { value: 16, label: '一级乙等' },
  { value: 17, label: '一级丙等' },
];
export const secondLevelHospitalOptions = [
  { value: 3, label: '二级' },
  { value: 11, label: '二级甲等' },
  { value: 12, label: '二级乙等' },
  { value: 13, label: '二级丙等' },
];
export const thirdLevelHospitalOptions = [
  { value: 2, label: '三级' },
  { value: 8, label: '三级甲等' },
  { value: 9, label: '三级乙等' },
  { value: 10, label: '三级丙等' },
];
// 健康号列表搜索
export const searchOrgTypeOptions = [
  {
    label: '全部',
    value: '-1',
  },
  {
    label: '三级医院',
    children: [
      {
        value: 103,
        label: '三级全部',
      },
      ...thirdLevelHospitalOptions,
    ],
  },
  {
    label: '二级医院',
    children: [
      {
        value: 102,
        label: '二级全部',
      },
      ...secondLevelHospitalOptions,
    ],
  },
  {
    label: '一级医院',
    children: [
      {
        value: 101,
        label: '一级全部',
      },
      ...firstLevelHospitalOptions,
    ],
  },
  {
    label: '其他机构',
    children: [...otherOrgTypeOptions],
  },
];
/**
 * 机构类型的 key value 映射表
 * */
export const orgTypeMap = {
  8: '三级甲等',
  9: '三级乙等',
  10: '三级丙等',
  14: '三级特等',
  11: '二级甲等',
  12: '二级乙等',
  13: '二级丙等',
  15: '一级甲等',
  16: '一级乙等',
  17: '一级丙等',
  2: '三级',
  3: '二级',
  4: '一级',
  5: '社区卫生服务中心',
  6: '乡镇卫生院',
  7: '专业卫生服务机构',
  1: '其他',
};

/**
 * 机构类型 key 集合 number 类型枚举
 *  */
export const orgTypeMapKeys = Object.keys(orgTypeMap).map((v) => Number(v));
/**
 * 医者名片的机构类型 key 集合 number 类型枚举
 *  */
export const doctorOrgTypeMapKeys = orgTypeOptions.map(({ value }) => value); // number 类型枚举

export default {
  orgTypeOptions,
  otherOrgTypeOptions,
  firstLevelHospitalOptions,
  secondLevelHospitalOptions,
  thirdLevelHospitalOptions,
  searchOrgTypeOptions,
};

import Request from '../utils/requestAxios';

class ApiGovTopic extends Request {
  /**
   * 获取四部委活动列表
   * @param {*} params
   */
  getTopicList(params) {
    return this.fetch({
      url: '/activity/list_science',
      method: 'get',
      params,
    });
  }
}

export default new ApiGovTopic();

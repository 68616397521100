/*
健康中国-C端顶部banner
*/
import React, { Component } from 'react';
import styles from './index.module.scss';

// eslint-disable-next-line react/prefer-stateless-function
class DoctorAgreementBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.domEvents = this.getDomEvents();
  }

  componentDidMount() {
  }

  getDomEvents() {
    return {
      signAgreement: () => {
        // this.props.history.push('/activity/doctorAgreement');
        window.open('#/activity/doctorAgreement', '_blank');
      },
    };
  }

  render() {
    const { domEvents } = this;
    return (
      <div className={styles.topBanner}>
        <div className={styles.bannerContent} onClick={domEvents.signAgreement} />
      </div>
    );
  }
}
export default DoctorAgreementBanner;

// **********垂直布局不用加width********
// 健康号类型
import {
  Government,
  Hospital,
  // Media, // 媒体合入其他
  Other,
  Checking,
  Success,
  Fail,
  Warning,
  DoctorCardIcon,
} from '../../static/images/index';
import { Regular } from '../../utils';
import { searchOrgTypeOptions } from '@/page/healthNumber/orgTypeData';

// 健康号类型选择
export const healthType = [
  {
    url: Hospital,
    name: '医疗卫生机构、专业公共卫生机构', // 在类型选择处特殊处理换行
    list: ['医疗机构', '疾病控制机构', '卫生监督机构', '妇幼保健机构', '社区卫生服务机构'],
    type: 2,
  },
  {
    name: '健康中国行动',
    list: ['健康中国行动推进委员会各成员单位', '各省、自治区、直辖市及新疆生产建设兵团推进健康中国行动议事协调机构', '健康中国行动各专项行动工作组'],
    type: 8,
  },
  {
    name: '爱国卫生运动',
    list: ['全国爱国卫生运动委员会各成员单位', '各省、自治区、直辖市及新疆生产建设兵团爱卫会'],
    type: 7,
  },
  {
    url: DoctorCardIcon,
    name: '医者名片',
    list: ['三级及以上医院且为主治及以上职称'],
    type: 6,
  },
  {
    url: Government,
    name: '政府机关',
    list: [
      '各级卫生健康行政机构',
      '国内各级各类政府机关',
      '事业单位(非医疗卫生机构）',
      '具有行政职能的社会组织等',
    ],
    type: 1,
  },
  // 媒体和其他类型合并
  // {
  //   url: Media,
  //   name: '媒体',
  //   list: ['自媒体', '新闻媒体等具有内容生产能力的团队'],
  //   type: 3,
  // },
  {
    url: Other,
    name: '其他',
    list: ['媒体', '各类公益机构', '学校', '社团', '民间组织', '公共场馆等'],
    type: 4,
  },
];
// 媒体号表单
export const media = [
  {
    name: 'wechat_name',
    label: '新媒体名称',
    type: 'input',
    rules: [
      { required: true, message: '请填写新媒体名称' },
    ],
  },
  {
    name: 'wechat_num',
    label: '账号',
    type: 'input',
    rules: [
      { required: true, message: '请填写账号' },
    ],
  },
  {
    name: 'type',
    label: '类型',
    type: 'select',
    list: [
      { value: 1, label: '微信' },
      { value: 2, label: '微博' },
      { value: 3, label: 'App' },
      { value: 4, label: '网页' },
    ],
    rules: [
      { required: true, message: '请选择媒体号类型' },
    ],
  },
  {
    name: 'article_url',
    label: '入口链接',
    type: 'input',
  },
];

// const DoctorCards = [10, 11, 12, 13];
const DoctorCards = [10, 11, 12];
export const healthSearch = [
  // 0
  {
    index: 1,
    name: 'health_code_type',
    label: '健康号类型',
    type: 'searchselect',
    value: '-1', // 默认填充值
    width: '280',
    list: [ // 选择列表项
      { value: '-1', label: '全部', hiddenlist: [4, 5, 6, ...DoctorCards] },
      { value: '1', label: '政府机关', hiddenlist: [5, 6, ...DoctorCards] }, // 待优化，不能用索引控制联动
      { value: '2', label: '医疗卫生机构/专业卫生机构', hiddenlist: [6, ...DoctorCards] },
      // { value: '3', label: '媒体', hiddenlist: [5, ...DoctorCards] }, // 媒体合入其他
      { value: '6', label: '医者名片', hiddenlist: [4, 5, 6, 11] },
      { value: '7', label: '爱国卫生运动', hiddenlist: [5, 6, ...DoctorCards] }, // 待优化，不能用索引控制联动
      { value: '8', label: '健康中国行动', hiddenlist: [5, 6, ...DoctorCards] }, // 待优化，不能用索引控制联动
      { value: '4', label: '其他', hiddenlist: [5, 6, ...DoctorCards] },
    ],
    // 为了首次刷新默认值可以正常刷新hiddenlist
    useHiddenList: true,
    opertion: true,
    hidden: false,
  },
  // 1
  {
    index: 0, // 索引
    name: 'health_code_name', // 收集字段属性名
    label: '健康号名称', // label
    type: 'input', // 表单字段类型
    width: '280', // 宽度，单位px
    hidden: false, // 是否显示
    rules: [{}], // 校验规则
    placeholder: '', // 占位
  },
  // 2
  {
    index: 12, // 索引
    name: 'org_name', // 收集字段属性名
    label: '机构名称', // label
    type: 'input', // 表单字段类型
    width: '280', // 宽度，单位px
    hidden: false, // 是否显示
    rules: [{}], // 校验规则
    placeholder: '', // 占位
  },
  // 3
  // !!! 机构类型（医院等级）相关逻辑 o
  {
    index: 2,
    name: 'org_type',
    label: '机构类型',
    type: 'groupSelect',
    value: '-1',
    width: '280',
    list: [
      ...searchOrgTypeOptions,
    ],
    hidden: false,
  },
  // 4
  {
    index: 4,
    name: 'area',
    label: '所在地',
    type: 'cascader',
    changeOnSelect: true,
    width: '280',
    hidden: false,
  },
  // 5
  {
    index: 5,
    name: 'org_nature',
    label: '机构性质',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '1', label: '公立' },
      { value: '2', label: '私立' },
    ],
    hidden: false,
  },
  // 6
  {
    index: 6,
    name: 'media_type',
    label: '媒体类型',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '1', label: '自媒体' },
      { value: '2', label: '新闻媒体' },
    ],
    hidden: false,
  },
  // 7
  {
    index: 7,
    name: 'status',
    label: '审核状态',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '2', label: '待审核' },
      { value: '3', label: '认证失败' },
      { value: '4', label: '认证成功' },
      { value: '5', label: '临时下线' },
      { value: '6', label: '永久下线' },
    ],
    hidden: false,
  },
  // 8
  {
    index: 3,
    name: 'time',
    label: '创建时间',
    type: 'datepicker',
    width: '560',
    hidden: false,
    formItemProps: {
      labelCol: { span: 4 },
    },
  },
  // 9
  {
    index: 8,
    name: 'media_status', // 待定
    label: '媒体号审核进展',
    type: 'select',
    value: 0,
    width: '340',
    list: [
      { value: 0, label: '全部' },
      { value: 1, label: '待审核' },
      { value: 2, label: '已完成' },
      { value: 3, label: '未提交' },
    ],
    hidden: false,
  },
  // 10
  {
    index: 13,
    name: 'department_name',
    label: '科室名称',
    type: 'cascader',
    value: '-1',
    width: '280',
    hidden: true,
    fieldNames: {
      label: 'label',
      value: 'label',
    },
    options: [],
    // 关联图表
    relation: {
      // 目标图表
      target: 'departmentOther',
      // 目标图表的关联属性
      attr: 'hidden',
      // 关联属性取值计算函数
      control(value = []) {
        if (value[0] === '其他') {
          return false;
        }
        return true;
      },
    },
  },
  // 11
  {
    index: 14,
    name: 'departmentOther',
    label: '其他科室',
    type: 'input',
    width: '280',
    hidden: true,
    placeholder: '',
  },
  // 12
  {
    index: 15,
    name: 'title',
    label: '职务名称',
    type: 'input',
    width: '280',
    hidden: true,
    placeholder: '',
  },
  // 13
  // {
  //   index: 16,
  //   name: 'doctor_status',
  //   label: '医者名片认证状态',
  //   type: 'select',
  //   width: '560',
  //   value: '0',
  //   list: [
  //     { value: '0', label: '全部' },
  //     { value: '1', label: '未认证' },
  //     { value: '2', label: '已认证' },
  //   ],
  //   hidden: true,
  // },
  // 14
  {
    type: 'button',
    label: '搜索',
  },
];
// 健康号审核搜索列表
export const healthAuditSearch = [
  // 0
  {
    index: 1,
    name: 'health_code_type',
    label: '健康号类型',
    type: 'searchselect',
    value: '-1', // 默认填充值
    width: '280',
    list: [ // 选择列表项
      { value: '-1', label: '全部', hiddenlist: [4, 5, 6, ...DoctorCards, 9] },
      { value: '1', label: '政府机关', hiddenlist: [5, 6, ...DoctorCards, 9] }, // 待优化，不能用索引控制联动
      { value: '2', label: '医疗卫生机构/专业卫生机构', hiddenlist: [6, ...DoctorCards, 9] },
      { value: '6', label: '医者名片', hiddenlist: [4, 5, 6, 11, 9] },
      { value: '7', label: '爱国卫生运动', hiddenlist: [5, 6, ...DoctorCards, 9] }, // 待优化，不能用索引控制联动
      { value: '8', label: '健康中国行动', hiddenlist: [5, 6, ...DoctorCards, 9] }, // 待优化，不能用索引控制联动
      { value: '4', label: '其他', hiddenlist: [5, 6, ...DoctorCards, 9] },
    ],
    // 为了首次刷新默认值可以正常刷新hiddenlist
    useHiddenList: true,
    opertion: true,
    hidden: false,
  },
  // 1
  {
    index: 0, // 索引
    name: 'health_code_name', // 收集字段属性名
    label: '健康号名称', // label
    type: 'input', // 表单字段类型
    width: '280', // 宽度，单位px
    hidden: false, // 是否显示
    rules: [{}], // 校验规则
    placeholder: '', // 占位
  },
  // 2
  {
    index: 12, // 索引
    name: 'org_name', // 收集字段属性名
    label: '机构名称', // label
    type: 'input', // 表单字段类型
    width: '280', // 宽度，单位px
    hidden: false, // 是否显示
    rules: [{}], // 校验规则
    placeholder: '', // 占位
  },
  // 3
  // !!! 机构类型（医院等级）相关逻辑 o
  {
    index: 2,
    name: 'org_type',
    label: '机构类型',
    type: 'groupSelect',
    value: '-1',
    width: '280',
    list: [
      ...searchOrgTypeOptions,
    ],
    hidden: false,
  },
  // 4
  {
    index: 4,
    name: 'area',
    label: '所在地',
    type: 'cascader',
    changeOnSelect: true,
    width: '280',
    hidden: false,
  },
  // 5
  {
    index: 5,
    name: 'org_nature',
    label: '机构性质',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '1', label: '公立' },
      { value: '2', label: '私立' },
    ],
    hidden: false,
  },
  // 6
  {
    index: 6,
    name: 'media_type',
    label: '媒体类型',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '1', label: '自媒体' },
      { value: '2', label: '新闻媒体' },
    ],
    hidden: false,
  },
  // 7
  {
    index: 7,
    name: 'status',
    label: '审核状态',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '1', label: '待审核' },
      { value: '2', label: '审核通过' },
      { value: '3', label: '审核驳回' },
    ],
    hidden: false,
  },
  // 8
  {
    index: 3,
    name: 'time',
    label: '创建时间',
    type: 'datepicker',
    width: '560',
    hidden: false,
    formItemProps: {
      labelCol: { span: 4 },
    },
  },
  // 9
  {
    index: 8,
    name: 'media_status', // 待定
    label: '媒体号审核进展',
    type: 'select',
    value: 0,
    width: '340',
    list: [
      { value: 0, label: '全部' },
      { value: 1, label: '待审核' },
      { value: 2, label: '已完成' },
      { value: 3, label: '未提交' },
    ],
    hidden: true,
  },
  // 10
  {
    index: 13,
    name: 'department_name',
    label: '科室名称',
    type: 'cascader',
    value: '-1',
    width: '280',
    hidden: true,
    fieldNames: {
      label: 'label',
      value: 'label',
    },
    options: [],
    // 关联图表
    relation: {
      // 目标图表
      target: 'departmentOther',
      // 目标图表的关联属性
      attr: 'hidden',
      // 关联属性取值计算函数
      control(value = []) {
        if (value[0] === '其他') {
          return false;
        }
        return true;
      },
    },
  },
  // 11
  {
    index: 14,
    name: 'departmentOther',
    label: '其他科室',
    type: 'input',
    width: '280',
    hidden: true,
    placeholder: '',
  },
  // 12
  {
    index: 15,
    name: 'title',
    label: '职务名称',
    type: 'input',
    width: '280',
    hidden: true,
    placeholder: '',
  },
  // 13
  // {
  //   index: 16,
  //   name: 'doctor_status',
  //   label: '医者名片认证状态',
  //   type: 'select',
  //   width: '560',
  //   value: '0',
  //   list: [
  //     { value: '0', label: '全部' },
  //     { value: '1', label: '未认证' },
  //     { value: '2', label: '已认证' },
  //   ],
  //   hidden: true,
  // },
  // 14
  {
    type: 'button',
    label: '搜索',
  },
];
// 抖音黄页审核搜索列表
export const hospAuditSearch = [
  // 2
  {
    index: 12, // 索引
    name: 'org_name', // 收集字段属性名
    label: '机构名称', // label
    type: 'input', // 表单字段类型
    width: '280', // 宽度，单位px
    hidden: false, // 是否显示
    rules: [{}], // 校验规则
    placeholder: '', // 占位
  },
  // 3
  // !!! 机构类型（医院等级）相关逻辑 o
  {
    index: 2,
    name: 'org_type',
    label: '机构类型',
    type: 'groupSelect',
    value: '-1',
    width: '280',
    list: [
      ...searchOrgTypeOptions,
    ],
    hidden: false,
  },
  // 5
  {
    index: 5,
    name: 'org_nature',
    label: '机构性质',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '1', label: '公立' },
      { value: '2', label: '私立' },
      { value: '3', label: '非营利性' },
      { value: '4', label: '其他' },
    ],
    hidden: false,
  },
  // 7
  {
    index: 7,
    name: 'status',
    label: '审核状态',
    type: 'select',
    value: '-1',
    width: '280',
    list: [
      { value: '-1', label: '全部' },
      { value: '1', label: '待审核' },
      { value: '2', label: '审核通过' },
      { value: '3', label: '审核驳回' },
    ],
    hidden: false,
  },
  // 8
  {
    index: 3,
    name: 'time',
    label: '创建时间',
    type: 'datepicker',
    width: '560',
    hidden: false,
    formItemProps: {
      labelCol: { span: 4 },
    },
  },
  // 13
  {
    type: 'button',
    label: '搜索',
  },
];
// 政府类型
const governmentFormHeader = [
  {
    index: 0,
    name: 'health_code_type',
    label: '已入驻类型',
    type: 'text',
    value: '政府机关',
    hidden: false,
  },
];
// 医院类型
const hospitalFormHeader = [
  {
    name: 'health_code_type',
    label: '已入驻类型',
    type: 'text',
    value: '医疗卫生机构/专业卫生公共机构',
    hidden: false,
  },
];

// 媒体类型
const madiaFormHeader = [
  {
    name: '已选入驻类型',
    label: '已选入驻类型',
    type: 'text',
    value: '媒体',
    hidden: false,
  },
];
// 其他类型
const otherFormHeader = [
  {
    name: '已选入驻类型',
    label: '已选入驻类型',
    value: '其他',
    type: 'text',
  },
];
export const typeFormHeader = [governmentFormHeader, hospitalFormHeader, madiaFormHeader, otherFormHeader];

// 政府类型
const governmentForm = [
];
// 医院类型 健康号信息填写表单项 医疗机构的表单已经单独抽离， 此处应该无用了
const hospitalForm = [
  {
    name: 'org_nature',
    label: '机构性质',
    type: 'radio',
    list: [
      { value: 1, label: '公立' },
      { value: 2, label: '私立' },
    ],
    hidden: false,
    rules: [
      { required: true, message: '请选择机构性质' },
    ],
  },
  // !!! 机构类型（医院等级）相关逻辑 o
  {
    name: 'org_type',
    label: '机构类型',
    placeholder: '请选择机构类型',
    type: 'select',
    list: [
      ...searchOrgTypeOptions,
    ],
    hidden: false,
    rules: [
      { required: true, message: '请选择机构类型' },
    ],
  },
];

// 媒体类型
const madiaForm = [
  {
    name: 'media_type',
    label: '媒体类型',
    type: 'radio',
    rules: [
      { required: true, message: '请选择媒体类型' },
    ],
    list: [
      { value: 1, label: '自媒体' },
      { value: 2, label: '新闻媒体' },
    ],
    hidden: false,
  },
];
// 其他类型
const otherForm = [
  // {
  //   name: '已选入驻类型',
  //   label: '已选入驻类型',
  //   value: '其他',
  //   type: 'text',
  // },
];
export const typeForm = [governmentForm, hospitalForm, madiaForm, otherForm];
// 健康号公共表单 顺序不可随意更改
export const commonForm = [
  {
    type: 'title',
    title: '健康号信息',
  },
  {
    name: 'health_code_name',
    label: '健康号名称',
    type: 'input',
    placeholder: '请输入健康号名称',
    warning: '2-50个字，请勿使用含特殊符号或含有明显营销推广意图的媒体名。健康号名称无需和机构名称一致，不能和其他健康号同名',
    hidden: false,
    rules: [
      { required: true, message: '请填写健康号名称' },
      { max: 50, message: '最多50个字符' },
      { min: 2, message: '最少2个字符' },
    ],
  },
  {
    name: 'introduce',
    label: '健康号介绍',
    type: 'textarea',
    placeholder: '请输入介绍内容',
    warning: '10-200个字，要求内容完整通顺，无特殊符号，请勿添加任何联系方式。如微博、手机号、QQ。',
    hidden: false,
    rules: [
      { required: true, message: '请填写健康号介绍' },
      { max: 200, message: '最多200个字符' },
      { min: 10, message: '最少10个字符' },
    ],
  },
  {
    name: 'head_url',
    label: '健康号头像',
    type: 'cropperUpload',
    warning: '要求清晰、健康、代表机构形象，请勿使用二维码。头像须在5MB以内，推荐尺寸为200×200像素',
    placeholder: '请选择照片',
    hidden: false,
    size: 5,
    ration: 1 / 1,
    rules: [
      { required: true, message: '请上传健康号头像' },
    ],
  },
  {
    type: 'title',
    title: '主体信息',
  },
  {
    name: 'org_name',
    label: '机构名称',
    type: 'input',
    placeholder: '请输入机构名称',
    warning: '机构名称填写请与营业执照名称一致',
    hidden: false,
    rules: [
      { required: true, message: '请填写机构名称' },
    ],
  },
  {
    name: 'identity_urls',
    label: '证件扫描件',
    type: 'multiImageupload',
    placeholder: '请选择照片',
    warning: '请上传组织机构代码证或营业执照，图片须在5MB以内',
    hidden: false,
    rules: [
      { required: true, message: '请上传证件扫描件' },
    ],
  },
];
// 健康号公共表单2 顺序不可随意更改
export const commonForm2 = [
  {
    name: 'area',
    label: '所在地',
    type: 'cascader',
    placeholder: '请选择所在地',
    hidden: false,
    rules: [
      { required: true, message: '请选择所在地' },
    ],
  },
  {
    type: 'title',
    title: '联系人信息',
  },
  {
    name: 'contact_name',
    label: '联系人姓名',
    placeholder: '请输入联系人姓名',
    type: 'input',
    hidden: false,
    rules: [
      { required: true, message: '请填写联系人姓名' },
    ],
  },
  {
    name: 'contact_number',
    label: '联系人电话',
    placeholder: '请输入联系人电话',
    type: 'input',
    hidden: false,
    rules: [
      { required: true, message: '请填写联系人电话' },
      { pattern: /^1[3-9]\d{9}$/g, message: '请填写正确格式电话' },
    ],
  },
  {
    name: 'contact_email',
    label: '联系邮箱',
    placeholder: '请输入联系邮箱',
    type: 'input',
    hidden: false,
    rules: [
      { required: true, message: '请填写联系邮箱' },
      { pattern: Regular.Email, message: '请填写正确格式邮箱' },
    ],
  },
  {
    name: 'contact_person_title',
    label: '联系人职务',
    placeholder: '请输入联系人职务',
    type: 'input',
    hidden: false,
    rules: [
      { required: false, message: '请填写联系人职务' },
      { max: 50, message: '最多50个字符' },
      { min: 2, message: '最少2个字符' },
    ],
  },
  {
    value: '提示：邮箱电话作为审核结果查询渠道，请务必填写正确。',
    type: 'waring',
    hidden: false,
    valueCol: 18,
  },
];

// 政府类型
const governmentShowForm = [
];

// 医院类型
const hospitalShowForm = [
  {
    name: '机构性质',
    label: '机构性质',
    type: 'label',
    value: '机构性质',
  },
  {
    name: '机构类型',
    label: '机构类型',
    type: 'label',
    value: '机构类型',
  },
];
// 媒体类型
const madiaShowForm = [
  {
    name: '媒体类型',
    label: '媒体类型',
    type: 'label',
    value: '媒体类型',
  },
];
// 其他类型
const otherShowForm = [];
// 健康号展示
export const commonShowForm = [
  {
    type: 'title',
    title: '健康号信息',
  },
  {
    name: 'health_code_name',
    label: '健康号名称',
    value: '',
    type: 'text',
  },
  {
    name: 'introduce',
    label: '健康号介绍',
    value: '',
    type: 'text',
  },
  {
    name: 'head_url',
    label: '健康号头像',
    value: '',
    type: 'image',
    ifShow: true,
  },
  {
    type: 'title',
    title: '主体信息',
  },
  {
    name: 'org_name',
    label: '机构名称',
    value: '',
    type: 'text',
  },
  {
    name: 'identity_urls',
    label: '证件扫描件',
    value: '',
    type: 'multiImage',
    ifShow: true,
  },
];
export const commonShowForm2 = [
  {
    name: 'area',
    label: '所在地',
    value: '',
    type: 'text',
  },
  {
    type: 'title',
    title: '联系人信息',
  },
  {
    name: 'contact_name',
    label: '联系人姓名',
    value: '',
    type: 'text',
  },
  {
    name: 'contact_number',
    label: '联系人电话',
    value: '',
    type: 'text',
  },
  {
    name: 'contact_email',
    label: '联系邮箱',
    value: '',
    type: 'text',
  },
  {
    name: 'contact_person_title',
    label: '职务',
    value: '',
    type: 'text',
  },
];
export const typeShowForm = [governmentShowForm, hospitalShowForm, madiaShowForm, otherShowForm];
export const typeShowFormHeader = [{
  name: 'health_code_type',
  label: '已入驻类型',
  type: 'text',
  value: '政府机关',
}, {
  name: 'health_code_type',
  label: '已入驻类型',
  type: 'text',
  value: '医疗卫生机构/专业卫生公共机构',
}, {
  name: 'health_code_type',
  label: '已选入驻类型',
  type: 'text',
  value: '媒体',
}, {
  name: 'health_code_type',
  label: '已选入驻类型',
  value: '其他',
  type: 'text',
}];
// 健康号申请结果页
export const result = {
  2: {
    icon: Checking,
    state: '待审核',
    state_v2: '审核中',
    state_v3: '已催审',
    message: '预计审核将在3-10个工作日内完成，请您耐心等待',
    message_v2: '预计审核将在3-10个工作日内完成，审核中无法撤回，请耐心等待',
    message_v3: '已帮您进行催审，预计审核将在3-10个工作日内完成，请您耐心等待',
    buttonText: '预览资料',
    url: '/mis/health/mynumber?status=preview',
  },
  3: {
    icon: Fail,
    state: '认证失败',
    message: '很遗憾地通知您，您的认证申请未通过，请按照审核意见修改您地认证资料。',
    comment: '审核意见：',
    buttonText: '修改资料',
    url: '/mis/health/authentication',
  },
  4: {
    icon: Success,
    state: '认证成功',
    message: '您的认证申请已成功通过审核，点击确认进入我的健康号，点击完善资料可编辑认证资料。',
    buttonText: '确认',
    url: '/mis/health/mynumber',
  },
  5: {
    icon: Warning,
    state: '临时下线',
    message: '您的健康号被临时下线，请耐心等候',
    comment: '临时下线意见：',
    buttonText: '预览资料',
    url: '/mis/health/mynumber?status=preview',
  },
  6: {
    icon: Fail,
    state: '健康号被删内容下线！',
    message: '您的健康号被删内容下线，若有疑问请咨询健康报社管理员/审核员',
    comment: '下线意见',
  },
};
// 区域级别
export const areaForm = [{
  name: 'level_name',
  label: '区域级别',
  placeholder: '请选择区域级别',
  type: 'select',
  list: [
    { value: '国家级', label: '国家级' },
    { value: '省部级', label: '省部级' },
    { value: '市县级', label: '市县级' },
    { value: '其他', label: '其他' },
  ],
  rules: [
    { required: true, message: '请选择区域级别' },
  ],
},
];

// 审核表单
export const checkList = [
  {
    name: 'status',
    label: '审核结果',
    type: 'radio',
    list: [
      { value: '4', label: '审核通过' },
      { value: '3', label: '审核驳回' },
    ],
    rules: [
      { required: true, message: '请选择审核结果' },
    ],
  },
  {
    name: 'status_content',
    label: '审核意见',
    type: 'textarea',
    placeholder: '请输入审核意见',
    rules: [
      { required: true, message: '请填写审核意见' },
      { min: 2, message: '审核意见至少需要填写2个字' },
      // { max: 200, message: '审核意见需要填写2-200字' },
    ],
  },
];
//  临时下线
export const toffline = [
  {
    name: 'status_content',
    label: '临时下线意见',
    type: 'textarea',
    rules: [
      { required: true, message: '请输入临时下线意见' },
    ],
  },
];
// 永久下线
export const poffline = [
  {
    name: 'status_content',
    label: '永久下线意见',
    type: 'textarea',
    rules: [
      { required: true, message: '请输入永久下线意见' },
    ],
  },
];
export const updateHealthSatte = [checkList, toffline, poffline, []];
export const breadArr = [
  ['健康号', '健康号列表', '审核页面'],
  ['健康号', '健康号列表', '临时下线'],
  ['健康号', '健康号列表', '永久下线'],
  ['健康号', '健康号列表', '上线'],
];
// 健康号类型
export const healthtype = {
  1: '政府机关',
  2: '医疗卫生机构/专业公共卫生机构',
  // 3: '媒体',
  4: '其他',
  6: '医者名片',
  7: '爱国卫生运动',
  8: '健康中国行动',
};
// !!! 机构类型（医院等级）相关逻辑 o
//   机构类型 迁移至orgTypeData
// export const hospitalType = {
//   8: '三级甲等',
//   9: '三级乙等',
//   10: '三级丙等',
//   14: '三级特等',
//   11: '二级甲等',
//   12: '二级乙等',
//   13: '二级丙等',
//   15: '一级甲等',
//   16: '一级乙等',
//   17: '一级丙等',
//   2: '三级医院',
//   3: '二级医院',
//   4: '一级医院',
//   5: '社区卫生服务中心',
//   6: '乡镇卫生院',
//   7: '专业卫生服务机构',
//   1: '其他',
// };
// 医院性质
export const hospitalNature = {
  1: '公立',
  2: '私立',
  3: '非营利性',
  4: '其他',
};

// 媒体类型
export const mediatype = {
  1: '自媒体',
  2: '新闻媒体',
};
// 审核状态
export const checkType = {
  1: '草稿',
  2: '待审核',
  3: '认证失败',
  4: '认证成功',
  5: '临时下线',
  6: '永久下线',
  7: '一审通过',
};
// 健康号审核流状态
export const healthNumberAuditStatus = {
  1: '待审核',
  2: '审核通过',
  3: '审核驳回',
};
export const freeReview = {
  1: '否',
  2: '是',
};
export const mediaType = {
  1: '微信',
  2: '微博',
  3: 'app',
  4: '网页',
  5: 'WAP',
};
export const mediaStatus = {
  1: '草稿',
  2: '审核中',
  3: '审核失败',
  4: '审核成功',
};

// 专题模块
import Request from '../utils/requestAxios';

class ApiTopic extends Request {
  /**
   * 获取专题列表
   * @param {*} params
   */
  getTopicList(params) {
    return this.fetch({
      url: '/topic/list',
      method: 'get',
      params,
    });
  }

  /**
   * 新增专题
   * @param {*} params
   */
  addTopic(params) {
    return this.fetch({
      url: '/topic/add',
      method: 'POST',
      params,
    });
  }

  /**
   * 编辑专题
   * @param {*} params
   */
  editTopic(params) {
    return this.fetch({
      url: '/topic/edit',
      method: 'POST',
      params,
    });
  }

  /**
   * 专题列表重新排序
   * @param {*} params
   */
  reorderTopic(params) {
    return this.fetch({
      url: '/topic/reorder',
      method: 'POST',
      params,
    });
  }

  /**
   * 获取专题信息
   * @param {*} params
   */
  getTopicInfo(params) {
    return this.fetch({
      url: '/topic/info',
      method: 'get',
      params,
    });
  }

  /**
   * 专题上下线
   * @param {*} params
   */
  setTopicOnline(params) {
    return this.fetch({
      url: '/topic/set_online',
      method: 'POST',
      params,
    });
  }

  /**
   * 获取专题列表
   * @param {*} params
   */
  getColumnList(params) {
    return this.fetch({
      url: '/topic/column_list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取专题资讯列表
   * @param {*} params
   */
  getArticleList(params) {
    return this.fetch({
      url: '/topic/column_record_list',
      method: 'get',
      params,
    });
  }

  /**
   * 筛选资讯列表
   * @param {*} params
   */
  getArticleSource(params) {
    return this.fetch({
      url: '/topic/article_select_list',
      method: 'get',
      params,
    });
  }

  /**
   * 专题作品添加列表

   * @param {*} params
   */
  getWorksSource(params) {
    return this.fetch({
      url: '/topic/product_select_list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取频道列表
   * @param {*} params
   */
  getCategoryList() {
    return this.fetch({
      url: '/config/category',
      method: 'get',
    });
  }

  /**
   * 添加资讯
   * @param {*} params
   */
  addArticles(params) {
    return this.fetch({
      url: '/topic/column_record_add',
      method: 'POST',
      params,
      closeErrorTip: true,
    });
  }

  /**
   * 移除资讯
   * @param {*} params
   */
  removeArticle(params) {
    return this.fetch({
      url: '/topic/column_record_delete',
      method: 'POST',
      params,
      closeErrorTip: true,
    });
  }

  /**
   * 获取轮播图列表
   * @param {*} params
   */
  getCarouselList(params) {
    return this.fetch({
      url: '/topic_carousel/list',
      method: 'get',
      params,
    });
  }

  /**
   * 新增轮播图
   * @param {*} params
   */
  addCarousel(params) {
    return this.fetch({
      url: '/topic_carousel/add',
      method: 'POST',
      params,
    });
  }

  /**
   * 调整轮播图顺序
   * @param {*} params
   */
  reorderCarousel(params) {
    return this.fetch({
      url: '/topic_carousel/reorder',
      method: 'POST',
      params,
    });
  }

  /**
   * 编辑录播图
   * @param {*} params
   */
  editCarousel(params) {
    return this.fetch({
      url: '/topic_carousel/edit',
      method: 'POST',
      params,
    });
  }

  /**
   * 置顶资讯
   * @param {*} params
   */
  setTop(params) {
    return this.fetch({
      url: '/topic/set_top',
      method: 'POST',
      params,
    });
  }
}

export default new ApiTopic();

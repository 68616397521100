// 上传
import Request from '../utils/requestAxios';

class Upload extends Request {
  /**
     * 上传图片
     * @param {*} params
     */
  uploadImg(file) {
    const params = new FormData();
    params.append('file', file);// 通过append向form对象添加数据
    return this.fetch({
      url: '/info/upload_img',
      method: 'post',
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
     * 上传图片
     * @param {*} params
     */
  saveImg(params) {
    return this.fetch({
      url: '/info/save_img',
      method: 'post',
      params,
    });
  }

  /**
     * 上传视频
     * @param {*} params
     */
  uploadVideo(params) {
    return this.fetch({
      url: '/info/upload_video',
      method: 'post',
      params,
    });
  }

  /**
     * 上传文件
     * @param {*} params
     */
  uploadFile(file) {
    const params = new FormData();
    params.append('file', file);// 通过append向form对象添加数据
    params.append('file_name', 'upload_file');
    return this.fetch({
      url: '/upload/file',
      method: 'post',
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
     * 上传文件
     * @param {*} params
     */
  downloadFile(params) {
    return this.fetch({
      url: '/upload/file_download',
      method: 'get',
      params,
    });
  }

  /**
   * 火山转码上报
   * @param {*} params
   */
  transcodingVod(params) {
    return this.fetch({
      url: '/upload/vod_record',
      method: 'post',
      params,
    });
  }

  /**
   * 获取转码状态
   * @param {*} params
   */
  getTransStatus(params) {
    return this.fetch({
      url: '/upload/vod_workflow_status',
      method: 'get',
      params,
    });
  }

  uploadKeyImage(data) {
    const params = new FormData();
    Object.keys(data).forEach((key) => {
      params.append(key, data[key]);// 通过append向form对象添加数据
    });
    return this.fetch({
      url: '/upload/file_public',
      method: 'post',
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new Upload();

/*
健康中国-C端首页
*/
import React, { PureComponent } from 'react';
import {
  Button, Modal,
} from 'antd';
import { apiHealth } from '../../../API';
import UserStore from '../../../store/user';
import util from '../../../utils';
import url from '../../../utils/url';
import Banner from '../components/banner';
import DoctorAgreementBanner from '../components/doctorAgreementBanner';
import styles from './index.module.scss';
import './index.scss';

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasSigned: false, // 是否已签署倡议书
      hasSignBtn: false, // 是否展示按钮
    };
    this.methods = this.getMethods();
    this.domEvents = this.getDomEvents();
  }

  async componentDidMount() {
    document.title = '健康中国管理系统';
    const params = url.getPageQuery();
    if (params.show !== 'true') {
      const hasSignBtn = await this.methods.initHasSignBtn();
      // 若无权限直接返回
      if (hasSignBtn) {
        const has_signed = await this.methods.initSignAgreement();
        if (!has_signed) {
          this.methods.initCheck();
        }
      }
    }
  }

  getMethods() {
    return {
      // 初始化签订状态
      initSignAgreement: async () => {
        try {
          const res = await apiHealth.getSignAgreement({ proposal_id: 1 });
          const { has_signed } = res || {};
          this.setState({
            hasSignBtn: true,
            hasSigned: !!has_signed,
          });
          return !!has_signed;
        } catch {}
      },
      initHasSignBtn: async () => {
        if (!UserStore.userInfo || !UserStore.userInfo.id) {
          await UserStore.update();
        }
        const { userInfo } = UserStore;
        const root = userInfo?.permissions;
        const hasSignBtn = !!(root && root.length && root.find((item) => item === 'proposal_sign'));
        this.setState({
          hasSignBtn,
        });
        return hasSignBtn;
      },
      signAgreement: async () => {
        try {
          const res = await apiHealth.signAgreement({ proposal_id: 1 });
          const { signed_count } = res;
          Modal.success({
            title: '签订成功！',
            content: `恭喜您成为第${signed_count}个签订”健康中国 医者先行“倡议书的健康号！`,
          });
          this.methods.initSignAgreement();
        } catch (error) {
          console.error(error);
        }
      },
      // 检查是否符合签订条件
      initCheck: async () => {
        try {
          // 如果用户信息没有获取之前进入该页面，则直接请求接口。
          if (!UserStore.userInfo || !UserStore.userInfo.id) {
            await UserStore.update();
          }
          const { userInfo } = UserStore;
          const {
            role_id, health_number_type, org_type, has_hospital_info, health_number_status, org_nature,
          } = userInfo;

          // 检查是否是个人用户 个人用户跳转认证页面
          if (role_id === 1) {
            return this.methods.goReCert();
          }

          // !!! 机构类型（医院等级）相关逻辑 o
          // 检查是否是医院机构类型 未认证黄页信息的跳转认证页面
          // 三级医院判定 2 8 9 10 三级、甲、乙、丙
          const isThirdLevelHosp = org_type === 2 || org_type === 8 || org_type === 9 || org_type === 10;
          if (health_number_type === 2 && isThirdLevelHosp && org_nature === 1 && !has_hospital_info) {
            return this.methods.goReCertStep();
          }

          // 健康号状态未认证成功
          if (health_number_status !== 4) {
            return this.methods.goReCert();
          }
          // 次数校验
          // const { edit_count = 0, has_hospital_info = false, health_code_type } = await apiHealth.getAppliedDetail();
          // hasEditCount = edit_count < 3;
        } catch (error) {
          console.error('活动资格限制', error);
        }

        // 检查是否登陆，如果未登陆，去登陆页面
        if (!UserStore.userInfo || !UserStore.userInfo.id) {
          UserStore.update(false).catch(() => {
            util.goLogin();
          });
        }

        // const res = await
      },
      goReCert: () => {
        const ok = async () => {
          this.props.history.push('/mis/health/authentication');
          Modal.destroyAll();
        };
        Modal.info({
          content: '仅支持健康号用户签订倡议书',
          onOk: ok,
          okText: '去认证',
          mask: true,
        });
      },
      goReCertStep: () => {
        const ok = async () => {
          this.props.history.push('/mis/health/authentication?step=1'); // step 为 1 直接跳入填写认证资料页面
          Modal.destroyAll();
        };
        Modal.info({
          content: '签订倡议书前需先完善健康号信息',
          onOk: ok,
          okText: '去完善',
          mask: true,
        });
      },
    };
  }

  getDomEvents() {
    return {
    };
  }

  render() {
    const {
      hasSigned = false, hasSignBtn = false,
    } = this.state;
    return (
      <div className={`${styles.wrap} doctor-agreement`}>
        <Banner {...this.props} />
        <DoctorAgreementBanner {...this.props} />
        <div className={styles.content}>
          {/* 通知 */}
          <div className={styles.toast}>
            为动员广大医疗卫生机构、专业公共卫生机构及医务人员积极践行健康中国行动，发挥在推进健康中国建设中的带头示范作用，在健康中国行动推进委员会办公室的指导下，健康报社牵头主办“健康中国 医者先行”案例征集与交流研讨活动。
            活动将通过倡议书签署、培训交流、典型发掘等形式，动员全国广大医疗卫生机构和专业公共卫生机构积极践行健康中国行动，组织医务人员带头行动、率先垂范，宣传展示机构在实际工作中涌现出的典型经验、做法，营造良好舆论氛围，强化民众满意度和获得感。
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              全国广大医疗机构：
            </div>
            <div className={styles.paragraph}>
              推进健康中国建设是以习近平同志为核心的党中央坚持以人民为中心，坚持和发展新时代中国特色社会主义，全面提升中华民族健康素质，实现人民健康与经济社会协调发展作出的重大决策部署。
              2019年，国务院成立健康中国行动推进委员会，统筹推进实施健康中国行动。
              为动员广大医疗机构积极践行健康中国行动，并组织医务人员带头行动、率先垂范，充分发挥在推进健康中国建设中的重要作用，在健康中国行动推进委员会办公室的指导下，中国医院协会决定组织开展“健康中国医者先行”活动，特发出以下倡议：
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sub_title}>
              1.提高认识、转变观念。
            </div>
            <div className={styles.paragraph}>
              深刻领会、准确把握党中央、国务院关于推进健康中国建设决策部署的重大意义，进一步落实大卫生、大健康理念和预防为主方针，促进从“以治病为中心”向“以健康为中心”转变。
              医疗机构要高度重视医务人员身心健康，开展多种多样的健康促进活动，倡导文明健康绿色环保生活方式，组织医务人员以身作则，以实际行动引导公众坚持预防为主，健康生活，防控重大疾病。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sub_title}>
              2.创新模式、提升质量。
            </div>
            <div className={styles.paragraph}>
              把医疗机构作为健康教育的重要平台和医防结合的前沿阵地，促进城市医联体、县域共同体建设，完善分级诊疗制度，构建整合型医疗服务模式，提高服务体系整体质量和效率，为人民群众提供全方位全生命周期健康服务。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sub_title}>
              3.健全机制、形成合力。
            </div>
            <div className={styles.paragraph}>
              在乡村振兴、基层帮扶、对口援建等工作中，全面融入健康促进内容，发展“互联网+医疗+预防”，持续提升基层卫生服务能力。
              充分依靠专家力量，依托协会各分支机构和各地行业协会，动员社会力量广泛参与，调动各方面的积极性和融入度，形成“共建共享，全民健康”的合力。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sub_title}>
              4.突出重点、精准发力。
            </div>
            <div className={styles.paragraph}>
              一方面，紧紧围绕推进健康中国建设的战略目标，实施重大疾病防治行动，推进慢病和重大传染病防、治、管融合发展；
              在诊疗过程中主动提供健康指导，提升健康素养，塑造自主自律的健康行为。
              另一方面，深入推进精细化管理，不断提升医疗机构的治理能力和管理水平，持续改进服务质量，保证患者安全，促进医疗机构高质量发展。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sub_title}>
              5.加强领导、务求实效。
            </div>
            <div className={styles.paragraph}>
              健康中国行动是一项长期的任务，希望各医疗机构加强对“健康中国 医者先行”活动的领导，结合本地的疾病谱和影响健康的突出问题，制定实实在在的举措，扎扎实实推进各项任务，保障行动落地见效，提高人民群众健康水平。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.paragraph}>
              让我们更加紧密地团结在以习近平同志为核心的党中央周围，以更强的责任感、使命感，全力投入健康中国建设，争当健康中国建设的先行者、排头兵和主力军，为不断提升广大人民群众的健康水平，推进实现中华民族伟大复兴的中国梦做出积极贡献！
            </div>
          </div>
          <div className={styles.section}>
            <div className={`${styles.paragraph} ${styles.text_right}`}>
              中国医院协会
              <br />
              2021年9月26日
            </div>
          </div>
          { hasSignBtn
          && (
            <div className={styles.section}>
              <div className={`${styles.paragraph} ${styles.text_right}`}>
                {
                  hasSigned
                    ? <Button disabled type="primary">您的健康号已签订倡议书</Button>
                    : <Button type="primary" onClick={this.methods.signAgreement}>签订倡议书</Button>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Home;

// 资讯管理管理模块
import Request from '../utils/requestAxios';

class APISys extends Request {
  /**
     * 获取资讯列表列表
     * @param {*} params
     */
  getInfoList(params) {
    return this.fetch({
      url: '/info/list',
      method: 'get',
      params,
    });
  }

  /**
     * 删除资讯
     * @param {*} params
     */
  delInfo(params) {
    return this.fetch({
      url: '/info/delete',
      method: 'post',
      params,
    });
  }

  /**
     * 获取资讯
     * @param {*} params
     */
  getInfoDetial(params) {
    return this.fetch({
      url: '/info/edit_data',
      method: 'get',
      params,
    });
  }

  /**
     * 获取健康号
     * @param {*} params
     */
  handleHealthNum(params) {
    return this.fetch({
      url: '/info/get_select_health_number',
      method: 'get',
      params,
      closeErrorTip: true,
    });
  }

  /**
     * 编辑资讯
     * @param {*} params
     */
  setInfo(params) {
    return this.fetch({
      url: '/info/edit_save',
      method: 'post',
      params,
    });
  }

  /**
     * 置顶资讯
     * @param {*} params
     */
  setTop(params) {
    return this.fetch({
      url: '/info/set_top',
      method: 'post',
      params,
    });
  }

  /**
     * 设置资讯状态
     * @param {*} params
     */
  setStatus(params) {
    return this.fetch({
      url: '/info/set_status',
      method: 'post',
      params,
    });
  }

  /**
     * 设置资讯频道
     * @param {*} params
     */
  setCategory(params) {
    return this.fetch({
      url: '/info/set_category',
      method: 'post',
      params,
    });
  }

  setOnline(params) {
    return this.fetch({
      url: '/info/set_online',
      method: 'post',
      params,
    });
  }

  /**
    * 预览资讯
    * @param {*} params
    */
  previewInfo(params) {
    return this.fetch({
      url: '/info/generate_preview',
      method: 'post',
      params,
    });
  }

  /**
     * 新建资讯
     * @param {*} params
     */
  createInfo(params) {
    return this.fetch({
      url: '/info/create',
      method: 'post',
      params,
    });
  }

  /**
     * 取消编辑资讯
     * @param {*} params
     */
  cancelInfo(params) {
    return this.fetch({
      url: '/info/edit_cancel',
      method: 'post',
      params,
    });
  }

  /**
     * 预览资讯
     * @param {*} params
     */
  viewInfo(params) {
    return this.fetch({
      url: '/info/view',
      method: 'get',
      params,
    });
  }

  previewPublicInfo(params) {
    return this.fetch({
      url: '/info/preview_public',
      method: 'get',
      params,
    });
  }

  /**
     * 预览链接
     * @param {*} params
     */
  previewLink(params) {
    return this.fetch({
      url: '/info/preview_link',
      method: 'get',
      params,
    });
  }

  /**
   * 预览链接
   * @param {*} params
   */
  previewToken(params) {
    return this.fetch({
      url: '/info/preview_token',
      method: 'get',
      params,
    });
  }

  /**
     * 预览信息详情
     * @param {*} params
     */
  getPreviewDetail(params) {
    return this.fetch({
      url: '/info/preview_public',
      method: 'get',
      params,
    });
  }

  /**
   * 推送至App
   * @param {*} params
   */
  pushToApp(params) {
    return this.fetch({
      url: '/info/push_to_app',
      method: 'get',
      params,
    });
  }

  /**
   * 设置轮播图
   * @param {*} params
   */
  setCarousel(params) {
    return this.fetch({
      url: '/info/set_carousel',
      method: 'post',
      params,
    });
  }

  /**
   * 获取聚合资讯列表
   * @param {*} params
   */
  getGrabList(params) {
    return this.fetch({
      url: '/grab/list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取自助聚合媒体号列表
   * @param {*} params
   */
  getAutomaticList(params) {
    return this.fetch({
      url: '/grab/automatic/list',
      method: 'get',
      params,
    });
  }

  /**
 * 获取创建中的健康号列表
 * @param {*} params
 */
  getAutomaticHealthSearch(params) {
    return this.fetch({
      url: '/grab/automatic/health_search',
      method: 'get',
      params,
    });
  }

  /**
* 获取创建中健康号ID查对应的媒体号
* @param {*} params
*/
  getAutomaticHealthInfo(params) {
    return this.fetch({
      url: '/grab/automatic/health_info',
      method: 'get',
      params,
    });
  }

  /**
  * 设置自助媒体号状态
  * @param {*} params
  */
  setAutomaticStatus(params) {
    return this.fetch({
      url: '/grab/automatic/change_status',
      method: 'post',
      params,
    });
  }

  /**
   * 创建自助聚合媒体号
   * @param {*} params
   */
  createGrabAutomatic(params) {
    return this.fetch({
      url: '/grab/automatic/create',
      method: 'post',
      params,
    });
  }

  /**
   * 创建聚合资讯
   * @param {*} params
   */
  createGrab(params) {
    return this.fetch({
      url: '/grab/create',
      method: 'post',
      params,
    });
  }
}

export default new APISys();

/*
健康中国-C端首页
*/
import React, { PureComponent } from 'react';
import {
  Tabs, Input, Icon, Pagination, Spin,
} from 'antd';
import moment from 'moment';
import PageStateStore from '../../../store/pageState';
import { apiActivity } from '../../../API';
import util, { defaultImg } from '../../../utils';
import Banner from '../components/banner';
import DoctorAgreementBanner from '../components/doctorAgreementBanner';
import styles from './index.module.scss';
import './index.scss';

const { TabPane } = Tabs;
class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.pageKey = 'home_page';
    const isHistoryBack = util.historyBack(); // 判断页面是否是返回
    let cacheState = {};
    if (isHistoryBack) {
      cacheState = PageStateStore.getState(this.pageKey);
    }
    const {
      page = 1, name = '', pageSize = 21, currTab = '0',
    } = cacheState;
    this.state = {
      page, // 当前页数
      name, // 搜索名字
      pageSize, // 分页大小
      currTab, // 当前tab
      dataSource: [],
      total: 0,
      loading: false,
      // isHistoryBack, // 是否为页面返回
    };
    this.methods = this.getMethods();
    this.domEvents = this.getDomEvents();
    this.domEvents.handleSearch = util.debounce(this.domEvents.handleSearch, 300);
  }

  componentDidMount() {
    document.title = '健康中国管理系统';
    this.methods.getList();
  }

  getMethods() {
    return {
      // 获取列表数据
      getList: () => {
        const {
          page = 1, pageSize = 1, currTab, name,
        } = this.state;
        this.setState({
          loading: true,
        });
        apiActivity.getOpenList({
          page,
          page_size: pageSize,
          phase: currTab,
          activity_name: name,
        }).then((data) => {
          const { items, total = 0 } = data;
          this.setState({
            dataSource: items,
            total,
          });
        }).finally(() => {
          this.setState({
            loading: false,
          });
        });
      },
    };
  }

  getDomEvents() {
    return {
      onChange: (page) => {
        this.setState({
          page,
        }, () => {
          this.methods.getList();
        });
      },
      onShowSizeChange: (current, size) => { // pageSize 变化的回调
        this.setState({
          pageSize: size,
          page: current,
        }, () => {
          this.methods.getList();
        });
      },
      handleSearch: (event) => {
        const { target } = event;
        const { value } = target;
        this.setState({
          name: value,
        }, () => {
          this.methods.getList();
        });
      },
      tabChange: (v) => {
        this.setState({ currTab: v, page: 1 }, () => {
          this.methods.getList();
        });
      },
      goDetial: (acid) => {
        const {
          page, name, pageSize, currTab,
        } = this.state;
        PageStateStore.saveState({
          page, name, pageSize, currTab,
        }, this.pageKey);
        util.goUrl(this.props.history, `/activity/detail/${acid}`);
      },
    };
  }

  render() {
    const { domEvents } = this;
    const {
      loading = false, dataSource, total, pageSize, page, name, currTab,
    } = this.state;
    // const cardStatusTxt = {
    //   0: '',
    //   1: '进行中',
    //   2: '已结束',
    // };
    const cardPhaseTxt = {
      0: '',
      1: '进行中', // '活动进行中，复合状态，可能为报名未开始，主要用来过滤搜索',
      2: '即将开始', // 报名未开始
      3: '报名中',
      4: '投票中',
      5: '已结束',
    };
    return (
      <div className={`${styles.wrap} home-page`}>
        <Banner {...this.props} />
        <DoctorAgreementBanner {...this.props} />
        <div className={styles.searchWrap}>
          <div className={styles.searchBox}>
            <div className={`fl ${styles.leftBox}`}>
              <Tabs defaultActiveKey={currTab} onChange={domEvents.tabChange}>
                <TabPane tab="全部" key="0" />
                <TabPane tab="进行中" key="1" />
                <TabPane tab="已结束" key="5" />
              </Tabs>
            </div>
            <div className={`fr ${styles.rBox}`}>
              <Input
                defaultValue={name}
                placeholder="请输入关键词"
                onChange={domEvents.handleSearch}
                style={{ width: 400 }}
                prefix={(
                  <Icon type="search" />
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <Spin tip="加载中..." spinning={loading}>
            {
              Array.isArray(dataSource) && dataSource.length > 0 ? util.arrTrans(dataSource, 3).map((itemArr, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={`${styles.itemLine} clearfix`} key={index}>
                  {
                    itemArr.map((item, subIndex) => (
                      <div
                        className={`${styles.card} ${subIndex === 1 ? styles.centerItem : ''} fl`}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index + subIndex}
                        onClick={() => { domEvents.goDetial(item.id); }}
                      >
                        <div className={styles.topImg}>
                          <img src={item.thumbnail || defaultImg} alt="" loading="lazy" />
                        </div>
                        <div className={styles.cTitle}>{item.name}</div>
                        <div className={styles.time}>
                          投稿时间：
                          {moment(+item.collect_start_time * 1000).format('YYYY-MM-DD')}
                          &nbsp;至&nbsp;
                          {moment(+item.collect_end_time * 1000).format('YYYY-MM-DD')}
                        </div>
                        <div className={`${styles.btmBox} clearfix`}>
                          <div className={`${styles.statusBtn} ${item.phase === 5 ? styles.statusOver : ''} fl`}>{cardPhaseTxt[item.phase]}</div>
                          <div className={`${styles.num} fr`}>
                            {item.product_count}
                            &nbsp;作品
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )) : <div className="no-data-tip">-暂无活动-</div>
            }
          </Spin>
        </div>
        <div className={styles.paginationBox}>
          <Pagination
            showSizeChanger
            showQuickJumper
            current={page}
            total={total}
            pageSize={pageSize}
            showTotal={(_total) => `共 ${_total} 条`}
            onChange={domEvents.onChange}
            onShowSizeChange={domEvents.onShowSizeChange}
            pageSizeOptions={['21', '42']}
          />
        </div>
      </div>
    );
  }
}

export default Home;

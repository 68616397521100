// 健康号
import Request from '../utils/requestAxios';

class HealthNumber extends Request {
  /**
   * 设置健康号催审
   * @param {*} params
   */
  setSubmitRemindAudit(params) {
    return this.fetch({
      url: '/healthynumber/submit_remind_audit',
      method: 'get',
      params,
    });
  }

  /**
     * 健康号认证 + 新建
     * @param {*} params
     */
  certHealthNumber(params) {
    return this.fetch({
      url: '/healthynumber/certification',
      method: 'post',
      params,
    });
  }

  /**
     * 健康号审核状态
     * @param {*} params
     */
  healthState(params) {
    return this.fetch({
      url: '/healthynumber/status',
      method: 'get',
      params,
    });
  }

  /**
     * 判断健康号是否存在
     * @param {*} params
     */
  checkHealthNumber(params) {
    return this.fetch({
      url: '/healthynumber/exist',
      method: 'get',
      params,
    });
  }

  /**
     * 获取健康号信息，返回结果id === -1 则不存在健康号
     * @param {*} params
     */
  getAppliedDetail(params) {
    return this.fetch({
      url: '/healthynumber/applied_detail',
      method: 'get',
      params,
    });
  }

  /**
     * 获取健康号信息
     * @param {*} params
     */
  getHealthDetail(params) {
    return this.fetch({
      url: '/healthynumber/detail',
      method: 'get',
      params,
    });
  }

  /**
     * 获取审核媒体号列表
     * @param {*} health_code_id
     */
  getCheckMediaList(params) {
    return this.fetch({
      url: '/healthynumbermedia/audit_list',
      method: 'get',
      params,
    });
  }

  /**
     * 我的媒体号列表
     * @param {*} health_code_id
     */
  getMyMediaList(params) {
    return this.fetch({
      url: '/healthynumbermedia/my_list',
      method: 'get',
      params,
    });
  }

  /**
     * 添加新媒体
     * @param {*} params
     */
  addMedia(params) {
    return this.fetch({
      url: '/healthynumbermedia/add',
      method: 'POST',
      params,
    });
  }

  /**
     * 更新媒体号状态  1 待审核 2 审核中 3 审核通过 4 审核失败 5 删除
     * @param {*}
     */
  updateMedia(params) {
    return this.fetch({
      url: '/healthynumbermedia/status',
      method: 'PUT',
      params,
    });
  }

  /**
     * 获取健康号列表
     * @param {*} params
     */
  getHealthList(params) {
    return this.fetch({
      url: '/healthynumber/list',
      method: 'GET',
      params,
    });
  }

  /**
     * 获取健康号审核列表
     * @param {*} params
     */
  getHealthAuditList(params) {
    return this.fetch({
      url: '/healthynumber/review_list',
      method: 'GET',
      params,
    });
  }

  /**
   * 获取健康号审核列表审核记录
   * @param {*} params
   */
  getHealthAuditReviewLogList(params) {
    return this.fetch({
      url: '/healthynumber/review_log',
      method: 'GET',
      params,
    });
  }

  /**
   * 获取健康号审核变更对比查询
   * @param {*} params
   */
  getHealthAuditSnapshotDetail(params) {
    return this.fetch({
      url: '/healthynumber/snapshot_detail',
      method: 'GET',
      params,
    });
  }

  /**
     * 健康号列表 审核 , 下线
     * @param {*} params
     * @id 健康号id
     * @status 1 草稿 2 审核中 3 审核失败 4 审核成功 5 临时下线 6 删除
     * @status 1 待审核 2 退回 3 通过 4 临时下线 5删除、永久下线
     * @status_content 理由
     */
  updateHealthNumber(params) {
    return this.fetch({
      url: '/healthynumber/change_status',
      method: 'POST',
      params,
    });
  }

  /**
     * 健康号列表 编辑
     * @param {*} params
     */
  editNumber(params) {
    return this.fetch({
      url: '/healthynumbermedia/add',
      method: 'POST',
      params,
    });
  }

  /**
     * 健康号列表 提交
     * @param {*} params
     */
  submitNumber(params) {
    return this.fetch({
      url: '/healthynumbermedia/add',
      method: 'POST',
      params,
    });
  }

  /**
     * 健康号列表 设置区域级别
     * @param {*} params
     * @level_name 区域级别
     * @id 健康号id
     */
  setArea(params) {
    return this.fetch({
      url: '/healthynumber/setlevelname',
      method: 'PUT',
      params,
    });
  }

  /**
     * 聚合内容是否免审核
     * @param {*} params
     * @free_review 1 上级审核 2 免审
     * @id 健康号id
     */
  freeReview(params) {
    return this.fetch({
      url: '/healthynumber/setfreereview',
      method: 'PUT',
      params,
    });
  }

  /**
     * 健康号列表 管理角色
     * @param {*} params
     */
  setRole(params) {
    return this.fetch({
      url: '/healthynumbermedia/add',
      method: 'POST',
      params,
    });
  }

  /**
     * 健康号列表 临时下线
     * @param {*} params
     */
  offline(params) {
    return this.fetch({
      url: '/healthynumbermedia/add',
      method: 'POST',
      params,
    });
  }

  /**
     * 健康号列表 永久下线
     * @param {*} params
     */
  perOffline(params) {
    return this.fetch({
      url: '/healthynumbermedia/add',
      method: 'POST',
      params,
    });
  }

  /**
     * 健康号列表 设置上级健康号
     * @param {*} params
     */
  setSuperior(params) {
    return this.fetch({
      url: '/healthynumber/setparents',
      method: 'PUT',
      params,
    });
  }

  /** *
     * 获取组织关系
     */
  getRelationShip(params) {
    return this.fetch({
      url: '/healthynumber/relationship',
      method: 'get',
      params,
    });
  }

  /** *
   * 健康号认证通过确认
   */
  confirmHealthyNumber(params) {
    return this.fetch({
      url: '/healthynumber/confirm',
      method: 'post',
      params,
    });
  }

  /** *
   * 健康号认证通过确认
   */
  checkLotusHospName(params) {
    return this.fetch({
      url: '/healthynumber/xiaohe_hospital_check',
      method: 'get',
      params,
    });
  }

  /** *
   * 小荷医院拓展信息接口
   */
  getLotusHospInfo(params) {
    return this.fetch({
      url: '/healthynumber/xiaohe_hospital_info',
      method: 'get',
      params,
    });
  }

  /** *
   * 提交小荷医院拓展信息接口
   */
  saveLotusHospInfo(params) {
    return this.fetch({
      url: '/healthynumber/xiaohe_hospital_info_save',
      method: 'post',
      params,
    });
  }

  /** *
   * 获取医生科室信息，医者名片
   */
  getDoctorDepartment() {
    return this.fetch({
      url: '/config/doctor_department',
      method: 'get',
    });
  }

  /**
   * 获取生成海报信息数据
   */
  getShareDoctor(params) {
    return this.fetch({
      url: '/healthynumber/doctor_share_card',
      method: 'get',
      params,
    });
  }

  /**
   * 获取医生动态积分列表数据
   * @param {}} params
   */
  getDoctorScoreList(params) {
    return this.fetch({
      url: '/healthynumber/doctor_score_list',
      method: 'get',
      params,
    });
  }

  /**
     * 医者先行 倡议书签订
     * @param {*} params
     */
  signAgreement(params) {
    return this.fetch({
      url: '/healthynumber/sign_proposal',
      method: 'post',
      params,
      closeErrorTip: true,
    });
  }

  /**
     * 医者先行 倡议书签订 状态获取
     * @param {*} params
     */
  getSignAgreement(params) {
    return this.fetch({
      url: '/healthynumber/proposal_status',
      method: 'get',
      params,
      closeErrorTip: true,
    });
  }

  /**
     * 医院信息同步到小荷
     * @param {*} params
     */
  pushHospInfoToXiaoHe(params) {
    return this.fetch({
      url: '/healthynumber/hospital_data_sync_xiaohe',
      method: 'post',
      params,
    });
  }

  /**
   * 健康号审核撤回
   * @param {*} params
   */
  postHealthyNumberAuditCancel(params) {
    return this.fetch({
      url: '/healthynumber/audit_cancel',
      method: 'post',
      params,
    });
  }

  /**
   * 健康号审核锁
   * @param {*} params
   */
  postHealthyNumberAuditLock(params) {
    return this.fetch({
      url: '/healthynumber/audit_lock',
      method: 'post',
      params,
    });
  }

  /**
   * 更新健康号主页资讯置顶
   * @param id 健康号id
   * @param article_uid 置顶资讯uid
   * @param update_type 更新类型 1.替换更新 2.取消置顶 3.追加更新 //此次需求只需要传类型1或2，3是为了多个置顶资讯预留的
   */
  updateTopArticle(params) {
    return this.fetch({
      url: '/healthynumber/update_top_article',
      method: 'get',
      params,
    });
  }

  /**
   * 获取健康号主页的置顶资讯列表
   */
  getTopArticle(params) {
    return this.fetch({
      url: '/healthynumber/top_article_list',
      method: 'get',
      params,
    });
  }
}

export default new HealthNumber();

/*
系统管理模块-角色管理
*/
import React, { PureComponent } from 'react';
import {
  Form, Input, Button, Modal, message,
} from 'antd';

import { apiCommon } from '../../API';
// import { Storage } from '../../utils';
import UserStore from '../../store/user';
import styles from './index.module.scss';
import logo from './img/logo.png';
import downloadQr from './img/download.png';
// import iosQr from './img/iosQr.png';
// import androidQr from './img/androidQr.png';
import util from '../../utils';
import GlobalNotify from '@/utils/globalNotify';

class Login extends PureComponent {
  domEvents = {
    closeModal: () => {
      this.setState({
        codeValue: '',
        isModalVisible: false,
      });
    },
    showModal: () => {
      if (this.state.sendCount) {
        return;
      }
      if (!this.props.form.getFieldValue('phone_num')) {
        message.error('手机号不能为空！');
        return;
      }
      if (!this.props.form.getFieldError('phone_num')) {
        apiCommon.getVerificationImg().then((res) => {
          if (res.data && res.captcha_id) {
            this.captcha_id = res.captcha_id;
            this.setState({
              verificationImgUrl: res.data,
              isModalVisible: true,
            });
          }
        });
      }
    },
    getCode: () => {
      const {
        codeValue,
      } = this.state;
      const phone_num = this.props.form.getFieldValue('phone_num');
      apiCommon.getVerificationCode({
        captcha: codeValue,
        captcha_id: this.captcha_id,
        phone_num,
      }).then(() => {
        message.success('请在手机中查看短信验证码');
        this.setState({
          codeValue: '',
          isModalVisible: false,
          sendCount: 60,
        }, this.methods.changeCount);
      }).catch(() => {
        this.domEvents.changeImg();
      });
    },
    changeImg: () => {
      apiCommon.getVerificationImg().then((res) => {
        if (res.data && res.captcha_id) {
          this.captcha_id = res.captcha_id;
          this.setState({
            codeValue: '',
            verificationImgUrl: res.data,
          });
        }
      });
    },
    login: () => {
      this.setState({
        btnLoading: true,
      });
      this.props.form.validateFields().then((res) => {
        apiCommon.loginByCode(res).then((data) => {
          UserStore.setUserInfo(data);
          this.methods.goRedirectUrl();
          setTimeout(() => {
            GlobalNotify.initPushAuditHealthNumberTips();
          }, 500);
        });
      }).finally(() => {
        this.setState({
          btnLoading: false,
        });
      });
    },
    toAppealPage: () => {
      this.props.history.push('/appeal');
    },
    toAgreement: () => {
      window.open('https://m.jkzg2030.cn/agreement');
    },
    toAgreementPrivacy: () => {
      window.open('https://m.jkzg2030.cn/agreementprivacy');
    },
  };

  methods = {
    isLogin: () => {
      UserStore.update().then(() => {
        this.methods.goRedirectUrl();
      }).catch((er) => {
        // eslint-disable-next-line no-console
        console.log('error', er);
      });
    },
    goRedirectUrl: () => {
      setTimeout(() => {
        if (this.redirectUrl) {
          const _url = new URL(this.redirectUrl);
          if (_url.hash === '#/mis/health/authentication?step=1') {
            // this.props.history.push('/mis/health/authentication');
            this.props.history.push('/mis/welcome');
          } else {
            window.location.href = this.redirectUrl;
          }
        } else {
          // 登陆后 无重定向地址 默认跳转欢迎页
          this.props.history.push('/mis/welcome');
        }
      });
    },
    changeCount: () => {
      const {
        sendCount,
      } = this.state;
      if (!sendCount) {
        return;
      }
      const timer = setTimeout(() => {
        this.setState({
          sendCount: sendCount - 1,
        }, () => {
          clearTimeout(timer);
          this.methods.changeCount();
        });
      }, 1000);
    },
  }

  constructor(props) {
    super(props);
    this.captcha_id = '';
    this.redirectUrl = '';
    document.title = '健康中国管理平台';
    this.state = {
      isModalVisible: false,
      verificationImgUrl: '',
      codeValue: '',
      sendCount: 0,
      btnLoading: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { search } = location || {};
    const searchParams = util.getUrlParams(search);
    const { redirect } = searchParams; // source logout: 右上角退出登录 api: 接口未登录
    if (redirect) {
      this.redirectUrl = decodeURIComponent(redirect);
    }
    this.methods.isLogin();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isModalVisible = false,
    } = this.state;
    return (
      <div className={styles.loginContainer}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="" />
        </div>
        <div className={styles.loginFormBox}>
          <div className={styles.upBox}>
            <p className={styles.title}>验证码登录</p>
            <Form>
              <Form.Item>
                {getFieldDecorator('phone_num', {
                  rules: [
                    { required: true, message: '手机号不能为空' },
                    { max: 11, message: '手机号格式错误' },
                    { min: 11, message: '手机号格式错误' },
                  ],
                })(
                  <Input
                    type="text"
                    className={styles.phoneInput}
                    prefix={<div className={styles.prefix}>+86</div>}
                    placeholder="手机号"
                    autoFocus="autoFocus"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('code', {
                  rules: [
                    { required: true, message: '验证码不能为空' },
                  ],
                })(
                  <Input
                    type="text"
                    className={styles.codeInput}
                    suffix={<div className={styles.suffix} onClick={this.domEvents.showModal}>{!this.state.sendCount ? '获取验证码' : `${this.state.sendCount}s`}</div>}
                    placeholder="验证码"
                  />
                )}
              </Form.Item>
            </Form>
            <p className={styles.loginState}>
              登录即同意
              <span onClick={this.domEvents.toAgreement}>
                {' 《用户协议》 '}
              </span>
              和
              <span onClick={this.domEvents.toAgreementPrivacy}>
                {' 《隐私政策》 '}
              </span>
            </p>
            <Button className={styles.loginBtn} loading={this.state.btnLoading} onClick={this.domEvents.login}>登录</Button>
            <p className={styles.loginInstruct}>未注册的手机号将自动注册并登录</p>
            <p><span className={styles.appeal} onClick={this.domEvents.toAppealPage}>账号申诉</span></p>
          </div>
          <div className={styles.downBox}>
            {/* <div className={styles.QrContainer}>
              <img src={iosQr} alt="" />
              <p className={styles.text}>IOS版下载</p>
            </div>
            <div className={styles.QrContainer}>
              <img src={androidQr} alt="" />
              <p className={styles.text}>Android版下载</p>
            </div> */}
            <div className={styles.QrContainer}>
              <img src={downloadQr} alt="" />
              <p className={styles.text}>健康中国客户端</p>
            </div>
            <p className={styles.instruction}>
              原有的微信登录或QQ登录的用户，请先下载健康中国App，进行手机号绑定，再通过手机号登录“健康中国管理平台”。
            </p>
          </div>
        </div>
        <Modal
          title={null}
          visible={isModalVisible}
          footer={null}
          onCancel={this.domEvents.closeModal}
          closeIcon={null}
          width={380}
          wrapClassName={styles.loginModal}
        >
          <p className={styles.modalTitle}>输入图片验证码</p>
          <div className={styles.inputContainer}>
            <Input
              type="text"
              value={this.state.codeValue}
              placeholder="请输入图形验证码"
              onChange={(e) => {
                this.setState({
                  codeValue: e.target.value,
                });
              }}
            />
            <div className={styles.verification}>
              <img src={this.state.verificationImgUrl} alt="" />
            </div>
            <p className={styles.changeImg} onClick={this.domEvents.changeImg}>换一张</p>
          </div>
          <div className={styles.btnContainer}>
            <Button onClick={this.domEvents.closeModal}>取消</Button>
            <Button type="primary" onClick={this.domEvents.getCode}>确定</Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({})(Login);

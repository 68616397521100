// import { decorate, observable } from 'mobx';
/**
 * 存放页面状态字段，eg: 页面筛选字段、分页等信息
 */
class PageState {
    pagesState = {};

    saveState(data = {}, key = location.href) { // 保存字段
      this.pagesState[key] = data;
    }

    getState(key = location.href) { // 保存字段
      return this.pagesState[key] || {};
    }
}

// decorate(PageState, {
//   pagesState: observable,
// });

export default new PageState();

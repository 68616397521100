// 热门机构
import Request from '../utils/requestAxios';

class PopIns extends Request {
  /**
     * 医者先行 排行榜列表
     * @param {*} params
     */
  getHospitalRank(params) {
    return this.fetch({
      url: '/healthynumber/hospital_ranking',
      method: 'get',
      params,
    });
  }

  /**
   * 热门机构发布
   * @param {*} params
   */
  popHospitalRankCommit(params) {
    return this.fetch({
      url: '/healthynumber/hospital_ranking_commit',
      method: 'post',
      params,
    });
  }
}

export default new PopIns();

// 开屏图管理
import Request from '../utils/requestAxios';

class ApiPoster extends Request {
  /**
   * 获取开屏图列表
   * @param {*} params
   */
  getPosterList(params) {
    return this.fetch({
      url: '/config/splash_pic/list',
      method: 'get',
      params,
    });
  }

  /**
   * 新建开屏图
   * @param {*} params
   */
  addPoster(params) {
    return this.fetch({
      url: '/config/splash_pic/submit',
      method: 'post',
      params,
    });
  }

  /**
   * 获取开屏图信息
   * @param {*} params
   */
  getPosterInfo(params) {
    return this.fetch({
      url: '/config/splash_pic/detail',
      method: 'get',
      params,
    });
  }

  /**
   * 开屏图下线
   * @param {*} params
   */
  setPosterOffline(params) {
    return this.fetch({
      url: '/config/splash_pic/offline',
      method: 'post',
      params,
    });
  }

  /**
   * 主题配置
   * @param {*} params
   */
  getThemeSetting(params) {
    return this.fetch({
      url: '/config/theme/setting',
      method: 'get',
      params,
    });
  }

  /**
   * 主题配置
   * @param {*} params
   */
  submitThemeSetting(params) {
    return this.fetch({
      url: '/config/theme/submit',
      method: 'post',
      params,
    });
  }
}

export default new ApiPoster();

// 活动管理
import Request from '../utils/requestAxios';

class Activity extends Request {
  /**
   * 发布活动
   * @param {*} params
   */
  publishActvity(params) {
    return this.fetch({
      url: '/activity/publish',
      method: 'POST',
      params,
    });
  }

  /**
   * 更新活动
   * @param {*} params
   */
  updateActvity(params) {
    return this.fetch({
      url: '/activity/update',
      method: 'POST',
      params,
    });
  }

  /**
   * 更新活动
   * @param {*} params
   */
  checkActvity(params) {
    return this.fetch({
      url: '/activity/audit',
      method: 'POST',
      params,
    });
  }

  /**
   * 我参与的活动列表
   * @param {*} params
   */
  manageActvityList(params) {
    return this.fetch({
      url: '/activity/list_manage',
      method: 'GET',
      params,
    });
  }

  /**
   * 我参与的活动列表
   * @param {*} params
   */
  joinActvityList(params) {
    return this.fetch({
      url: '/activity/list_join',
      method: 'GET',
      params,
    });
  }

  /**
   * 我发布的活动列表
   * @param {*} params
   */
  publishActvityList(params) {
    return this.fetch({
      url: '/activity/list_publish',
      method: 'GET',
      params,
    });
  }

  /**
   * 获取公开的活动列表，C端访问
   * @param {*} params
   */
  getOpenList(params) {
    return this.fetch({
      url: '/activity/list_open',
      method: 'GET',
      params,
    });
  }

  /**
   * 我协助的活动列表
   * @param {*} params
   */
  helpedActvityList(params) {
    return this.fetch({
      url: '/activity/list_assist',
      method: 'GET',
      params,
    });
  }

  /**
   * 活动列表 status 0 全部 1 待审核  2审核通过 3 未通过
   * @param {*} params
   */
  ActvityList(params) {
    return this.fetch({
      url: '/activity/list_audit',
      method: 'GET',
      params,
    });
  }

  /**
   * 删除活动
   * @param {*} params
   */
  deleteActvity(params) {
    return this.fetch({
      url: '/activity/remove',
      method: 'POST',
      params,
    });
  }

  /**
   * 活动下线
   * @param {*} params
   * @id 活动id
   */
  offlineActvity(params) {
    return this.fetch({
      url: '/activity/offline',
      method: 'POST',
      params,
    });
  }

  /**
   * 获取活动分类
   * @param {*} params
   * @stage publish 发布阶段 finalist 入围阶段
   */
  getCategory(params) {
    return this.fetch({
      url: 'activity/category/list',
      method: 'GET',
      params,
    });
  }

  /**
   * 添加作品分类
   * @param {*} params
   * @stage finalist 入围阶段
   */
  addCategory(params) {
    return this.fetch({
      url: '/activity/category/add',
      method: 'POST',
      params,
    });
  }

  /**
   * 作品分类状态修改
   * @param {*} params
   * @id 分类id
   * @status 1 启用 2 停用
   * @name 分类名称
   * activity_id
   */
  updateTextCategory(params) {
    return this.fetch({
      url: '/activity/category/edit',
      method: 'POST',
      params,
    });
  }

  /**
   * 入围作品状态 修改 删除
   * @param {*} params
   * @id 分类id
   * @status 分类状态
   * @name 分类名称
   * activity_id
   */
  updateCategory(params) {
    return this.fetch({
      url: '/activity/category/edit_finalist',
      method: 'POST',
      params,
    });
  }

  /**
   * 活动审核人列表
   * @param {*} params
   * @activity_id 活动id
   */
  authList(params) {
    return this.fetch({
      url: '/activity/auditor/list',
      method: 'GET',
      params,
    });
  }

  /**
   * 添加活动审核人
   * @param {*} params
   * @contact_phone 手机号
   * @activity_id 活动id
   * @area_code 地区code
   */
  addAudith(params) {
    return this.fetch({
      url: '/activity/auditor/add',
      method: 'POST',
      params,
    });
  }

  /**
   * 删除活动审核人
   * @param {*} params
   * @user_id 用户id
   * @activity_id 活动id
   * @area_code 地区id array
   */
  deleteAudith(params) {
    return this.fetch({
      url: '/activity/auditor/remove',
      method: 'POST',
      params,
    });
  }

  /**
     * 获取活动详情
     * @param {*} params
     */
  getDetail(params) {
    return this.fetch({
      url: '/activity/detail',
      method: 'get',
      params,
    });
  }

  /**
     * C端获取活动详情
     * @param {*} params
     */
  getOpenDetail(params) {
    return this.fetch({
      url: '/activity/detail_open',
      method: 'get',
      params,
      closeErrorTip: true,
    });
  }
}

export default new Activity();

import { message } from 'antd';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import UserStore from '../store/user';
import { Url } from './url';
import Storage from './Storage';
import Token from './token';
import Conf from '../conf/app';

class Util extends Url {
  userPermissionObj = null; // 存放用户权限点

  userInfo = null;

  // 节流函数，间隔wait时间段执行
  throttle(fn, wait) {
    let timer;
    return (...args) => {
      if (timer) { return; }
      timer = setTimeout(() => {
        fn(...args);
        timer = null;
      }, wait);
    };
  }

  // 防抖函数，执行最后一次
  debounce(fn, wait) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, wait);
    };
  }

  // 防抖函数，执行一次，优先执行
  // eg: debouncePrev(handleSubmit, 1500); // 间隔1.5s，连续触发1.5s内只执行一次，切立即执行
  debouncePrev(fn, wait) {
    let timer;
    return (...args) => {
      if (timer) {
        return;
      }
      fn(...args);
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
      }, wait);
    };
  }

  // fn会返回promise，promise状态变更之后才可继续执行
  execPromise(fn) {
    let promise;
    let isExec = true; // 是否可执行
    return (...args) => {
      if (!isExec) {
        return;
      }
      if (!promise) {
        promise = fn(...args);
        if (Object.prototype.toString.call(promise) !== '[object Promise]') {
          // eslint-disable-next-line no-console
          console.error('此函数需要返回Promise！');
          return;
        }
        isExec = false;
      }
      promise.finally(() => {
        isExec = true;
        promise = null;
      });
    };
  }

  // 是否支持复制内容至剪贴板
  isCopy = !!document.execCommand

  // 判断对象是否为Promise对象
  isPromise(obj) {
    return Object.prototype.toString.call(obj) === '[object Promise]';
  }

  // 判断对象是否为Promise对象
  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  /**
   * 去登录
   * @param {*} param0 登录之后是否重定向之前退出页面
   * @param {*} param1 调用来源，1：右上角主动退出 2：接口未登录
   */
  goLogin({ isRedirect = true, source } = {}) {
    const params = {};
    const { hash = '', search = '', pathname = '' } = window.location;
    Storage.setSession('logoutSource', source);
    UserStore.removeUserInfo();
    Token.delToken();
    if (hash.includes('#/login') || pathname === '/login') { // 当前页面为登录页面时不需要跳转了
      return;
    }
    if (
      isRedirect
      && !(hash.includes('redirect') || search.includes('redirect')) // 避免二次重定向
    ) {
      params.redirect = encodeURIComponent(window.location.href);
    }
    const qsr = this.toQueryString(params);
    const href = `#/login${qsr ? '?' : ''}${qsr}`;
    window.location.href = href; // react hash模式
  }

  hasPermission(permissions = []) {
    if (!Array.isArray(permissions)) {
      return false;
    }
    const { userInfo } = UserStore;
    const userPermissions = userInfo.permissions || [];
    const userPermissionObj = {};
    userPermissions.forEach((key) => {
      userPermissionObj[key] = 1;
    });
    return permissions.some((key) => !!userPermissionObj[key]);
  }

  // 暂不支持Set、Map数据类型
  deepClone(target, cache = []) {
    if (typeof target !== 'object' || target === null) {
      return target;
    }
    const hit = cache.filter((_) => _.origin === target);
    if (hit.length > 0) {
      return hit[0].copy;
    }
    const newTarget = Array.isArray(target) ? [] : {};
    cache.push({
      origin: target,
      copy: newTarget,
    });
    Object.keys(target).forEach((key) => {
      newTarget[key] = this.deepClone(target[key], cache);
    });
    return newTarget;
  }

  // 一维数组专为二维数组
  arrTrans(arr, num = 2) { // 一维数组转换为二维数组
    const iconsArr = []; // 声明数组
    arr.forEach((item, index) => {
      const page = Math.floor(index / num); // 计算该元素为第几个素组内
      if (!iconsArr[page]) { // 判断是否存在
        iconsArr[page] = [];
      }
      iconsArr[page].push(item);
    });
    return iconsArr;
  }

  // 递归遍历树结构数据
  travelWidely(nodes = [], dealNodeCb) {
    if (!Array.isArray(nodes)) {
      return nodes;
    }
    const queue = [...nodes];
    while (queue.length) {
      let node = queue.shift();
      if (node) {
        const dealNode = dealNodeCb && dealNodeCb(node);
        node = dealNode || node;
      }
      if (node.children && node.children.length) {
        queue.push(...node.children);
      }
    }
  }

  // 文件下载
  downFile({
    store_key = '', // tos的storeKey
    file_name = '', // 文件名称
    path = '', // 接口路径
    query, // 请求查询参数
    vid = '', // 资源的vid (通过tt-uploader上传的文件有vid)
    file_url = '', // 文件的存储地址
  }) {
    path = path || '/upload/file_download';
    const qString = this.toQueryString({
      ...query,
      store_key,
      file_name,
      vid,
      token: Token.getToken(),
    });
    let downHref = file_url;
    // 如果有地址，直接下载  由于通过vod和tos的方式上传的附件会同时存在，所以当有地址时（tos）直接下载
    if (downHref) {
      this.downFileByAtag(downHref);
      return;
    }
    if (vid) {
      path = '/upload/file_download_by_vid';
      // this.downVideo(downHref, file_name);
      downHref = `${Conf.baseAPI}${path}?${qString}`;
    } else if (store_key) {
      downHref = `${Conf.baseAPI}${path}?${qString}`;
    }
    this.downFileByAtag(downHref);
  }

  downVideo(url, name) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer'; // 返回类型blob
    message.loading();
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const blob = this.response;
        // 转换一个blob链接
        const u = window.URL.createObjectURL(new Blob([blob], { type: 'video/mp4' }));
        this.downFileByAtag(u, name);
      }
    };
    setTimeout(() => {
      message.destroy();
    }, 2000);
    xhr.send();
  }

  // 通过a标签下载文件
  downFileByAtag(href, name) {
    const a = document.createElement('a'); // 创建a标签
    a.setAttribute('download', name || '');// download属性
    a.setAttribute('href', href);// href链接
    a.setAttribute('target', 'blank');// href链接
    a.click();// 自执行点击事件
  }

  copy(value, tip = '已成功复制至粘贴板') {
    // const scrollTop = window.pageYOffset;
    const transfer = document.createElement('input');
    document.body.appendChild(transfer);
    transfer.value = value; // 这里表示想要复制的内容
    transfer.focus();
    transfer.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      message.success(tip);
    } else {
      message.info(`请复制：${value}`, 4000);
    }
    transfer.blur();
    document.body.removeChild(transfer);
    // window.scrollTo(0, scrollTop);
  }

  getIEVersion() {
    const { userAgent } = navigator; // 取得浏览器的userAgent字符串
    const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; // 判断是否IE<11浏览器
    const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
    const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
    if (isIE) {
      const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
      reIE.test(userAgent);
      const fIEVersion = parseFloat(RegExp.$1);
      if (fIEVersion == 7) {
        return 7;
      } if (fIEVersion == 8) {
        return 8;
      } if (fIEVersion == 9) {
        return 9;
      } if (fIEVersion == 10) {
        return 10;
      }
      return 6;// IE版本<=7
    } if (isEdge) {
      return 20;// edge
    } if (isIE11) {
      return 11; // IE11
    }
    return 1000;// 不是ie浏览器
  }

  encrypt(vid = '', name = '') {
    let key = this.randomString(16);
    const data = `vid=${vid}&file_name=${name}`;
    const encryptor = new JSEncrypt();
    const pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDkWpAkhRRHEI4pFb4FiDSq3AH7Hvif2Br5BE7MOsGLrQOI3lIes7c+VdAnA88ZNRBRiK2SWFjSrYgvgjvm/+5w4uhS471Sh8lJYN2fBp35q7l0KmN0mmuVt6DTZllx7QcOiReAetIFZDv7VXbfriFuh4Qdd7KccZuIatkw+pLhAQIDAQAB';
    const aesData = this.encryptAES(data, key);
    encryptor.setPublicKey(pubKey);
    key = encryptor.encrypt(key);
    return {
      data: aesData,
      key,
    };
  }

  // 加密方法
  encryptAES(word, key) {
    key = this.paddingLeft(key, 16); // 保证key的长度为16byte,进行'0'补位
    key = CryptoJS.enc.Utf8.parse(key);
    // 加密结果返回的是CipherParams object类型
    // key 和 iv 使用同一个值
    const encrypted = CryptoJS.AES.encrypt(word, key, {
      iv: key,
      mode: CryptoJS.mode.CBC, // CBC算法
      padding: CryptoJS.pad.Pkcs7, // 使用pkcs7 进行padding 后端需要注意
    });
    // ciphertext是密文,toString()内传编码格式,比如Base64,这里用了16进制
    // 如果密文要放在 url的参数中 建议进行 base64-url-encoding 和 hex encoding, 不建议使用base64 encoding
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex); // 后端必须进行相反操作
  }

  paddingLeft(key, length) {
    let pkey = key.toString();
    const l = pkey.length;
    if (l < length) {
      pkey = new Array(length - l + 1).join('0') + pkey;
    } else if (l > length) {
      pkey = pkey.slice(length);
    }
    return pkey;
  }

  // 随机生成字符串
  randomString(e) {
    let n = '';
    if (typeof e !== 'number') {
      return n;
    }
    const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789'.split('');
    for (let i = 0; i < e; i++) {
      n += t[Math.floor(Math.random() * t.length)];
    }
    return n;
  }

  // 表单校验滚动
  validateFieldsAndScrollFn({ form, config, callBack }) {
    let scrollConfig = {
      alignWithTop: true,
      offsetTop: 200,
    };
    scrollConfig = config || scrollConfig;
    setTimeout(() => {
      const formKeys = Object.keys(form.getFieldsValue());
      form.validateFieldsAndScroll(formKeys, {
        scroll: scrollConfig,
      }, (err) => {
        callBack && callBack(err);
      });
    }, 300);
  }
}

export default new Util();

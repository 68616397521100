import { decorate, observable } from 'mobx';
import { apiCommon } from '../API';
import MenuBadgesStore from './menuBadges';

class User {
    userInfo = {};

    setUserInfo(data = {}) { // 设置用户信息
      this.userInfo = data;
      MenuBadgesStore.setMenuBadges();
    }

    removeUserInfo() {
      this.userInfo = {};
    }

    update(isRedirect = true) {
      return apiCommon.getLoginUserInfo({}, isRedirect).then(async (data) => {
        MenuBadgesStore.setMenuBadges();
        this.setUserInfo(data);
        return data;
      });
    }
}

decorate(User, {
  userInfo: observable,
});

export default new User();

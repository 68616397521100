import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { initApmPlus } from '@/utils/apm';
import url from './utils/url';
import env from './utils/env';
import 'normalize.css';
import './index.css';
import 'antd/dist/antd.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (env.isProd) {
  initApmPlus();
}

const locationPath = location.pathname;
const isRightHost = location.host === 'www.jkzg2030.cn' || location.host === 'test.jkzg2030.cn';

if (location.protocol !== 'https:') {
  location.replace(`https://${location.hostname}/${location.hash}`);
}

if (location.host === 'jkzg2030.cn') {
  location.replace(`https://www.jkzg2030.cn/${location.hash}`);
}

// 旧系统地址重定向 http://www.jkzg2030.cn/
function redirectOldRouter() {
  if (!isRightHost || location.hash) {
    return;
  }
  if (locationPath === '/jkzg/topicNewIndex.htm' || locationPath === '/jkzgpjxd/topicNewIndex.htm') { // 2021的活动
    location.hash = '/topic/2021';
  } else if (locationPath === '/jkzg/topicIndex.htm' || locationPath === '/jkzgpjxd/topicIndex.htm') { // 2020的活动
    location.hash = '/topic/2020';
  } else if (locationPath === '/jkzgcms/' || locationPath === '/jkzgcms') { // 后台
    location.hash = '/mis';
  }
}

// 为了兼容IOS的schema 所做的重定向，当接收到/WebView 路径时去解析 url 参数并跳转。
function redirectWebView() {
  try {
    if (locationPath === '/WebView') {
      const redirectUrl = url.getUrlParam('url');
      const redirectUrlObj = new URL(redirectUrl);
      const isWhiteHost = redirectUrlObj.host === 'www.jkzg2030.cn' || redirectUrlObj.host === 'test.jkzg2030.cn';
      if (redirectUrl && isWhiteHost) {
        location.replace(redirectUrl);
      }
    }
  } catch (error) {
    location.replace(`${location.origin}/`);
  }
}

if (isRightHost && locationPath.includes('/app-m')) { // 旧的移动端页面全部跳转
  const mLocationPath = locationPath.replace('/app-m', '');
  const searchStr = window.location.search;
  location.replace(`//m.jkzg2030.cn${mLocationPath}${searchStr}`);
} else if (isRightHost && locationPath === '/jkzg/home.htm') { // 旧的活动首页
  location.replace(`${location.origin}/#/activity/home`);
} else if (isRightHost && locationPath === '/jkzg/login.htm') { // 旧的登录
  location.replace(`${location.origin}/#/login`);
} else {
  redirectOldRouter();
  redirectWebView();
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

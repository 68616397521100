// 通用接口
import Request from '../utils/requestAxios';

class Common extends Request {
  /**
     * 登录
     * @param {*} params
     */
  getVerificationImg() {
    return this.fetch({
      url: '/captcha/refresh',
      method: 'post',
    });
  }

  getVerificationCode(params) {
    return this.fetch({
      url: '/login/generate_code',
      method: 'post',
      params,
    });
  }

  loginByCode(params) {
    return this.fetch({
      url: '/login/code',
      method: 'post',
      params,
    });
  }

  login(params) {
    return this.fetch({
      url: '/login/pwd',
      method: 'post',
      params,
    });
  }

  /**
     * 申诉
     * @param {*} params
     */
  complaint(params) {
    return this.fetch({
      url: '/complaint/submit',
      method: 'post',
      params,
    });
  }

  /**
   * 获取用户信息
   * @param {*} params
   * @param isRedirectLogin
   */
  getLoginUserInfo(params, isRedirectLogin = true) {
    return this.fetch({
      url: '/user/current',
      method: 'get',
      params,
      isRedirectLogin,
    });
  }

  /**
     * 修改自己的信息
     * @param {*} params
     */
  setUserEditOwn(params) {
    return this.fetch({
      url: '/user/edit_own',
      method: 'post',
      params,
    });
  }

  /**
     * 切换身份信息
     * @param {*} params
     */
  SwitchHealthNumber(params) {
    return this.fetch({
      url: '/identity/switch',
      method: 'post',
      params,
    });
  }

  /**
     * 获取视频token
     * @param {*} params
     */
  getVideoToken(params) {
    return this.fetch({
      url: '/info/video_get_token',
      method: 'post',
      params,
    });
  }

  getLocation(params) {
    return this.fetch({
      url: '/config/location',
      method: 'get',
      params,
    });
  }

  /**
   * 获取频道列表
   * @param {*} params
   */
  getCategoryList() {
    return this.fetch({
      url: '/config/category',
      method: 'get',
    });
  }

  /**
   * 获取科室列表
   * @param {*} params
   */
  getDoctorDepartment() {
    return this.fetch({
      url: '/config/doctor_department',
      method: 'get',
    });
  }

  /**
   * 获取职称列表
   * @param {*} params
   */
  getDoctorTitle() {
    return this.fetch({
      url: '/config/doctor_title',
      method: 'get',
    });
  }

  /**
   * 获取教学职称列表
   * @param {*} params
   */
  getDoctorTeachingTitle() {
    return this.fetch({
      url: '/config/doctor_teaching_title',
      method: 'get',
    });
  }

  /**
   * 获取教学职称列表
   * @param {*} params
   */
  getLotusHospitalsList(params) {
    return this.fetch({
      url: '/healthynumber/select_hospital_list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取上传token
   * @param {*} params
   */
  getUploadToken() {
    return this.fetch({
      url: '/upload/token',
      method: 'get',
    });
  }

  /**
   * 获取视频地址
   * @param {*} params
   */
  getVideoUrl(params) {
    return this.fetch({
      url: '/upload/get_video_url',
      method: 'get',
      params,
      closeErrorTip: true,
    });
  }

  /**
   * 获取视频海报集合
   * @param {*} params
   */
  getVideoPosterUrl(params) {
    return this.fetch({
      url: '/upload/get_cover',
      method: 'get',
      params,
      closeErrorTip: true,
    });
  }

  /**
   * 健康号名称验重
   * @param {*} params
   */
  getHealthyNumberNameUniqueCheck(params) {
    return this.fetch({
      url: '/healthynumber/name_unique_check',
      method: 'get',
      params,
    });
  }

  /**
   * 健康号机构验重
   * @param {*} params
   */
  getHealthyNumberOrgNameUniqueCheck(params) {
    return this.fetch({
      url: '/healthynumber/org_name_unique_check',
      method: 'get',
      params,
    });
  }

  /**
   * 健康号催审消息数
   * @param {*} params
   */
  getNeedAuditNum(params) {
    return this.fetch({
      url: '/healthynumber/need_audit_num',
      method: 'get',
      params,
      isRedirectLogin: false,
    });
  }
}

export default new Common();

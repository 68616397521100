import React, { useState, useEffect, Suspense } from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import {
  Menu,
  Icon,
  Layout,
  Breadcrumb,
  Dropdown,
  Avatar,
  Modal,
  Badge,
  // List,
  // Typography,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { RouteWithSubRoutes } from '../router';
import util from '../utils';
import { Logo } from '../static/images/index';
import CommonStore from '../store/common';
import MessageStore from '../store/message';
import UserStore from '../store/user';
import './index.scss';
import SwitchRole from './component/switchrole';
import FeedBack from '../component/FeedBack';
import default_avatar from '../static/images/default.png';
import {
  SysManage, InfoManage, HealthNumber, ActiveManage, AppealManage, TopicManage, ChannelManage,
} from './icon';
import { checkType } from '../page/healthNumber/data';
import { apiSystemManage } from '../API';
import MenuBadgesStore from '../store/menuBadges';
import GlobalNotify from '@/utils/globalNotify';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const ieVersion = util.getIEVersion();

function LayAdmin({ routes, location, history }) {
  const currPathname = location.pathname; // 当前页面路由
  const [menusList, setMenusList] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(currPathname); // 导航菜单的默认选中项
  const [showModal, setModalShow] = useState(false);
  const methods = {
    onCloseSwitch: (status) => {
      setModalShow(status);
    },
    initMenusList: () => {
      const menusListConf = methods.getMenusListConf();
      const newmenusListConf = util.deepClone(menusListConf);
      const provincialChannelList = toJS(UserStore.userInfo.provincial_channel_list) || [];
      const newList = newmenusListConf.filter((mItem) => {
        let isDis = false;
        if (mItem.key === 'lcoal_channel' && Array.isArray(provincialChannelList)) {
          provincialChannelList.map((item) => {
            mItem.children.push({
              name: `${item.location}频道`,
              pathname: `/mis/channel/setting/${item.id}`,
              permissions: ['prov_chan_local_manage', 'prov_chan_manage_edit', 'prov_chan_content_edit'],
              setectStatus: (currPath) => {
                const reg = /^\/mis\/channel\/add\/\d+\/\d+$/; // 地方频道管理/添加资讯
                if (reg.test(currPath)) {
                  const numArr = currPath.match(/\d+/g) || [];
                  const channelId = numArr[0];
                  return item.id == channelId;
                }
              },
            });
          });
        }
        if (util.hasPermission(mItem.permissions) || !mItem.permissions) {
          isDis = true;
        }
        if (Array.isArray(mItem.children)) { // 判断子菜单
          // eslint-disable-next-line max-len
          mItem.children = mItem.children.filter((item) => {
            if (item.pathname === '/mis/health/authentication' && UserStore.userInfo.health_number_confirm) {
              return false; // 认证完之后隐藏健康号认证
            }
            if (util.hasPermission(item.permissions)) {
              return true;
            }
            if (!item.permissions && isDis) {
              // 子菜单permissions不存在并且主菜单permissions有权限，则有权限
              return true;
            }
            if (!item.permissions && (!mItem.permissions || (Array.isArray(mItem.permissions) && mItem.permissions.length === 0))) {
              // 子菜单permissions不存在并且主菜单permissions不存在时，默认有权限
              return true;
            }
          }); // 配置了权限点(即指定权限)；无指定权限点时默认含有全部权限点
          isDis = mItem.children.length > 0;
        }
        return isDis;
      });
      setMenusList(newList);
    },
    // 判断侧边导航的选中状态
    exeSetectStatus: (menusItem, pathname) => {
      if (menusItem.pathname === pathname) {
        return true;
      }
      // 设置默认选中项
      if (typeof menusItem.setectStatus === 'function' && menusItem.setectStatus(pathname)) {
        return true;
      }
      if (Array.isArray(menusItem.setectStatus) && menusItem.setectStatus.includes(pathname)) {
        return true;
      }
      return false;
    },
    // 根据 currPathname 初始化要展开的菜单、默认选中的导航
    initOpenMenus: () => {
      let openIndex = -1; // 展开菜单索引
      let defaultIndex = -1; // 未精准匹配到菜单索引时的备用索引
      menusList.some((mItem, index) => {
        let childMenusIncludeCurrPath = false; // 子菜单中是否包含当路由
        if (mItem.children) { // 存在子导航
          // 子菜单中是否包含当前页面的路由
          childMenusIncludeCurrPath = mItem.children.some((childItem) => {
            if (methods.exeSetectStatus(childItem, currPathname)) {
              setSelectedKey(childItem.pathname);
              return true;
            }
            return false;
          });
          if (childMenusIncludeCurrPath) { // 当前mItem菜单下包含当前页面的路由
            openIndex = index;
          }
        } else if (methods.exeSetectStatus(mItem, currPathname)) {
          setSelectedKey(mItem.pathname);
          openIndex = index;
        }
        // 设置默认展开菜单
        if (typeof mItem.menuOpenStatus === 'function' && mItem.menuOpenStatus(currPathname)) {
          defaultIndex = index;
        }
        if (Array.isArray(mItem.menuOpenStatus) && mItem.menuOpenStatus.includes(currPathname)) {
          defaultIndex = index;
        }
        return childMenusIncludeCurrPath;
      });
      if (openIndex === -1) { // 未匹配到菜单取默认值
        openIndex = defaultIndex;
      }
      const keys = openIndex > -1 ? [openIndex.toString()] : openKeys;
      setOpenKeys(keys);
    },
    toCertModal: () => {
      // 弹框提示用户是否填写黄页信息
      if (UserStore.userInfo.is_pop_notice) {
        Modal.destroyAll();
        Modal.confirm({
          title: '完善信息提示',
          content: '健康中国健康号管理员，您好。为确保您的医院健康号公开信息在国家卫健委政务新媒体“健康中国APP”完整展示，并有机会参与平台更多活动，请您尽快完善医院健康号信息，避免影响健康号正常使用。',
          onOk: () => {
            try {
              apiSystemManage.modalCnt({
                name: 'login_notice_btn_fix',
              });
            } catch (error) { }
            if (UserStore.userInfo.hospital_state === 2) {
              // 有黄页但缺失字段
              history.push('/mis/health/myedithospinfo');
            } else {
              // 无黄页跳转
              history.push('/mis/health/authentication?step=1'); // step 为 1 直接跳入填写认证资料页面
            }
          },
          okText: '完善资料',
          onCancel: () => {
            try {
              // 发送请求
              apiSystemManage.modalCnt({
                name: 'login_notice_btn',
              });
            } catch (error) { }
          },
          cancelText: '我已知晓 ',
        });
      }
    },
    getHealthName: () => {
      const healthNumberName = (UserStore?.userInfo?.hospital_state === 1 || UserStore?.userInfo?.hospital_state === 2)
        ? (
          <>
            健康号
            <span className="menu_tag">待完善</span>
          </>
        ) : '健康号';
      return healthNumberName;
    },
    getMenusListConf: () => {
      const menusListConf = [
        {
          name: '首页',
          key: 'welcome',
          icon: SysManage,
          pathname: '/mis/welcome',
        },
        {
          name: '系统管理',
          key: 'system',
          permissions: [
            'system_manage',
          ],
          icon: SysManage,
          // iconUrl: imgHome,
          // iconUrlChoose: HomeChoose,
          children: [
            {
              name: '角色管理',
              pathname: '/mis/system/role',
              permissions: ['role_manage'],
              // setectStatus: (currPathname) => currPathname.includes('/mis/person'), // 返回true选中当前导航
              // setectStatus: ['/path1', '/path2'], // 含有数组中的导航时，选中当前导航
            },
            {
              name: '人员管理',
              pathname: '/mis/system/person',
              permissions: ['user_manage'],
              setectStatus: (currentPathname) => currentPathname.includes('/mis/person/detail'), // 返回true选中当前导航
            },
            {
              name: '操作日志',
              pathname: '/mis/system/log',
              permissions: ['system_log'], // ----------------权限设置------------------------------------------
              setectStatus: (currentPathname) => currentPathname.includes('/mis/person/log'), // 返回true选中当前导航
            },
            {
              name: '临时下线',
              pathname: '/mis/system/offline',
              permissions: ['application_switch_manage'], // ----------------权限设置------------------------------------------
              setectStatus: (currentPathname) => currentPathname.includes('/mis/system/offline'), // 返回true选中当前导航
            },
          ],
        },
        // health_number_manage 健康号管理列表 媒体号列表  平台管理员, 系统管理员
        // health_number_view 查看健康号                 健康号管理员
        // health_number_request // 健康号申请           游客
        {
          // eslint-disable-next-line react/jsx-one-expression-per-line
          name: methods.getHealthName(),
          key: 'health',
          permissions: [],
          // iconUrl: imgHome,
          // iconUrlChoose: HomeChoose,
          icon: HealthNumber,
          // icon: unCompleteIcon,
          children: [
            {
              name: '健康号认证',
              pathname: '/mis/health/authentication',
              permissions: [
                'health_number_request',
              ],
              setectStatus: ['/mis/health/mynumber'], // 返回true选中当前导航
            },
            {
              name: '我的健康号',
              pathname: '/mis/health/mynumber',
              permissions: [
                'health_number_manage', // 健康号管理
                'health_number_view', // 健康号查看
              ],
              setectStatus: ['/mis/health/myshowhospinfo'], // 返回true选中当前导航
            },
            {
              name: '健康号列表',
              pathname: '/mis/health/list',
              permissions: [
                'health_number_manage',
              ],
              setectStatus: (currentPathname) => (
                currentPathname.includes('/mis/health/list')
                || currentPathname.includes('/mis/health/healthdetail') // 健康号/健康号列表/编辑页面
                || currentPathname.includes('/mis/health/list/medialists')
                || currentPathname.includes('/mis/health/healthlist/detail')
              ),
            },
            {
              name: '健康号审核列表',
              pathname: '/mis/health/auditlist',
              permissions: [
                'health_number_manage',
              ],
              setectStatus: (currentPathname) => (
                currentPathname.includes('/mis/health/auditlist')
                || currentPathname.includes('/mis/health/auditlist/detail')
              ),
            },
            {
              name: '医院黄页审核列表',
              pathname: '/mis/health/hospauditlist',
              permissions: [
                'health_number_manage',
              ],
              setectStatus: (currentPathname) => (
                currentPathname.includes('/mis/health/hospauditlist')
                || currentPathname.includes('/mis/health/hospauditlist/detail')
              ),
            },
          ],
        },
        {
          name: '资讯管理',
          // pathname: '/mis/information',
          key: 'information',
          permissions: [
            'info_manage', // 资讯管理
            'info_audit', // 资讯审核
            'info_edit', // 资讯编辑
            'info_view', // 资讯查看
          ],
          icon: InfoManage,
          // iconUrl: imgHome,
          // iconUrlChoose: HomeChoose,
          children: [
            {
              name: '我的资讯',
              pathname: '/mis/information/myinfo',
              permissions: [
                'info_manage', // 资讯管理
                'info_audit', // 资讯审核
                'info_edit', // 资讯编辑
              ],
            },
            {
              name: '我的审核',
              pathname: '/mis/information/audit',
              permissions: [
                'info_manage', // 资讯管理
                'info_audit', // 资讯审核
              ],
            },
            {
              name: '资讯设置',
              pathname: '/mis/information/settings',
              permissions: [
                'info_manage', // 资讯管理
              ],
            },
            {
              name: '自助聚合',
              pathname: '/mis/information/mediaCapture',
              permissions: [
                'info_real_time_grab', // 自助聚合
              ],
            },
            {
              name: '实时聚合',
              pathname: '/mis/information/capture',
              permissions: [
                'info_real_time_grab', // 资讯管理
              ],
            },
            {
              name: '新建图文',
              pathname: '/mis/information/article',
            },
            {
              name: '新建视频',
              pathname: '/mis/information/video',
            },
          ],
        },
        {
          name: '直播管理',
          key: 'live',
          icon: SysManage,
          permissions: ['live_view'],
          children: [
            {
              name: '直播审核',
              permissions: ['live_manage'],
              pathname: '/mis/live/audit',
            },
            {
              name: '直播列表',
              pathname: '/mis/live/list',
            },
            // {
            //   name: '直播',
            //   pathname: '/mis/live/player',
            // },
            {
              name: '新建直播',
              pathname: '/mis/live/create',
            },
          ],
        },
        {
          name: '活动管理',
          // pathname: '/mis/information',
          key: 'action',
          permissions: [],
          menuOpenStatus: [],
          // iconUrl: imgHome,
          // iconUrlChoose: HomeChoose,
          icon: ActiveManage,
          children: [
            {
              name: '发布活动',
              pathname: '/mis/action/create',
              permissions: [
                'activity_publish',
              ],
              // setectStatus: (currentPathname) => {
              //     return /\/mis\/information\/(article|video)\//.test(currentPathname);
              // },
            },
            {
              name: '我的活动',
              pathname: '/mis/action/myaction',
              permissions: [
                'activity_join',
              ],
              setectStatus: (currentPathname) => (
                /\/mis\/check\/works/.test(currentPathname)
                || /\/mis\/action\/myaction\//.test(currentPathname) // 入围作品、绑定审核人员
              ),
            },
            {
              name: '待审核活动',
              pathname: '/mis/action/checkaction',
              permissions: [
                'activity_audit',
              ],
              setectStatus: (currentPathname) => /\/mis\/action\/checkaction\/detail/.test(currentPathname),
            },
            {
              name: '我的作品',
              pathname: '/mis/works/list',
              permissions: [
                'activity_join',
              ],
              setectStatus: (currentPathname) => /\/mis\/works\/detail/.test(currentPathname),
            },
            {
              name: '全部活动',
              pathname: '/activity/home',
              permissions: [
                'activity_join',
              ],
            },
            // {
            //   name: '活动-作品审核',
            //   pathname: '/mis/check/works',
            // },
          ],
        },
        {
          name: '用户管理',
          key: 'complaint',
          permissions: [
            'complaint_manage',
          ],
          icon: AppealManage,
          children: [
            {
              name: '用户反馈',
              pathname: '/mis/feedback/list',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/feedback/detail'),
            },
            {
              name: '账号申诉',
              pathname: '/mis/complaint/list',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/complaint/detail') || currentPathname.includes('/mis/complaint/handle'),
            },
          ],
        },
        {
          name: '专题管理',
          key: 'topic',
          permissions: ['topic_manage', 'topic_configure', 'topic_style_edit', 'topic_data_edit'],
          pathname: '/mis/topic/list',
          icon: TopicManage,
          setectStatus: (currentPathname) => (
            /^\/mis\/topic/.test(currentPathname) // 专题管理/编辑专题
          ),
        },
        {
          name: '产品配置',
          key: 'product',
          permissions: [
            'carousel_manage',
          ],
          icon: SysManage,
          children: [
            {
              name: '轮播图',
              pathname: '/mis/product/carousel/list',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/product/carousel/detail'),
            },
            {
              name: '专网腰封',
              pathname: '/mis/product/belt/list',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/product/belt/detail'),
            },
            {
              name: 'App开屏图',
              pathname: '/mis/product/appposter/list',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/product/appposter/detail'),
            },
            {
              name: '主题切换',
              pathname: '/mis/product/topic',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/product/topic'),
            },
            {
              name: '名片科普达人',
              pathname: '/mis/product/popdoctor',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/product/popdoctor'),
            },
            {
              name: '名片热门机构',
              pathname: '/mis/product/popins',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/product/popins'),
            },
            {
              name: '医者先行排行榜',
              pathname: '/mis/product/rankdoctor',
              setectStatus: (currentPathname) => currentPathname.includes('/mis/product/rankdoctor'),
            },
          ],
        },
        {
          name: '推送管理',
          key: 'push',
          permissions: [
            // 'info_manage', // 资讯管理
            'push_manage',
          ],
          icon: SysManage,
          children: [
            {
              name: 'APP推送列表',
              pathname: '/mis/product/push/list',
            },
            {
              name: '卒中推送列表',
              pathname: '/mis/push/stroke',
            },
          ],
        },
        {
          name: '地方频道管理',
          key: 'lcoal_channel',
          permissions: ['prov_chan_manage', 'prov_chan_local_manage', 'prov_chan_manage_edit', 'prov_chan_content_edit'],
          icon: ChannelManage,
          children: [
            {
              name: '地方频道配置',
              pathname: '/mis/channel/list',
              permissions: ['prov_chan_manage_edit'],
              setectStatus: (currentPathname) => currentPathname.includes('/mis/channel/detail') // 编辑/新建地方频道
                || currentPathname.includes('/mis/channel/preview'),
            },
          ],
        },
      ];
      return menusListConf;
    },
  };
  const domEvents = {
    logout: () => {
      util.goLogin({
        isRedirect: false,
        source: 'logout',
      });
    },
    toUserinfo() {
      history.push('/mis/user/userinfo');
    },
    toSwitch: () => {
      setModalShow(true);
    },
    toMessage: () => {
      MessageStore.setMessageCount();
      history.push('/mis/message');
    },
  };

  // 默认进入第一个页面
  const [firstMenusItem = []] = menusList;
  const [firstRoute = []] = firstMenusItem.children || [];
  const redirect = firstRoute.pathname || '';
  useEffect(() => {
    document.title = '健康中国管理平台';
    MessageStore.setMessageCount();
  }, []);
  useEffect(() => {
    if (!UserStore.userInfo || !UserStore.userInfo.id) { // 用户信息不存在时获取用户信息
      UserStore.update().then(() => {
        methods.initMenusList();
        methods.toCertModal();
      });
    } else {
      methods.initMenusList();
      methods.toCertModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserStore.userInfo]);
  useEffect(() => {
    GlobalNotify.initPushAuditHealthNumberTips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 找出默认展开的菜单、默认选中导航
  useEffect(() => {
    methods.initOpenMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPathname, menusList]);

  const userInfoMenu = (
    <Menu>
      <Menu.Item key="mobileOne" onClick={domEvents.toUserinfo}>
        <Icon type="user" />
        个人信息
      </Menu.Item>
      {UserStore.userInfo.identities && UserStore.userInfo.identities[0].health_number_id === 0 ? ('') : (
        <Menu.Item key="switch" onClick={domEvents.toSwitch}>
          <Icon type="swap" />
          切换身份
        </Menu.Item>
      )}
      <Menu.Item onClick={domEvents.logout} key="lg">
        <Icon type="logout" />
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="sider-bar-box">
      <Layout style={{ height: '100vh', minWidth: '1500px' }}>
        { // 切换身份
          showModal && <SwitchRole onClose={methods.onCloseSwitch} history={history} />
        }
        <Sider className="menuWrap">
          <div className="logo" />
          <div className="logo-box">
            <img className="logo" src={Logo} alt="" />
            {' '}
            <span style={{ color: '#ffffff' }}>健康中国管理平台</span>
          </div>
          <Menu
            style={{ width: '200px' }}
            selectedKeys={[selectedKey]}
            openKeys={openKeys}
            mode="inline"
            theme="dark"
            onOpenChange={(keys) => {
              setOpenKeys(keys);
            }}
          >
            {
              menusList.map((item, index) => (item.children ? (
                <SubMenu
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  title={(
                    <span>
                      <Icon component={item.icon} />
                      {' '}
                      {item.name}
                      {' '}
                      <Badge count={MenuBadgesStore.menuBadges?.[item.key]?.badges} overflowCount={9} style={{ padding: '0 4px', height: '14px', lineHeight: '14px' }} />
                    </span>
                  )}
                >
                  {
                    item.children.map((item0) => (
                      <Menu.Item
                        key={item0.pathname}
                        onClick={() => { setSelectedKey(item0.pathname); }}
                      >
                        {item0.name}
                        {' '}
                        <Badge count={MenuBadgesStore.menuBadges?.[item.key]?.children?.[item0.pathname]?.badges} overflowCount={9} style={{ padding: '0 4px', height: '14px', lineHeight: '14px' }} />
                        <Link to={item0.pathname}>{item0.name}</Link>
                      </Menu.Item>
                    ))
                  }
                </SubMenu>
              ) : (
                <Menu.Item
                  className="main-m-item"
                  key={item.pathname}
                  onClick={() => { setSelectedKey(item.pathname); }}
                >
                  <SettingOutlined />
                  {item.name}
                  <Link to={item.pathname}>{item.name}</Link>
                </Menu.Item>
              )
              ))
            }
          </Menu>
        </Sider>
        <Layout>
          {
            ieVersion < 10 && <div className="ie-tip">为保证您的使用体验，请使用谷歌、火狐、搜狗、360浏览器。</div>
          }
          <Header style={{ background: '#fff', padding: 0 }}>
            <div className="fr" style={{ textAlign: 'right', marginRight: '20px', cursor: 'pointer' }}>
              <Dropdown
                overlay={userInfoMenu}
              >
                <div>
                  <Avatar
                    style={{ width: 42, height: 42, marginRight: 5 }}
                    src={UserStore.userInfo.avatar_url ? UserStore.userInfo.avatar_url : default_avatar}
                  />
                  <div className="info" style={{}}>
                    <span style={{ cursor: 'pointer', color: '#333' }}>
                      {UserStore.userInfo.nick_name}
                    </span>
                    <span>
                      {UserStore.userInfo.role_name}
                    </span>
                    {/* 非个人角色下，当健康号处于不可用状态 审核中、 审核失败、 临时下线 时显示 */}
                    {UserStore.userInfo.role_id !== 1
                      && UserStore.userInfo.health_number_status
                      && (
                        UserStore.userInfo.health_number_status === 2
                        || UserStore.userInfo.health_number_status === 3
                        || UserStore.userInfo.health_number_status === 5)
                      && (
                        <span style={{ color: 'red' }}>
                          {`当前健康号处于‘${checkType[UserStore.userInfo.health_number_status]}’状态`}
                        </span>
                      )}
                  </div>
                </div>
              </Dropdown>
            </div>
            <div style={{
              overflow: 'hidden', textAlign: 'right', paddingRight: '30px', position: 'relative',
            }}
            >
              {!!MessageStore.messageCount && (
                <div style={{
                  display: 'inline-block', textAlign: 'center', backgroundColor: 'red', color: 'white', width: 25, height: 25, lineHeight: '25px', position: 'absolute', borderRadius: '50%', zIndex: 2, top: 4, right: 14,
                }}
                >
                  {MessageStore.messageCount}
                </div>
              )}
              <Icon
                style={{
                  fontSize: '30px', position: 'relative', top: '6px', cursor: 'pointer',
                }}
                onClick={domEvents.toMessage}
                type="mail"
              />
            </div>
          </Header>
          <Content style={{ margin: '0 16px', marginBottom: '20px' }}>
            <Breadcrumb style={{ margin: '20px 0', textAlign: 'left' }}>
              {
                // eslint-disable-next-line max-len
                // eslint-disable-next-line react/no-array-index-key
                CommonStore.crumbsData && CommonStore.crumbsData.map((item, index) => <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>)
              }
            </Breadcrumb>
            <div className="person-info" style={history.location.pathname === '/mis/user/userinfo' ? { display: 'block' } : { display: 'none' }}>个人资料</div>
            {/* 首页增加倡议书签订 */}
            {/* { history.location.pathname === '/mis/welcome' && (
              <List style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                <List.Item style={{ padding: '20px 40px' }}>
                  <Typography.Text style={{
                    lineHeight: '22px',
                    fontSize: '16px',
                  }}
                  >
                    点击签订“健康中国 医者先行”倡议书！
                    <a style={{ marginLeft: '60px' }} href="/#/activity/doctorAgreement">
                      去签订&nbsp;
                      <Icon type="right" style={{ fontSize: '12px', verticalAlign: 'baseline' }} />
                    </a>
                  </Typography.Text>
                </List.Item>
              </List>
            )} */}
            <div className="content-card">
              <Suspense fallback={<Icon type="loading" />}>
                <Switch>
                  {
                    routes.map((route, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <RouteWithSubRoutes
                        menusList={menusList}
                        setOpenKeys={setOpenKeys}
                        setSelectedKey={setSelectedKey}
                        key={i}
                        {...route}
                        isChildRoute
                      />
                    ))
                  }
                  {
                    redirect && <Redirect to={redirect} />
                  }
                </Switch>
              </Suspense>
            </div>
          </Content>
        </Layout>
        {/* 用户反馈悬浮 */}
        <FeedBack />
      </Layout>
    </div>
  );
}

export default observer(LayAdmin);

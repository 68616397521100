import { decorate, observable } from 'mobx';

import apiMessage from '../API/apiMessage';

class Message {
  messageCount = 0;

  setMessageCount() {
    apiMessage.getUnreadCount().then((data) => {
      const { count } = data;
      this.messageCount = count;
    });
  }
}

decorate(Message, {
  messageCount: observable,
});

export default new Message();

/*
资讯预览详情页
*/
import React, { PureComponent } from 'react';
import {
  Icon, message,
// Icon,
} from 'antd';
import Player from 'xgplayer';
// import moment from 'moment';
import { apiInformation, apiUpload } from '../../../API';
import util from '../../../utils';
import styles from './index.module.scss';
import './index.scss';
import { templateIdMap } from '../../../utils/vol';

class InfoDeail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ifShowTransWarn: false,
      detailInfo: {},
    };
    this.videoContainRef = React.createRef();
    this.videoPlayer = null;
    this.methods = this.getMethods();
    this.domEvents = this.getDomEvents();
  }

  componentDidMount() {
    const { location } = this.props;
    const { search } = location || {};
    const searchParams = util.getUrlParams(search);
    const { token = '' } = searchParams || {}; // aid 活动id

    this.setState({
      token,
    }, () => {
      this.methods.getDetail();
    });
  }

  componentWillUnmount() {
    this.videoPlayer && this.videoPlayer.destroy();
  }

  getMethods() {
    return {
      // 资讯详情
      getDetail: () => {
        const { videoContainRef } = this;
        const { token } = this.state;
        apiInformation.getPreviewDetail({ token }).then((data) => {
          const {
            type, video_poster, video_url, video_id,
          } = data;
          if (type === 2 && video_url) {
            // eslint-disable-next-line no-new
            this.methods.getTransStatus(video_id);
            this.videoPlayer = new Player({
              el: videoContainRef.current,
              poster: video_poster,
              url: video_url,
              width: '736px',
              height: '414px',
              autoplay: true,
            });
          }
          this.setState({
            detailInfo: data,
          });
        });
      },
      getTransStatus: (vid) => {
        apiUpload.getTransStatus({
          vid,
          template_id: templateIdMap.video,
        }).then((data) => {
          const { status } = data;
          if (status !== 2) {
            this.setState({
              ifShowTransWarn: true,
            });
          }
        });
      },
    };
  }

  getDomEvents() {
    return {
      downloadFile: ({
        store_key, vid, name, url,
      }) => {
        if (!store_key && !vid && !url) {
          message.warning('资源不存在');
          return;
        }
        const { data, key } = util.encrypt(vid, name);
        util.downFile({
          store_key, vid, file_name: name, file_url: url, query: { data, key },
        });
      },
    };
  }

  render() {
    const { videoContainRef, domEvents } = this;
    const { detailInfo = {}, ifShowTransWarn = false } = this.state;
    const {
      title, author, content, type, file_info, abstract, enclosures = [], tos_enclosures = [],
    } = detailInfo;
    // 整合新旧附件和推荐信
    let showEnclosures = [];
    if (tos_enclosures && Array.isArray(tos_enclosures)) {
      showEnclosures = [...tos_enclosures];
    } else if (enclosures && Array.isArray(enclosures)) {
      showEnclosures = [...enclosures];
    }
    return (
      <div className={`${styles.wrap} info-detail-page`}>
        <div className={styles.tWrap}>
          <div className={styles.title}>{title}</div>
        </div>
        <div>
          <div className={styles.authName}>{author}</div>
        </div>
        <div className={styles.content}>
          <div ref={videoContainRef} />
          {content && <div className={styles.contentTxt} dangerouslySetInnerHTML={{ __html: content }} />}
          {abstract && <div className={styles.contentTxt} dangerouslySetInnerHTML={{ __html: abstract }} />}
          {
            type === 'audio' && (
              <audio
                controls
                src={file_info.url}
              >
                <track kind="captions" />
              </audio>
            )
          }
          {ifShowTransWarn && <div className={styles.warnStatus}>提示：正在转码中，如无法播放，请耐心等待…</div>}
        </div>
        <div className={styles.fileBox}>
          {
            Array.isArray(showEnclosures) && showEnclosures.map((item, index) => (
              <div className={`${styles.fileLi} clearfix`} key={index} onClick={() => domEvents.downloadFile(item)}>
                <span className="fl"><Icon type="file-text" /></span>
                <div className={`${styles.fileName} fl`}>{item.name}</div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default InfoDeail;

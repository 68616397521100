import axios from 'axios';
// import qs from "qs"
import {
  message,
} from 'antd';
import uitl from './util';
import Conf from '../conf/app';
import Token from './token';
import { initAxiosInterceptorsConfig } from './interceptors';

initAxiosInterceptorsConfig();
export class BaseRequest {
baseURL = Conf.baseAPI;

preFetch(config = {}) {
  return config;
}

setToken(config = {}) { // 设置token
  const token = Token.getToken();
  if (token) {
    config.headers[Token.reqKey] = token;
  }
  return config;
}

async fetch({
  url,
  method,
  params,
  closeErrorTip = false, // 是否关闭错误提醒
  headers,
  isRedirectLogin = true, // 未登录状态下是否重定向到登陆页
}) {
  method = method.toUpperCase();
  if (params && (method === 'GET' || method === 'DELETE')) {
    Object.keys(params).forEach((key) => {
      const item = params[key];
      if (item === null || item === undefined || item === '') {
        delete params[key];
      }
    });
  }
  const httpDefaultConfig = {
    method,
    baseURL: this.baseURL,
    url,
    params: method === 'GET' || method === 'DELETE' ? params : null,
    data: method === 'POST' || method === 'PUT' ? params : null,
    timeout: 60000,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Biz-Source': 'cms', // 业务线
      'Utm-Source': 'pc', // 渠道
      ...headers,
    },
  };
  const preConfig = this.preFetch(httpDefaultConfig); // 请求参数预处理
  const config = this.setToken(preConfig);
  const res = await axios(config).catch((error) => ({ error }));
  if (res.error) {
    if (!closeErrorTip) {
      message.error('系统繁忙，请稍后再试');
    }
    return Promise.reject(res.error);
  }
  if (res.headers[Token.resNewKey]) {
    Token.setToken(res.headers[Token.resNewKey]);
  }
  if (res.status === 200 && res.data) {
    if (res.data.code === 100005 || res.data.code === 400000) { // 维护更新页面
      window.location.href = `${window.location.origin}/#/update`;
      return Promise.reject(res.data);
    }
    if (res.data.code === 100010) {
      isRedirectLogin && uitl.goLogin({ source: 'api' });
      return Promise.reject(res.data);
    }
    if (res.data.code === 102019 || res.data.code === 102020) { // 跳过错误提醒
      return Promise.reject(res.data);
    }
    if (res.data.code === 0) {
      return res.data.data;
    }
    if (!closeErrorTip) {
      message.error(res.data.msg || '网络错误');
    }
    return Promise.reject(res.data);
  }
  if (!closeErrorTip) {
    message.error('网络错误');
  }
  return Promise.reject(res.data);
}
}

export default BaseRequest;

import Request from '../utils/requestAxios';

class Message extends Request {
  /**
   * 获取未读总数
   */
  getUnreadCount() {
    return this.fetch({
      url: '/mail/unread_count',
      method: 'GET',
    });
  }

  /**
   * 获取未读总数
   */
  getMessageList(params) {
    return this.fetch({
      url: '/mail/my_list',
      method: 'GET',
      params,
    });
  }

  /**
   * 改变站内信状态
   */
  changeMessageStatus(params) {
    return this.fetch({
      url: '/mail/status',
      method: 'POST',
      params,
    });
  }
}

export default new Message();

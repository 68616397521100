// 作品管理
import Request from '../utils/requestAxios';

class ActivityProduct extends Request {
  /**
     * 预览作品
     * @param {*} params
     */
  previewProduct(params) {
    return this.fetch({
      url: '/activity_product/generate_preview',
      method: 'post',
      params,
    });
  }

  /**
     * 创建作品
     * @param {*} params
     */
  createProduct(params) {
    return this.fetch({
      url: '/activity_product/create',
      method: 'post',
      params,
    });
  }

  /**
     * 更新作品
     * @param {*} params
     */
  updateProduct(params) {
    return this.fetch({
      url: '/activity_product/update',
      method: 'post',
      params,
    });
  }

  /**
     * 提交审核
     * @param {*} params
     */
  commitProduct(params) {
    return this.fetch({
      url: '/activity_product/commit_audit',
      method: 'post',
      params,
    });
  }

  /**
     * 获取详情作品
     * @param {*} params
     */
  getDetail(params) {
    return this.fetch({
      url: '/activity_product/info',
      method: 'get',
      params,
    });
  }

  /**
     * 获取详情作品 c端展示
     * @param {*} params
     */
  getDisplayInfo(params) {
    return this.fetch({
      url: '/activity_product/display_info',
      method: 'get',
      params,
    });
  }

  /**
     * 获取作品列表C端展示的
     * @param {*} params
     */
  getDisplayList(params) {
    return this.fetch({
      url: '/activity_product/display_list',
      method: 'get',
      params,
    });
  }

  /**
     * 获取作品列表
     * @param {*} params
     */
  getList(params) {
    return this.fetch({
      url: '/activity_product/list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取作品列表
   * @param {*} params
   */
  getViewList(params) {
    return this.fetch({
      url: '/activity_product/auth_view_list',
      method: 'get',
      params,
    });
  }

  /**
     * 获取删除作品
     * @param {*} params
     */
  deleteProduct(params) {
    return this.fetch({
      url: '/activity_product/delete',
      method: 'post',
      params,
    });
  }

  /**
     * 下线作品
     * @param {*} params
     */
  offlineProduct(params) {
    return this.fetch({
      url: '/activity_product/set_offline',
      method: 'post',
      params,
    });
  }

  /**
     * 审核
     * @param {*} params
     */
  review(params) {
    return this.fetch({
      url: '/activity_product/review',
      method: 'post',
      params,
    });
  }

  /**
     * 调整分类
     * @param {*} params
     */
  setCategory(params) {
    return this.fetch({
      url: '/activity_product/set_category',
      method: 'post',
      params,
    });
  }

  /**
     * 调整地理位置
     * @param {*} params
     */
  setLocation(params) {
    return this.fetch({
      url: '/activity_product/set_location',
      method: 'post',
      params,
    });
  }

  /**
     * 设置获奖描述
     * @param {*} params
     */
  setReward(params) {
    return this.fetch({
      url: '/activity_product/set_reward',
      method: 'post',
      params,
    });
  }

  /**
   * 活动作品是否转资讯
   * @param {*} params
   * @param { number } id // 作品ID
   */
  getConventToArticleStatus(params) {
    return this.fetch({
      url: '/activity_product/convert_status',
      method: 'get',
      params,
    });
  }

  /**
   * 作品转资讯
   * @param {*} params
   * @param { number } id // 作品ID
   */
  conventToArticle(params) {
    return this.fetch({
      url: '/activity_product/convert',
      method: 'post',
      params,
    });
  }
}

export default new ActivityProduct();

import React from 'react';
import { Result } from 'antd';
import { SettingTwoTone } from '@ant-design/icons';

const UpdatePage = () => (
  <Result
    status="success"
    icon={<SettingTwoTone />}
    title="系统升级维护中，请稍后访问…"
  />
);

export default UpdatePage;

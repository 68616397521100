// 地方频道模块
import Request from '../utils/requestAxios';

class ApiChannel extends Request {
  /**
   * 获取地方频道列表
   * @param {*} params
   */
  getChannelManageList(params) {
    return this.fetch({
      url: '/provincial_channel/manage_list',
      method: 'get',
      params,
    });
  }

  /**
   * 新增频道
   * @param {*} params
   */
  addChannel(params) {
    return this.fetch({
      url: '/provincial_channel/add',
      method: 'POST',
      params,
    });
  }

  /**
   * 编辑频道
   * @param {*} params
   */
  editChannel(params) {
    return this.fetch({
      url: '/provincial_channel/edit',
      method: 'POST',
      params,
    });
  }

    /**
   * 管理员编辑频道
   * @param {*} params
   */
  editChannelManage(params) {
    return this.fetch({
      url: '/provincial_channel/manage_edit',
      method: 'POST',
      params,
    });
  }

  /**
   * 频道列表重新排序
   * @param {*} params
   */
  reorderChannel(params) {
    return this.fetch({
      url: '/provincial_channel/reorder',
      method: 'POST',
      params,
    });
  }

  /**
   * 获取频道信息
   * @param {*} params
   */
  getChannelInfo(params) {
    return this.fetch({
      url: '/provincial_channel/info',
      method: 'get',
      params,
    });
  }

  /**
   * 频道上下线
   * @param {*} params
   */
  setChannelOnline(params) {
    return this.fetch({
      url: '/provincial_channel/set_online',
      method: 'POST',
      params,
    });
  }

  /**
   * 获取频道栏目列表
   * @param {*} params
   */
  getColumnList(params) {
    return this.fetch({
      url: '/provincial_channel/column_list',
      method: 'get',
      params,
    });
  }

  /**
   * 频道栏目已添加资讯列表
   * @param {*} params
   */
  getArticleList(params) {
    return this.fetch({
      url: '/provincial_channel/column_article_list',
      method: 'get',
      params,
    });
  }

  /**
   * 筛选资讯列表
   * @param {*} params
   */
  getArticleSource(params) {
    return this.fetch({
      url: '/provincial_channel/article_select_list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取频道列表
   * @param {*} params
   */
  getCategoryList() {
    return this.fetch({
      url: '/config/category',
      method: 'get',
    });
  }

  /**
   * 添加资讯
   * @param {*} params
   */
  addArticles(params) {
    return this.fetch({
      url: '/provincial_channel/column_article_add',
      method: 'POST',
      params,
      closeErrorTip: true,
    });
  }

  /**
   * 移除资讯
   * @param {*} params
   */
  removeArticle(params) {
    return this.fetch({
      url: '/provincial_channel/column_article_delete',
      method: 'POST',
      params,
      closeErrorTip: true,
    });
  }

  /**
   * 获取轮播图列表
   * @param {*} params
   */
  getCarouselList(params) {
    return this.fetch({
      url: '/provincial_channel_carousel/list',
      method: 'get',
      params,
    });
  }

  /**
   * 新增轮播图
   * @param {*} params
   */
  addCarousel(params) {
    return this.fetch({
      url: '/provincial_channel_carousel/add',
      method: 'POST',
      params,
    });
  }

  /**
   * 调整轮播图顺序
   * @param {*} params
   */
  reorderCarousel(params) {
    return this.fetch({
      url: '/provincial_channel_carousel/reorder',
      method: 'POST',
      params,
    });
  }

  /**
   * 编辑录播图
   * @param {*} params
   */
  editCarousel(params) {
    return this.fetch({
      url: '/provincial_channel_carousel/edit',
      method: 'POST',
      params,
    });
  }

  
  /**
   * 编辑录播图
   * @param {*} params
   */
  getCityData(params) {
    return this.fetch({
      url: '/provincial_channel/location_list',
      method: 'GET',
      params,
    });
  }
}

export default new ApiChannel();

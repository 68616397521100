/* eslint-disable */
export const SysManage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path opacity="0.6" d="M8 15.7C7.6 15.7 7.1 15.6 6.7 15.4L2.2 12.8C1.5 12.3 1 11.5 1 10.6V5.40005C1 4.50005 1.5 3.70005 2.3 3.20005L6.8 0.600049C7.6 0.200049 8.5 0.200049 9.3 0.600049L13.8 3.20005C14.5 3.70005 15 4.50005 15 5.40005V10.6C15 11.5 14.5 12.3 13.7 12.8L9.2 15.4C8.9 15.6 8.4 15.7 8 15.7ZM8 1.30005C7.7 1.30005 7.5 1.40005 7.2 1.50005L2.8 4.10005C2.3 4.40005 2 4.90005 2 5.40005V10.6C2 11.1 2.3 11.6 2.8 11.9L7.3 14.5C7.8 14.8 8.3 14.8 8.8 14.5L13.3 11.9C13.8 11.6 14.1 11.1 14.1 10.6V5.40005C14.1 4.90005 13.8 4.40005 13.3 4.10005L8.8 1.50005C8.5 1.40005 8.3 1.30005 8 1.30005ZM8 11C6.3 11 5 9.70005 5 8.00005C5 6.30005 6.3 5.00005 8 5.00005C9.7 5.00005 11 6.30005 11 8.00005C11 9.70005 9.7 11 8 11ZM8 6.00005C6.9 6.00005 6 6.90005 6 8.00005C6 9.10005 6.9 10 8 10C9.1 10 10 9.10005 10 8.00005C10 6.90005 9.1 6.00005 8 6.00005Z" fill="white" stroke="#F8F9FB" strokeWidth="0.2" />
  </svg>
);

export const InfoManage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
    <g opacity="0.6">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="17">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H15V16.667H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3335 14.3337C13.3335 14.667 13.0826 15.0004 12.6659 15.0004H2.33328C1.91661 15.0004 1.66568 14.667 1.66568 14.3337V2.33345C1.66568 2.00011 1.91661 1.66678 2.33328 1.66678H8.33339V5.16684C8.33339 6.00019 8.99915 6.66687 9.83342 6.66687H13.3335V14.3337ZM9.99955 2.74996L12.2505 5H9.99955V2.74996ZM9.66685 0H2.33338C1.00002 0 0 1.08335 0 2.33338V14.3336C0 15.5836 1.00002 16.667 2.33338 16.667H12.666C13.9169 16.667 15.0003 15.5836 15.0003 14.3336V5.41677L9.66685 0Z" fill="white" />
        <path d="M8.33339 1.66678H8.58339V1.41678H8.33339V1.66678ZM13.3335 6.66687H13.5835V6.41687H13.3335V6.66687ZM9.99955 2.74996L10.1763 2.57315L9.74955 2.14658V2.74996H9.99955ZM12.2505 5V5.25H12.8542L12.4273 4.82319L12.2505 5ZM9.99955 5H9.74955V5.25H9.99955V5ZM9.66685 0L9.84499 -0.175401L9.77154 -0.25H9.66685V0ZM15.0003 5.41677H15.2503V5.31435L15.1784 5.24137L15.0003 5.41677ZM13.0835 14.3337C13.0835 14.5494 12.9249 14.7504 12.6659 14.7504V15.2504C13.2402 15.2504 13.5835 14.7847 13.5835 14.3337H13.0835ZM12.6659 14.7504H2.33328V15.2504H12.6659V14.7504ZM2.33328 14.7504C2.07426 14.7504 1.91568 14.5494 1.91568 14.3337H1.41568C1.41568 14.7847 1.75896 15.2504 2.33328 15.2504V14.7504ZM1.91568 14.3337V2.33345H1.41568V14.3337H1.91568ZM1.91568 2.33345C1.91568 2.11776 2.07426 1.91678 2.33328 1.91678V1.41678C1.75896 1.41678 1.41568 1.88247 1.41568 2.33345H1.91568ZM2.33328 1.91678H8.33339V1.41678H2.33328V1.91678ZM8.08339 1.66678V5.16684H8.58339V1.66678H8.08339ZM8.08339 5.16684C8.08339 6.13816 8.86097 6.91687 9.83342 6.91687V6.41687C9.13732 6.41687 8.58339 5.86222 8.58339 5.16684H8.08339ZM9.83342 6.91687H13.3335V6.41687H9.83342V6.91687ZM13.0835 6.66687V14.3337H13.5835V6.66687H13.0835ZM9.82281 2.92677L12.0738 5.17681L12.4273 4.82319L10.1763 2.57315L9.82281 2.92677ZM12.2505 4.75H9.99955V5.25H12.2505V4.75ZM10.2496 5V2.74996H9.74955V5H10.2496ZM9.66685 -0.25H2.33338V0.25H9.66685V-0.25ZM2.33338 -0.25C0.856164 -0.25 -0.25 0.951137 -0.25 2.33338H0.25C0.25 1.21557 1.14387 0.25 2.33338 0.25V-0.25ZM-0.25 2.33338V14.3336H0.25V2.33338H-0.25ZM-0.25 14.3336C-0.25 15.7158 0.856164 16.917 2.33338 16.917V16.417C1.14387 16.417 0.25 15.4514 0.25 14.3336H-0.25ZM2.33338 16.917H12.666V16.417H2.33338V16.917ZM12.666 16.917C14.0549 16.917 15.2503 15.7218 15.2503 14.3336H14.7503C14.7503 15.4455 13.7789 16.417 12.666 16.417V16.917ZM15.2503 14.3336V5.41677H14.7503V14.3336H15.2503ZM15.1784 5.24137L9.84499 -0.175401L9.4887 0.175401L14.8221 5.59217L15.1784 5.24137Z" fill="#021529" />
      </g>
    </g>
    <rect opacity="0.6" x="2.8125" y="6.6875" width="3.75" height="0.9375" rx="0.46875" fill="white" />
    <rect opacity="0.6" x="3" y="10" width="8.5" height="1.1" rx="0.55" fill="white" />
  </svg>
);

export const HealthNumber = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <path d="M0.6 2C0.6 1.2268 1.2268 0.6 2 0.6H13C13.7732 0.6 14.4 1.2268 14.4 2V10C14.4 10.7732 13.7732 11.4 13 11.4H4.25C3.82323 11.4 3.40303 11.5051 3.02647 11.7059L0.6 13V2Z" stroke="#9AA1A9" strokeWidth="1.2" />
    <path d="M10 6C10 7.10457 8.88071 8 7.5 8C6.11929 8 5 7.10457 5 6" stroke="#9AA1A9" strokeWidth="1.2" />
  </svg>
);

export const ActiveManage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
    <rect x="1.19922" y="15" width="1.2" height="15" rx="0.6" transform="rotate(-180 1.19922 15)" fill="#9AA1A9" />
    <mask id="path-2-inside-1" fill="white">
      <rect x="8.19922" y="3" width="6" height="8" rx="1" />
    </mask>
    <rect x="8.19922" y="3" width="6" height="8" rx="1" stroke="#9AA1A9" strokeWidth="2.4" mask="url(#path-2-inside-1)" />
    <mask id="path-3-inside-2" fill="white">
      <rect x="0.199219" width="11" height="9" rx="1" />
    </mask>
    <rect x="0.199219" width="11" height="9" rx="1" fill="#021529" stroke="#9AA1A9" strokeWidth="2.4" mask="url(#path-3-inside-2)" />
  </svg>
);

export const AppealManage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M1.6 2C1.6 1.2268 2.2268 0.6 3 0.6H11C11.7732 0.6 12.4 1.2268 12.4 2V12.4H1.6V2Z" stroke="#9AA1A9" strokeWidth="1.2" />
    <path d="M0.6 7.32573C0.6 7.05998 0.854362 6.86811 1.10989 6.94112L6.56045 8.49842C6.84773 8.5805 7.15227 8.5805 7.43955 8.49842L12.8901 6.94112C13.1456 6.86811 13.4 7.05998 13.4 7.32573V13C13.4 13.2209 13.2209 13.4 13 13.4H1C0.779086 13.4 0.6 13.2209 0.6 13V7.32573Z" fill="#021529" stroke="#9AA1A9" strokeWidth="1.2" />
    <rect x="4" y="2.5" width="4" height="1.2" rx="0.6" fill="#9AA1A9" />
    <rect x="4" y="4.80005" width="6" height="1.2" rx="0.6" fill="#9AA1A9" />
  </svg>
);

export const TopicManage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <rect x="0.6" y="1.6" width="12.8" height="11.8" rx="1.4" stroke="#9AA1A9" strokeWidth="1.2" />
    <rect x="4.5" y="0.5" width="5" height="2" rx="1" fill="#021529" stroke="#9AA1A9" />
    <path d="M6.82065 5.3634C6.89402 5.21475 7.10598 5.21475 7.17935 5.3634L7.83514 6.69219C7.86428 6.75121 7.92059 6.79213 7.98573 6.80159L9.45214 7.01468C9.61618 7.03851 9.68168 7.24011 9.56298 7.35581L8.50188 8.39013C8.45474 8.43608 8.43323 8.50228 8.44436 8.56716L8.69485 10.0276C8.72287 10.191 8.55139 10.3156 8.40466 10.2385L7.09307 9.54893C7.0348 9.5183 6.9652 9.5183 6.90693 9.54893L5.59534 10.2385C5.44861 10.3156 5.27713 10.191 5.30515 10.0276L5.55564 8.56716C5.56677 8.50228 5.54526 8.43608 5.49812 8.39013L4.43702 7.35581C4.31832 7.24011 4.38382 7.03851 4.54786 7.01468L6.01427 6.80159C6.07941 6.79213 6.13572 6.75121 6.16486 6.69219L6.82065 5.3634Z" stroke="#9AA1A9" />
  </svg>
);

export const ChannelManage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <mask id="path-1-inside-1" fill="white">
      <rect width="6" height="6" rx="1" />
    </mask>
    <rect width="6" height="6" rx="1" stroke="#9AA1A9" strokeWidth="2.4" mask="url(#path-1-inside-1)" />
    <mask id="path-2-inside-2" fill="white">
      <rect y="8" width="6" height="6" rx="1" />
    </mask>
    <rect y="8" width="6" height="6" rx="1" stroke="#9AA1A9" strokeWidth="2.4" mask="url(#path-2-inside-2)" />
    <rect x="8" y="0.299805" width="6" height="1.2" rx="0.6" fill="#9AA1A9" />
    <rect x="8" y="4.2998" width="6" height="1.2" rx="0.6" fill="#9AA1A9" />
    <rect x="8" y="8.2998" width="6" height="1.2" rx="0.6" fill="#9AA1A9" />
    <rect x="8" y="12.2998" width="6" height="1.2" rx="0.6" fill="#9AA1A9" />
  </svg>
);

import { decorate, observable } from 'mobx';

class Common {
    crumbsData = []; // 面包屑导航数据

    setCrumbs(data = []) { // 设置面包屑导航
      this.crumbsData = data;
    }
}

decorate(Common, {
  crumbsData: observable,
});

export default new Common();

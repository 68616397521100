import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  Icon,
  Affix,
  Popover,
  Badge,
} from 'antd';
// import LinkUs from './linkUs';
// import env from '../../utils/env';
import { apiComplaint } from '../../API';
import FeedbackReadStore from '../../store/feedbackRead';
import './index.scss';

const FeedBack = () => {
  const getRead = async () => {
    // 获取消息是否已读
    try {
      const res = await apiComplaint.getFeedbackReplyState();
      FeedbackReadStore.setUnread(res.unread_cnt || 0);
    } catch {}
  };

  useEffect(() => {
    getRead();
  }, []);

  const { unread } = FeedbackReadStore;
  return (
    <Affix className="feedback">
      <Popover placement="left" content="帮助中心" trigger="hover">
        <a href="https://bytedance.feishu.cn/docx/doxcnCgPpU3qd2YxOEu4S3ajXPd" target="blank">
          <Icon className="fix-icon" type="question-circle" />
        </a>
      </Popover>
      <Popover placement="left" content="反馈中心" trigger="hover">
        <Link to="/mis/linkus">
          <Badge dot={!!unread} style={{ cursor: 'pointer' }}>
            <Icon className="fix-icon" type="edit" />
          </Badge>
        </Link>
      </Popover>
    </Affix>
  );
};
export default observer(FeedBack);

import React from 'react';
import { Route } from 'react-router-dom';
import {
  common,
  systemModule,
  informationModule,
  healthModule,
  actionModule,
  complaintModule,
  userinfoModule,
  localChannelModule,
  productSettingModule,
  topicModule,
  internalMessageModule,
  liveModule,
  pushManageModule,
} from './routerConfig';
import env from '../utils/env';
// eslint-disable-next-line import/no-cycle
import LayAdmin from '../layout';
import Login from '../page/login';
import Appeal from '../page/login/appeal';
import Home from '../page/jkzg/home';
import Detail from '../page/jkzg/detail';
import WorksDetail from '../page/jkzg/worksDetail';
import DoctorAgreementDetail from '../page/jkzg/doctorAgreementDetail';
import InfoDetailPreview from '../page/jkzg/infoDetail';
import GovActivityHome from '../page/action/govActivity/index';
import UpdatePage from '../page/update/index';

export function RouteWithSubRoutes(route) {
  return (
    <Route
      exact={!!route.isChildRoute}
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component
          {...props}
          routes={route.routes}
          menusList={route.menusList}
          setOpenKeys={route.setOpenKeys}
          setSelectedKey={route.setSelectedKey}
        />
      )}
    />
  );
}

const router = [{
  path: '/activity/home',
  name: '首页',
  component: Home,
}, {
  path: '/activity/detail/:acid',
  name: '活动详情',
  component: Detail,
}, {
  path: '/activity/works/:wid?',
  name: '作品详情',
  component: WorksDetail,
}, {
  path: '/activity/doctorAgreement',
  name: '倡议书',
  component: DoctorAgreementDetail,
}, {
  path: '/info/preview',
  name: '作品详情',
  component: InfoDetailPreview,
}, {
  path: '/login',
  name: '登录',
  component: Login,
}, {
  path: '/topic/:id?',
  name: '四部委列表页',
  component: GovActivityHome,
}, {
  path: '/appeal',
  name: '申诉',
  component: Appeal,
},
{
  path: '/update',
  name: '维护',
  component: UpdatePage,
},
{
  path: '/mis',
  name: '通用管理页面',
  component: LayAdmin,
  routes: [
    ...common,
    ...systemModule,
    ...informationModule,
    ...healthModule,
    ...actionModule,
    ...complaintModule,
    ...topicModule,
    ...userinfoModule,
    ...localChannelModule,
    ...productSettingModule,
    ...internalMessageModule,
    ...liveModule,
    ...pushManageModule,
  ],
},
];

if (!env.isJkzg2030) { // 非线上正式环境，默认为活动首页
  router.push({ // '/' 需要在最后一层
    path: '/',
    name: '首页',
    component: Home,
  });
}
export default router;

// 系统管理模块
import Request from '../utils/requestAxios';

class APISys extends Request {
  /**
     * 获取角色列表
     * @param {*} params
     */
  getRoleList(params) {
    return this.fetch({
      url: '/role/list',
      method: 'get',
      params,
    });
  }

  /**
     * 获取人员数据
     * @param {*} params
     */
  getUserList(params) {
    return this.fetch({
      url: '/user/list',
      method: 'get',
      params,
    });
  }

  /**
     * 获取人员数据
     * @param {*} params
     */
  getOperateLogList(params) {
    return this.fetch({
      url: 'operation_log/search',
      method: 'get',
      params,
    });
  }

  /**
     * 获取人员详情数据
     * @param {*} params
     */
  getPersonDetail(params) {
    return this.fetch({
      url: '/user/detail',
      method: 'get',
      params,
    });
  }

  /**
     * 手机号验重
     * @param {*} params
     */
  validatorPhoneNum(params) {
    return this.fetch({
      url: '/phone/detail',
      method: 'get',
      params,
    });
  }

  /**
     * 新增人员
     * @param {*} params
     */
  addUser(params) {
    return this.fetch({
      url: '/user/add',
      method: 'post',
      params,
    });
  }

  /**
     * 编辑、新增人员
     * @param {*} params
     */
  editUser(params) {
    return this.fetch({
      url: '/user/edit',
      method: 'post',
      params,
    });
  }

  /**
     * 删除人员
     * @param {*} params
     */
  delUser(params) {
    return this.fetch({
      url: '/user/delete',
      method: 'post',
      params,
    });
  }

  /**
   * 黄页引导弹框标识
   * @param {*}
   */
  modalCnt(params) {
    return this.fetch({
      url: '/user/modal_cnt',
      method: 'post',
      params,
    });
  }

  /**
     * 获取下线项目列表
     * @param {*} params
     */
  getApplicationList(params) {
    return this.fetch({
      url: '/application_settings/switch_list',
      method: 'get',
      params,
    });
  }

  /**
   * 设置应用下线/上线
   * @param {*}
   */
  setApplicationStatus(params) {
    return this.fetch({
      url: '/application_settings/set_switch',
      method: 'post',
      params,
    });
  }
}

export default new APISys();

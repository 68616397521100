import env from '../utils/env';

class Conf {
    baseAPI = '';

    wsAPI = '';

    infoPreviewUrl = `${location.origin}/#/info/preview`; // 资讯预览地址

    shareInfoHost = env.isProd ? 'https://m.jkzg2030.cn' : 'https://m-test.jkzg2030.cn'; // 已上线资讯分享的H5域名

    imgDomain = ['image.jkzgxd.cn', 'image1.jkzgxd.cn'];

    constructor() {
      this.setBaseUrl();
    }

    setBaseUrl() {
      // const protocolHttp = 'http://'; // 绕过代码检测工具
      const protocolHttps = 'https://';
      const protocolWss = 'wss://';
      let baseAPI = ''; // 空字符串时为webpack-dev-server
      let wsAPI = '';
      switch (true) {
        case env.isDev:
          // boe
          baseAPI = `${protocolHttps}api-test.jkzg2030.cn`;
          baseAPI = '/api';
          wsAPI = '';
          // baseAPI = `${protocolHttp}10.227.84.39:6789`;
          // 本地
          // baseAPI = `${protocolHttp}10.79.43.157:6789`; // 张皓
          break;
        case env.isTest: // 目前后端测试环境只有一个，如果是多个需要映射不同的后端接口
          baseAPI = `${protocolHttps}api-test.jkzg2030.cn`;
          wsAPI = `${protocolWss}api-test.jkzg2030.cn`;
          break;
        case env.isPreview:
          baseAPI = '';
          break;
        case env.isProd:
          baseAPI = `${protocolHttps}api.jkzg2030.cn`;
          wsAPI = `${protocolWss}api.jkzg2030.cn`;
          break;
        default:
          baseAPI = '';
          wsAPI = '';
      }
      this.baseAPI = baseAPI;
      this.wsAPI = wsAPI;
    }
}

export default new Conf();

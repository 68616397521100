/*
健康中国-C端作品、预览详情页
*/
import React, { PureComponent } from 'react';
import {
  Icon, message,
} from 'antd';
import Player from 'xgplayer';
import moment from 'moment';
import {
  apiActivityProduct, apiActivity, apiUpload, apiCommon,
} from '../../../API';
import util from '../../../utils';
import styles from './index.module.scss';
import './index.scss';
import { templateIdMap } from '../../../utils/vol';

class WorksDeail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wid: '', // 作品ID
      acid: '', // 活动ID
      isPreview: false,
      worksDetail: {},
      categoriesObj: {},
      currVideoIndex: 0,
      ifTransWarnShow: false,
    };

    this.videoContainRef = React.createRef();
    this.videoPlayer = null;

    this.methods = this.getMethods();

    this.domEvents = this.getDomEvents();

    this.domEvents.handleSearch = util.debounce(this.domEvents.handleSearch, 300);
  }

  componentDidMount() {
    const { match, location } = this.props;
    const { params = {} } = match || {};
    const { wid = '' } = params || {};
    const { search } = location || {};
    const searchParams = util.getUrlParams(search);
    const { acid = '', status = '' } = searchParams || {}; // aid 活动id
    document.title = '健康中国管理系统';
    this.setState({
      wid,
      acid,
      isPreview: status === 'preview',
    }, () => {
      this.methods.getActivityProduct();
      this.methods.getActivity();
    });
  }

  componentWillUnmount() {
    this.videoPlayer && this.videoPlayer.destroy();
  }

  getMethods() {
    return {
      // 获取活动详情
      getActivityProduct: () => {
        const { wid, isPreview, acid } = this.state;
        let psm = Promise.resolve();
        if (isPreview) {
          psm = apiActivityProduct.getDetail({ id: wid, activity_id: acid });
        } else {
          psm = apiActivityProduct.getDisplayInfo({ id: wid });
        }
        psm.then((data) => {
          const { type, file_info = [] } = data;
          this.setState({
            worksDetail: data,
          }, () => {
            if (type === 'video') {
              // eslint-disable-next-line no-new
              this.methods.getVideo();
            }
            if (type === 'audio') {
              this.methods.getAudioStatus(file_info);
            }
          });
        });
      },
      // 获取音频转码状态
      getAudioStatus: async (audioList) => {
        if (!Array.isArray(audioList)) {
          return;
        }
        let ifAllAudioTrans = true;
        for (let i = 0; i < audioList.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          const status = await this.methods.getTransStatus(audioList[i].vid, 'audio');
          if (status !== 2) {
            ifAllAudioTrans = false;
            break;
          }
        }
        if (!ifAllAudioTrans) {
          this.setState({
            ifTransWarnShow: true,
          });
        }
      },
      // 生成视频
      getVideo: async () => {
        const { videoContainRef } = this;
        const { currVideoIndex, worksDetail = {} } = this.state;
        const { file_info } = worksDetail || {};
        if (!file_info) {
          // 部分历史数据没有文件信息。
          return;
        }
        this.videoPlayer && this.videoPlayer.destroy();
        const { vid } = file_info[currVideoIndex];
        this.methods.getTransStatus(vid, 'video').then((status) => {
          if (status !== 2) {
            this.setState({
              ifTransWarnShow: true,
            });
          }
        });
        // 将链接缓存入state
        let playerUrl = '';
        let playerPoster = '';
        const nowTime = Date.now();
        if ((300 * 1000) > (nowTime - (worksDetail.file_info[currVideoIndex].urlExpireTime || 0))
          && worksDetail.file_info[currVideoIndex].url
          && worksDetail.file_info[currVideoIndex].poster) {
          playerUrl = worksDetail.file_info[currVideoIndex].url;
          playerPoster = worksDetail.file_info[currVideoIndex].poster;
        } else {
          const videoInfo = await apiCommon.getVideoUrl({ vid });
          playerUrl = videoInfo.url;
          playerPoster = videoInfo.poster;
          worksDetail.file_info[currVideoIndex].urlExpireTime = nowTime; // 标记时间 5分钟过期
          worksDetail.file_info[currVideoIndex].url = videoInfo.url;
          worksDetail.file_info[currVideoIndex].poster = videoInfo.poster;
          this.setState({
            worksDetail,
          });
        }

        setTimeout(() => {
          this.videoPlayer = new Player({
            el: videoContainRef.current,
            url: playerUrl,
            width: '736px',
            height: '414px',
            // autoplay: true,
            poster: playerPoster,
          });
        }, 0);
      },
      // 获取转码状态
      getTransStatus: async (vid, type = 'video') => {
        const data = await apiUpload.getTransStatus({
          vid,
          template_id: templateIdMap[type],
        });
        const { status } = data || {};
        return status;
      },
      // 获取活动详情
      getActivity: () => {
        const { acid, isPreview } = this.state;
        let psm = Promise.resolve();
        if (isPreview) {
          psm = apiActivity.getDetail({ id: acid });
        } else {
          psm = apiActivity.getOpenDetail({ id: acid });
        }
        psm.then((data) => {
          const { categories = [] } = data;
          const categoriesObj = {};
          if (Array.isArray(categories)) {
            categories.forEach((cateItem) => {
              categoriesObj[cateItem.id] = cateItem;
            });
          }
          this.setState({
            categoriesObj,
          });
        });
      },
    };
  }

  getDomEvents() {
    return {
      downloadFile: ({
        store_key, vid, name, url,
      }) => {
        if (!store_key && !vid && !url) {
          message.warning('资源不存在');
          return;
        }
        const { data, key } = util.encrypt(vid, name);
        util.downFile({
          store_key, vid, file_name: name, file_url: url, query: { data, key },
        });
      },
      changeVideo: (index) => {
        this.setState({
          currVideoIndex: index,
        }, () => {
          this.methods.getVideo();
        });
      },
    };
  }

  render() {
    const { domEvents, videoContainRef } = this;
    const {
      worksDetail = {}, categoriesObj, currVideoIndex = 0, acid, ifTransWarnShow = false,
    } = this.state;
    const {
      title, author, content,
      enclosures = [], tos_enclosures = [],
      category_1, type, read_count, reward_detail, fab_count, create_time, file_info,
    } = worksDetail;
    const category = categoriesObj[category_1] || {};
    // TOS上传附件
    // 整合新旧附件和推荐信
    let showEnclosures = [];
    if (tos_enclosures && Array.isArray(tos_enclosures)) {
      showEnclosures = [...tos_enclosures];
    } else if (enclosures && Array.isArray(enclosures)) {
      showEnclosures = [...enclosures];
    }
    return (
      <div className={`${styles.wrap} works-page`}>
        <div className={styles.tWrap}>
          <div className={styles.title}>{title}</div>
          <div className={styles.categroy}>{category.name}</div>
        </div>
        <div>
          <div className={styles.authName}>{author}</div>
          <div className={`clearfix ${styles.articleInfo}`}>
            <div className={`fl ${styles.item}`}>
              阅读&nbsp;
              {read_count || 0}
            </div>
            <div className={`fl ${styles.item}`}>
              <Icon type="like" />
              赞 &nbsp;
              {fab_count || 0}
            </div>
            <div className={`fl ${styles.item}`}>{moment(+create_time * 1000).format('YYYY-MM-DD')}</div>
          </div>
        </div>
        { // 入围信息
          reward_detail && (
            <div className={styles.rewardDetailBox}>
              <div className={styles.slogan} />
              <div className={styles.rewardDetailTxt}>
                {reward_detail}
              </div>
            </div>
          )
        }
        <div className={styles.content}>
          <div className={styles.contentTxt} dangerouslySetInnerHTML={{ __html: content }} />
          <div ref={videoContainRef} />
          {
            type === 'video' && Array.isArray(file_info) && file_info.length > 1 && (
              <div className={styles.videoPageBox}>
                {
                  file_info.map((info, index) => (
                    // eslint-disable-next-line max-len
                    <div className={`${styles.videoPage}${index === currVideoIndex ? ` ${styles.videoSelect}` : ''}`} onClick={() => { this.domEvents.changeVideo(index); }}>
                      {index + 1}
                    </div>
                  ))
                }
              </div>
            )
          }
          {
            type === 'audio' && Array.isArray(file_info) && (
              <>
                {
                  file_info.map((info, index) => (
                    <div key={index}>
                      {info.name && <p>{info.name}</p>}
                      <audio
                        controls
                        src={info.url}
                      >
                        <track kind="captions" />
                      </audio>
                    </div>
                  ))
                }
              </>
            )
          }
          {ifTransWarnShow && <div className={styles.transWarn}>提示：正在转码中，如无法播放，请耐心等待…</div>}
          <div className={styles.fileBox}>
            {
              Array.isArray(showEnclosures) && showEnclosures.map((item, index) => (
                acid != 1 ? (
                  <div className={`${styles.fileLi} clearfix`} key={index} onClick={() => domEvents.downloadFile(item)}>
                    <span className="fl"><Icon type="file-text" /></span>
                    <div className={`${styles.fileName} fl`}>{item.name}</div>
                  </div>
                ) : <img className={styles.fileImage} src={item.url} alt="" />
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default WorksDeail;

/*!
 * JavaScript url
 */
const win = window;

export class Url {
  /**
     * ‘http’ or ‘//‘ 开头的返回true
     * @param {*} url
     */
  isAbsoluteUrl(url = '') {
    return url.indexOf('http') === 0 || url.indexOf('//') === 0;
  }

  /**
     * 解析url
     * @param {string} url 'x.com/haha?a=1&b=2#abc' [default location.href]
     * @returns {object} {
            hash: '#abc',
            base: 'x.com/haha',
            search: '?a=1&b=2',
            queryString: 'a=1&b=2',
        }
     */
  parseUrl(url = win.location.href) {
    const hashSplit = url.split('#');
    const hash = hashSplit[1];
    const querySplit = hashSplit[0].split('?');
    const base = querySplit[0];
    const search = querySplit[1];
    return {
      hash: hash ? `#${hash}` : '',
      base,
      search: search ? `?${search}` : '',
      queryString: search || '',
    };
  }

  /**
     * 对象转查询字符串
     * @param {object} obj {a: 1, b: 2}
     * @returns {string} 'a=1&b=2'
     */
  toQueryString(obj, isEmptyToString = false) {
    const s = [];
    if (obj) {
      Object.keys(obj).forEach((key) => {
        const value = this.isEmpty(obj[key]) ? '' : obj[key];
        if ((isEmptyToString && this.isEmpty(obj[key])) || !this.isEmpty(obj[key])) {
          s.push(`${key}=${encodeURIComponent(value)}`);
        }
      });
    }
    return s.join('&');
  }

  isEmpty(vlaue) {
    return vlaue === null || vlaue === undefined || vlaue === '';
  }

  /**
     * 去除对象的空值key和key值的前后空格，key值为对象则作为json字符串
     *
     * 注意：obj instanceof FormData，不做处理
     * @param {object} obj {a: null, b: 2, c: '', d: [1,2], e: {a:1}} => {b: 2, d: '[1,2]', e: '{"a":1}'}
     * @param {boolean} isTrimValue 是否去除前后空格，默认true
     * @param {boolean} isDeleteEmptyKey 是否删除值为空的key，默认true
     */
  trimAndStringify(obj, isTrimValue = true, isDeleteEmptyKey = true) {
    if (obj && !(obj instanceof FormData)) {
      Object.keys(obj).forEach((key) => {
        let v = obj[key];
        v = this.isEmpty(v) ? '' : v;
        if (isDeleteEmptyKey && v === '') {
          delete obj[key]; // 过滤掉空参数
        } else if (typeof v === 'object') {
          obj[key] = JSON.stringify(v); // 对象转json字符串
        } else if (isTrimValue) {
          obj[key] = String(v).trim(); // 去除前后空格
        }
      });
    }
  }

  getPageQuery(href = window.location.href) {
    const params = {};
    let queryStr = '';
    const qsIndex = href.indexOf('?');
    const sharpIndex = href.indexOf('#');
    if (qsIndex !== -1) {
      queryStr = qsIndex > sharpIndex ? href.split('?')[1] : href.slice(qsIndex + 1, sharpIndex);
    }
    const items = queryStr.split('&');
    items.forEach((item) => {
      const kv = item.split('=');
      params[kv[0]] = kv[1] && decodeURIComponent(kv[1]);
    });
    return params || {};
  }

  /**
     * 从url中获取参数名的值
     * @param {string} name 'a'
     * @param {string} url 'x.com?a=1&b=2#abc' [default location.href]
     * @returns {string} ‘1’
     */
  getUrlParam(name, url = win.location.href) {
    return this.getUrlParams(url)[name];
  }

  /**
     * 从url中获取参数对象
     * @param {string} url 'x.com?a=1&b=2#abc' [default location.href]
     * @returns {object} {a: 1, b: 2}
     */
  getUrlParams(url = win.location.href) {
    const params = {};
    const { queryString } = this.parseUrl(url);
    if (queryString) {
      const items = queryString.split('&');
      items.forEach((item) => {
        const kv = item.split('=');
        params[kv[0]] = kv[1] && decodeURIComponent(kv[1]);
      });
    }
    return params;
  }

  /**
     * 向url中添加参数对象
     * @param {object} params {c: 3}
     * @param {string} url 'x.com?a=1&b=2#abc' [default location.href]
     * @returns {string} 'x.com?a=1&b=2&c=3#abc'
     */
  addUrlParams(params = {}, url = win.location.href) {
    const qs = this.toQueryString(Object.assign(this.getUrlParams(url), params));
    const { base, hash } = this.parseUrl(url);
    return `${base}${qs ? `?${qs}` : ''}${hash}`;
  }

  /**
     * 从url中移除参数名数组对应的参数
     * @param {Array} keys ['a', 'c']
     * @param {string} url 'x.com?a=1&b=2&c=3#abc' [default location.href]
     * @returns {string} 'x.com?b=2#abc'
     */
  removeUrlParams(keys = [], url = win.location.href) {
    const params = this.getUrlParams(url);
    keys.forEach((k) => {
      delete params[k];
    });
    const { base, hash } = this.parseUrl(url);
    return this.addUrlParams(params, base) + hash;
  }

  // 新增history.state参数
  addHistoryStateParams(addParams = {}) {
    if (!addParams) {
      return;
    }
    let state = {
      ...addParams,
    };
    if (window.history && window.history.state) {
      state = {
        ...window.history.state,
        ...state,
      };
    }
    window.history.replaceState(
      state,
      '',
      window.location.href
    );
  }

  /**
   * 删除history.state参数
   * @param {Array} delKeys 要删除的字段key
   */
  removeHistoryStateParams(delKeys = []) {
    const { state } = win.history;
    if (state && Array.isArray(delKeys)) {
      delKeys.forEach((key) => {
        delete state[key];
      });
    }
    win.history.replaceState(state, '', win.location.href);
  }

  // 判断是否是路由返回
  historyBack() {
    let isBack = false;
    if (win.history && win.history.state) { // state存在时
      isBack = win.history.state._history_back_ === 1;
      this.removeHistoryStateParams(['_history_back_']); // 返回之后删除_history_back_
    }
    return isBack;
  }

  /**
   * react路由跳转
   * @param {object} reactHistory react 路由
   * @param {object|string} options 路由参数
   *       @param  {string} options.pathname 路经eg: '/path/child'
   *       @param  {string} options.search '?a=1&c=2'
   *       @param  {object} options.state
   *       @param  {object} options.query
   */
  goUrl(reactHistory, options = {}, { needScrollTop = false } = {}) {
    this.addHistoryStateParams({ _history_back_: 1 });
    reactHistory.push(options);

    if (needScrollTop && (document?.documentElement || document?.body)) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }
  }

  goBack(reactHistory) {
    this.addHistoryStateParams({ _history_back_: 1 });
    try {
      reactHistory.back();
    } catch (error) {
      window.history.back();
    }
  }
}

export default new Url();

import env from '@/utils/env';

/**
 * @description 火山云增长分析打点
 * @param {string} eventName 事件名称
 * @param {string} eventType 事件类型
 * @param {object} [params = {}] 自定义维度 value为string类型
 */
const collectEvent = ({
  eventName, eventType = 'click', params = {},
}) => {
  const hc_cms_data_finder = sessionStorage.getItem('hc_cms_data_finder');
  try {
    if (env.isProd || hc_cms_data_finder === 'open') {
      window.collectEvent && window.collectEvent(eventName, {
        event_type: eventType,
        ...params,
      });
    }
  } catch (error) {
    console.error('打点错误', error);
  }
};
export default {
  collectEvent,
};

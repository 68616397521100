/*
健康中国-C端顶部banner
*/
import React, { Component } from 'react';
import { Icon } from 'antd';

import styles from './index.module.scss';
import UserStore from '../../../../store/user';

// eslint-disable-next-line react/prefer-stateless-function
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
    };
    this.domEvents = this.getDomEvents();
  }

  componentDidMount() {
    if (!UserStore.userInfo || !UserStore.userInfo.id) { // 用户信息不存在时获取用户信息
      UserStore.update(false).catch(() => {
        this.setState({
          isLogin: false,
        });
      });
    }
  }

  getDomEvents() {
    return {
      toLogin: () => {
        const { isLogin } = this.state;
        this.props.history.push(`${isLogin ? '/mis/welcome' : '/login'}`);
      },
    };
  }

  render() {
    const { domEvents } = this;
    const { isLogin } = this.state;
    return (
      <div className={styles.topBanner}>
        <div className={styles.bannerContent}>
          <span onClick={domEvents.toLogin}>
            <Icon type="left-circle" theme="filled" className={styles.icon} />
            {isLogin ? '返回后台' : '登录'}
          </span>
        </div>
      </div>
    );
  }
}
export default Banner;

import { decorate, observable } from 'mobx';

class FeedbackRead {
    unread = 0; // 面包屑导航数据

    setUnread(val = 0) { // 设置面包屑导航
      this.unread = val;
    }
}

decorate(FeedbackRead, {
  unread: observable,
});

export default new FeedbackRead();

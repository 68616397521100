import apmPlus from '@apmplus/web';
import env from '@/utils/env';

export const Level = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
};

export const getApmPlusContextConfig = () => {
  // 自定义基础配置
  // 宿主环境
  let client = 'h5';
  if (env.isJKZGApp) {
    client = 'app';
  } else if (env.isWXXCX) {
    client = 'wx';
  }
  return {
    client,
    ua: env.userAgent || (navigator && navigator.userAgent),
    refer: window.location.href,
    pathname: window.location.pathname,
  };
};

export const initApmPlus = async () => {
  // 火山云性能监控 web pro
  // 接入说明文档 https://www.volcengine.com/docs/6431/104857
  try {
    apmPlus('context.merge', {
      ...getApmPlusContextConfig(),
    });
    apmPlus('init', {
      aid: 257234,
      env: env.isProd ? 'production' : 'test',
      plugins: {
        pageview: {
          routeMode: 'hash', // SPA应用 路由模式
        },
        jsError: {
          captureGlobalAsync: true,
        },
        blankScreen: {
          rootSelector: '#root', // 开始计算得分的根元素选择器， 默认从body开始，针对检测部分组件白屏设置此参数
          autoDetect: true, // 是否自动检测
          threshold: 2, // 得分阈值，小于阈值则判断为白屏
          screenshot: true, // 是否在判断为白屏的时候截屏上报
        },
      },
    });
    apmPlus('start');
    window.apmPlus = apmPlus;
    console.log('火山云性能监控 web pro 开启');
  } catch (error) {
    console.error('火山云性能监控 web pro 错误：', error);
  }
};

/**
 * @description 火山云APM 事件打点
 * @param {string} eventName 日志内容(对应事件名称)
 * @param {object} [params = {}] 自定义维度 value为string类型
 * @param {object} [metrics = {}] 自定义数值 value为number类型
 */
export const apmPlusEvent = async ({ eventName, params = {}, metrics = {} }) => {
  try {
    if (apmPlus) {
      apmPlus('sendEvent', {
        name: eventName,
        metrics: {
          value: 1,
          ...metrics,
        },
        categories: params,
      });
    }
  } catch (error) {
    console.error('sendEvent error', error);
  }
};
/**
 * @description 火山云APM 日志打点
 * @param {string} eventName 日志内容(对应事件名称)
 * @param {object} [params = {}] 自定义维度 number和sting类型的分别会被分流至metrics 和 categories
 * @param {string} level 日志级别 枚举 Level
 */
export const apmPlusLog = async ({
  eventName, params = {}, level,
}) => {
  try {
    if (apmPlus) {
      apmPlus('sendLog', {
        content: eventName,
        level,
        extra: {
          ...params,
        },
      });
    }
  } catch (error) {
    console.error('sendLog error', error);
  }
};

/**
 * @description 火山云APM 打点 包括自定义事件和日志
 * @param {string} eventName 日志内容(对应事件名称)
 * @param {object} [params = {}] 自定义维度 value为string类型
 * @param {object} [metrics = {}] 自定义数值 value为number类型
 * @param {string} level 日志级别 枚举 Level
 */
export const apmPlusReport = ({
  eventName, params = {}, metrics = {}, level,
}) => {
  apmPlusEvent({ eventName, params, metrics });
  apmPlusLog({ eventName, params, level });
};

import React from 'react';
import {
  Icon,
  Modal,
} from 'antd';
import { apiCommon } from '../API';
import Tea from '@/utils/collectEvent';
import Storage from '@/utils/Storage';

export default {
  initPushAuditHealthNumberTips: async () => {
    const auditConfig = await apiCommon.getNeedAuditNum({ type: 2 });
    if (auditConfig?.urgent_num >= 5) {
      const now = new Date();
      const prev = Storage.get('pushHealthNumberAuditTip');
      if (prev && ((prev + 86400000) > now.getTime())) {
        return;
      }
      Tea.collectEvent({
        eventName: 'cms_pop_cuishen',
        eventType: 'show',
      });
      if (window.pushAuditHealthNumberModal) {
        return;
      }
      window.pushAuditHealthNumberModal = Modal.confirm({
        title: '医院健康号审核催审提示',
        content: `目前存在 ${auditConfig.urgent_num} 家医院健康号催审，${auditConfig.total_num} 家医院健康号未审，请尽快完成审核。`,
        icon: <Icon type="exclamation-circle" />,
        okText: '立即审核',
        onOk: () => {
          Tea.collectEvent({
            eventName: 'cms_pop_cuishen_shenhe',
            eventType: 'show',
          });
          // 不需要提示认证的拦截
          const _now = new Date();
          Storage.set('pushHealthNumberAuditTip', _now.getTime());
          window.pushAuditHealthNumberModal.destroy();
          setTimeout(() => {
            window.location.href = `${window.location.origin}/#/mis/health/auditlist`;
          }, 200);
        },
        cancelText: '我已知晓',
        onCancel: () => {
          // 不需要提示认证的拦截
          const _now = new Date();
          Storage.set('pushHealthNumberAuditTip', _now.getTime());
          window.pushAuditHealthNumberModal.destroy();
        },
        afterClose: () => {
          window.pushAuditHealthNumberModal = null;
        },
      });
    } else {
      Storage.remove('pushHealthNumberAuditTip');
    }
  },
};

// 环境判断
const {
  location = {},
} = window;
const {
  hostname = '',
} = location || {};
const devHost = ['localhost', 'xx.dev.boe.byted.org', '10.79.40.46', '10.79.42.148', '10.79.41.152', '10.79.41.12', '192.168.0.100']; // 开发环境域名
const testHost = ['test.jkzg2030.cn'];
const prodHost = ['www.jkzg2030.cn'];
const Jkzg2030 = [];
class Env {
    isProd = prodHost.includes(hostname);

    isJkzg2030 = Jkzg2030.includes(hostname);

    isTest = testHost.includes(hostname);

    isPreview = hostname === 'xx';

    isDev = devHost.includes(hostname);
}

export default new Env();

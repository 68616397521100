/*
申诉页面
*/
import React, { PureComponent } from 'react';
import {
  Form, Input, Button, Modal,
} from 'antd';
import { apiCommon } from '../../API';
import Conf from '../../conf/app';
import styles from './appeal.module.scss';
import { GroupLogo } from '../../static/images';
import ImgUpload from '../../component/ImgUpload';

const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 6 },
    sm: { span: 24 },
  },
};
const { TextArea } = Input;
class Appeal extends PureComponent {
    // eslint-disable-next-line react/state-in-constructor
    state = {
      loading: false,
    }

    methods = this.getMethods();

    domEvents = this.getDomEvents();

    componentDidMount() {
      document.title = '健康中国管理平台';
    }

    componentWillUnmount() { }

    getMethods() {
      return {
      };
    }

    getDomEvents() {
      return {
        handleSubmit: () => {
          const { loading } = this.state;
          if (loading) { return; }
          this.props.form.validateFields((err, values) => {
            if (!err) {
              this.setState({
                loading: true,
              });
              values.detail_pic = (values.detail_pic || [])[0].store_key;
              apiCommon.complaint(values).then((data) => {
                if (data === 'ok') {
                  Modal.success({
                    content: '账号申诉提交成功，请您耐心等候',
                    onOk: () => {
                      window.history.back();
                    },
                  });
                }
              }).finally(() => {
                this.setState({
                  loading: false,
                });
              });
            }
          });
        },
      };
    }

    render() {
      const { domEvents } = this;
      const { getFieldDecorator } = this.props.form;
      return (
        <div className={styles.wrap}>
          <div className={styles.outter}>
            <img className={styles.groupLogo} src={GroupLogo} alt="" />
            <div className={styles.formBox}>
              <h1>账号申诉</h1>
              <Form labelAlign="left" {...formItemLayout}>
                <Form.Item label="账号">
                  {getFieldDecorator('account', {
                    rules: [
                      { required: true, message: '请输入您要申诉的手机号' },
                      { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' },
                    ],
                  })(
                    <Input
                      type="text"
                      placeholder="您要申诉的手机号"
                    />
                  )}
                </Form.Item>
                <Form.Item label="信息填写">
                  {getFieldDecorator('description', {
                    rules: [
                      { required: true, message: '请填写申诉原因' },
                      { max: 500, message: '请控制在500字以内' },
                    ],
                  })(
                    <TextArea
                      maxLength={500}
                      placeholder="请填写申诉原因，并提交个人资料及账号使用信息来证明账号归属于自己，字数限制为500字内容可以包括但不限于：创作、收藏、分享过的作品，最近一次的登录时间等"
                    />
                  )}
                </Form.Item>
                <Form.Item label="联系方式（请确保联系方式是否填写正确，会影响申诉结果的发送）">
                  {getFieldDecorator('contact_mobile', {
                    rules: [
                      { required: true, message: '请输入您正在使用的手机号' },
                      { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' },
                    ],
                  })(
                    <Input
                      type="text"
                      placeholder="请输入您正在使用的手机号"
                    />
                  )}
                </Form.Item>
                <Form.Item label="截图">
                  {getFieldDecorator('detail_pic', {
                    rules: [
                      { required: false },
                    ],
                  })(
                    <ImgUpload
                      mode="multiple"
                      fileNum={1}
                      action={`${Conf.baseAPI}/upload/file_public`}
                    />
                  )}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <div className={styles.btmBtnBox}>
                    <Button type="primary" onClick={domEvents.handleSubmit}>
                      提交
                    </Button>
                    {/* <Button onClick={domEvents.handleCanncel}>
                                    取消
                                </Button> */}
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      );
    }
}

export default Form.create({})(Appeal);

/*!
 * JavaScript Storage
 */

const {
  localStorage: ls,
  sessionStorage: ss,
} = window;

export default class Storage {
  static get(key) {
    let value = ls.getItem(key);
    if (value) {
      try {
        value = JSON.parse(value);
            } catch (e) { // eslint-disable-line
      }
    }
    return value;
  }

  static set(key, value) {
    ls.setItem(key, JSON.stringify(value));
  }

  static remove(key) {
    ls.removeItem(key);
  }

  static getSession(key) {
    let value = ss.getItem(key);
    if (value) {
      try {
        value = JSON.parse(value);
            } catch (e) { // eslint-disable-line
      }
    }
    return value;
  }

  static setSession(key, value) {
    // console.log('setSession', Date.now(), value)
    ss.setItem(key, JSON.stringify(value));
  }

  static removeSession(key) {
    ss.removeItem(key);
  }
}

import React, { PureComponent } from 'react';
import {
  Icon, Upload, message, Modal,
} from 'antd';
import PropTypes from 'prop-types';
import Conf from '../../conf/app';
import Token from '../../utils/token';
import util from '../../utils';
import defaultImg from '../../static/images/defaultImg.png';
import './index.scss';

class ImgUpload extends PureComponent {
  // setState 执行之后会执行getDerivedStateFromProps
  static getDerivedStateFromProps(nextProps, state) {
    let newFileList = [];
    if (!nextProps.value) { // value不存在时不更新
      return null;
    }
    if (nextProps.mode === 'single') {
      newFileList = [{
        uid: 'index_0',
        name: nextProps.value,
        url: nextProps.value,
      }];
    } else {
      newFileList = nextProps.value || [];
    }
    if (
      (Array.isArray(state.prevFileList) && newFileList.length !== state.prevFileList.length) // 优先对比长度
      || JSON.stringify(newFileList) !== JSON.stringify(state.prevFileList)
    ) {
      return {
        fileList: newFileList,
        prevFileList: newFileList,
      };
    }
    // 不需要更新
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      previewVisible: false,
      previewImage: '',
      fileList: [],
      prevFileList: [],
    };
    this.validateOpenStatus = false;
    this.methods = this.getMethods();
    this.domEvents = this.getDomEvents();
  }

  getMethods() {
    return {
      /**
       * 触发change事件，输出结果
       * @param {Array|string} outputValue
       */
      triggerChange: (outputValue) => {
        const { onChange } = this.props;
        onChange && onChange(outputValue);
      },
      /**
       * 标准化文件列表
       * @param {Array} fileList
       * @return {Array} 返回一个新的文件列表newFileList
       */
      standardOutputFileList: (fileList) => {
        if (Array.isArray(fileList)) {
          fileList = fileList.map((_file) => {
            const {
              uid, name, status, response, url = '',
            } = _file;
            const newItem = {
              uid,
              name,
              status,
              response,
              url: url || '',
            };
            delete newItem.response;
            if (util.isObject(response)) {
              const { data, msg } = response;
              if (response.code === 0) {
                newItem.store_key = data.key;
                newItem.url = data.url;
              } else {
                newItem.response = msg || '上传失败'; // 上传多张图片时，错误信息会展示在列表中
              }
            }
            return newItem;
          });
        }
        return fileList;
      },
    };
  }

  getDomEvents() {
    return {
      onChange: ({ file, fileList = [] }) => {
        const { mode } = this.props;
        const { response } = file || {};
        const isFail = file.status === 'error' || (response && response.code !== 0);
        const isSucess = file.status === 'done' && response && response.code === 0;
        let fileIndex = -1; // 当前file在fileList中的索引
        if (mode === 'single') {
          fileList = [file];
          if (isFail || file.status === 'removed') {
            file.status = 'removed';
            fileList = []; // 单张上传清空列表
            // 单张图片上传失败时，单独toast提醒
            isFail && message.error('上传失败');
          }
        }
        fileIndex = fileList.findIndex((_file) => file.uid === _file.uid);
        fileList = this.methods.standardOutputFileList(fileList); // 输出标准格式的文件列表
        if (isFail && fileIndex !== -1) { // error 或code !== 0都置为error
          fileList[fileIndex].status = 'error';
        }
        if (!file.status && fileIndex !== -1) {
          fileList.splice(fileIndex, 1);
        }
        if (file.status === 'removed' || isSucess) { // 上传成功或删除触发结果输出事件
          let outputValue = [...fileList];
          if (mode === 'single') {
            const firstItem = outputValue[0];
            if (firstItem && firstItem.url) { // 上传成功，默认输出第一项
              outputValue = firstItem.url;
            }
            if (!firstItem || (firstItem && (firstItem.status === 'error' || !firstItem.url))) { // 删除为空时和上传失败
              outputValue = undefined;
            }
          }
          this.methods.triggerChange(outputValue); // 触发输出
        }
        this.setState({
          fileList: [...fileList],
        });
      },
      beforeUpload: (file) => {
        const {
          fileMaxSize = 5, fileType, compression,
        } = this.props;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        const isLt5M = file.size / 1024 / 1024 <= fileMaxSize;
        // if (fileList.length >= fileNum || (mode === 'single' && fileList.length >= 1)) {
        //   message.error('超过最大上传数量！');
        //   return;
        // }
        if (fileType === 'pictrue' && !isJpgOrPng) {
          message.error('请上传jpg/png格式的文件！');
          return false;
        }
        if (!isLt5M) {
          message.error(`文件不能超过${fileMaxSize}MB！`);
          return false;
        }
        this.setState({
          data: { file_name: file.name, type: compression ? 1 : 0 },
        });
        return true;
      },
      // 预览图片
      handlePreview: async (file) => {
        this.setState({
          previewImage: file.url || file.preview,
          previewVisible: true,
        });
      },
      // 取消预览
      handleCancel: () => {
        this.setState({ previewVisible: false });
      },
    };
  }

  render() {
    const { domEvents } = this;
    const {
      fileNum, fileType, mode, action, tipText, tipClass, ...props
    } = this.props;
    const {
      data,
      previewVisible = false,
      previewImage = '',
    } = this.state;
    let { fileList = [] } = this.state;
    const fileLength = mode === 'single' ? 1 : fileNum;
    const defaultProps = {
      name: 'file',
      accept: '.zip,.rar,.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*',
      action,
      headers: { // IE10 以上有效
        [Token.reqKey]: Token.getToken(),
      },
      ...props,
    };
    if (Array.isArray(fileList)) {
      fileList = fileList.filter((item, index) => {
        if (!item) {
          return false;
        }
        if (item && !item.uid) {
          item.uid = `uid_${index}`;
        }
        return true;
      });
    }
    const children = [];
    if (!this.props.children && fileList.length < fileLength) {
      children.push(
        <div key="plus">
          <Icon type="plus" />
          上传
        </div>
      );
    }
    if (this.props.children) {
      children.push(this.props.children);
    }
    return (
      <span style={{ position: 'relative' }}>
        <Upload
          {...defaultProps}
          multiple={mode === 'multiple'}
          data={data}
          listType="picture-card"
          fileList={fileList || []}
          beforeUpload={domEvents.beforeUpload}
          onChange={domEvents.onChange}
          onPreview={domEvents.handlePreview}
        >
          {
            children.length > 0 ? children : undefined
          }
        </Upload>
        <p className={tipClass}>{tipText}</p>
        <Modal visible={previewVisible} footer={null} onCancel={domEvents.handleCancel}>
          <img
            alt="example"
            style={{ width: '100%' }}
            src={previewImage}
            onError={(e) => {
              e.target.src = defaultImg;
            }}
          />
        </Modal>
      </span>
    );
  }
}
// 可以通过ImgUpload组件透传Upload的组件参数（除了Upload的multiple、fileMaxSize、fileNum、value、listType、fileList、beforeUpload、onChange、onPreview）
/**
 * mode === single, value: "https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/b63a0f5ed4b6448c9516c89b3985b4ee~tplv-a3h8qa8x9l-png.png?"
 * mode === multiple, value: [{url: 'https://image.jkzgxd.cn/tos', name: 'demo'}]
 */
ImgUpload.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mode: PropTypes.string, // multiple、single
  accept: PropTypes.string, // 上传文件类
  fileMaxSize: PropTypes.number, // 上传文件大小，单位：Mb
  fileNum: PropTypes.number, // 文件个数 mode === multiple 有效
  action: PropTypes.string, // 上传地址
  tipText: PropTypes.string, // 上传提示文字
  tipClass: PropTypes.string, // 提示文字类名
  compression: PropTypes.bool, // 图片是否压缩
};

ImgUpload.defaultProps = {
  mode: 'multiple', // multiple: 能上传多张图片；single: 单张图片
  fileMaxSize: 5,
  fileNum: 20, // mode === multiple 有效
  accept: 'image/png,image/jpeg,image/jpg',
  action: `${Conf.baseAPI}/upload/file`,
  tipText: '',
  tipClass: '',
  compression: false,
};
export default ImgUpload;

import React, { Component } from 'react';
import { Button } from 'antd';

import './index.scss';
// import bgContent from './img/header_content.png';
import ApiGovTopic from '../../../API/apiGovTopic';
import { stamp2time } from '../../../utils/time';
import UserStore from '../../../store/user';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity_list: [],
      isLogin: true,
      head_pics: [],
    };
    this.domEvents = this.getDomEvents();
    this.methods = this.getMethods();
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    this.methods.getActivityList(id);
    if (!UserStore.userInfo || !UserStore.userInfo.id) { // 用户信息不存在时获取用户信息
      UserStore.update(false).catch(() => {
        this.setState({
          isLogin: false,
        });
      });
    }
  }

  getDomEvents() {
    return {
      toDetail: (id) => {
        this.props.history.push(`/activity/detail/${id}`);
      },
      toLogin: () => {
        const { isLogin } = this.state;
        this.props.history.push(`${isLogin ? '/mis/welcome' : '/login'}`);
      },
    };
  }

  getMethods() {
    return {
      getActivityList: (id = '') => {
        const params = {
          key: `gov_${id}`,
        };
        ApiGovTopic.getTopicList(params).then((data) => {
          this.setState({
            activity_list: (data.activity_list && data.activity_list.items) || [],
            head_pics: data.pics || [],
          });
        });
      },
    };
  }

  render() {
    const { domEvents } = this;
    const { activity_list, isLogin, head_pics } = this.state;
    return (
      <div className="gov-topic-container">
        {
          Array.isArray(head_pics) && (
            head_pics[0] && (
              <div className="header" style={{ background: `url('${head_pics[0]}') no-repeat center` }}>
                {head_pics[1] && <img src={head_pics[1]} alt="" />}
              </div>
            )
          )
        }
        <div className="button-box">
          <Button type="primary" onClick={domEvents.toLogin}>
            {isLogin ? '返回后台' : '登录'}
          </Button>
        </div>
        <div className="card-container">
          <div className="card-box">
            {
              Array.isArray(activity_list) && activity_list.map((item) => (
                <div key={item.id} className="card" onClick={() => { domEvents.toDetail(item.id); }}>
                  <img className="thumbnail" src={item.thumbnail || ''} alt="" />
                  <div className="info-box">
                    <div className="title">{item.name || ''}</div>
                    <div className="time">{`${stamp2time((item.collect_start_time || 0) * 1000, false)}至${stamp2time((item.collect_end_time * 1000 || 0), false)}`}</div>
                    <div className="work-count">{`${item.product_count || 0}作品`}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Index;

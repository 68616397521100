// 专题模块
import Request from '../utils/requestAxios';
import Conf from '@/conf/app';
import util from '@/utils';
import Token from '@/utils/token';

class ApiLive extends Request {
  /**
   * 创建直播
   * @param {*} params
   */
  createLive(params) {
    return this.fetch({
      url: '/live/create',
      method: 'post',
      params,
    });
  }

  /**
   * 更新直播
   * @param {*} params
   */
  updateLive(params) {
    console.log('updateLive:', params);
    return this.fetch({
      url: '/live/update',
      method: 'post',
      params,
    });
  }

  /**
   * 获取直播页信息
   * @param {*} params
   */
  getLiveInfo(params) {
    return this.fetch({
      url: '/live/info',
      method: 'get',
      params,
    });
  }

  /**
   * 获取直播列表
   * @param {*} params
   */
  getLiveList(params) {
    return this.fetch({
      url: '/live/list',
      method: 'get',
      params,
    });
  }

  /**
   * 获取直播审批列表
   * @param {*} params
   */
  getLiveReviewList(params) {
    return this.fetch({
      url: '/live/review_list',
      method: 'get',
      params,
    });
  }

  /**
   * 修改直播状态
   * @param {*} params
   */
  setLiveStatus(params) {
    return this.fetch({
      url: '/live/set_status',
      method: 'POST',
      params,
    });
  }

  /**
   * 上传直播回放
   * @param {*} params
   */
  uploadLivePlayback(params) {
    return this.fetch({
      url: '/live/playback',
      method: 'POST',
      params,
    });
  }

  /**
   * 直播观看人数配置
   * @param {*} params
   */
  updateLiveView(params) {
    return this.fetch({
      url: '/live/update_view',
      method: 'POST',
      params,
    });
  }

  /**
   * 直播置顶
   * @param {*} params
   */
  setLiveTop(params) {
    return this.fetch({
      url: '/live/set_top',
      method: 'POST',
      params,
    });
  }

  /**
   * 取消直播置顶
   * @param {*} params
   */
  cancelLiveTop(params) {
    return this.fetch({
      url: '/live/cancel_top',
      method: 'POST',
      params,
    });
  }

  /**
   * 导出参会人信息
   * @param {*} params
   */
  exportParticipant(uid) {
    const href = `${Conf.baseAPI}/live/export_participant/?token=${Token.getToken()}&uid=${uid}`;
    util.downFileByAtag(href);
  }

  /**
   * 导出手机号信息
   * @param {*} params
   */
  exportPhone(id) {
    const href = `${Conf.baseAPI}/live/reserve_export/?token=${Token.getToken()}&id=${id}`;
    util.downFileByAtag(href);
  }
}

export default new ApiLive();

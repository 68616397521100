// 健康号
import Request from '../utils/requestAxios';

class ApiHospInfo extends Request {
  /**
     * 获取健康号审核列表
     * @param {*} params
     */
  getHospAuditList(params) {
    return this.fetch({
      url: '/healthynumber/douyin_hospital_review_list',
      method: 'GET',
      params,
    });
  }

  /**
   * 获取健康号信息
   * @param {*} params
   */
  getHospInfo(params) {
    return this.fetch({
      url: '/healthynumber/douyin_hospital_info',
      method: 'get',
      params,
    });
  }

  /**
   * 获取健康号审核列表审核记录
   * @param {*} params
   */
  getHealthAuditReviewLogList(params) {
    return this.fetch({
      url: '/healthynumber/douyin_hospital_review_log',
      method: 'GET',
      params,
    });
  }

  /**
   * 获取健康号审核变更对比查询
   * @param {*} params
   */
  getHealthAuditSnapshotDetail(params) {
    return this.fetch({
      url: '/healthynumber/douyin_hospital_snapshot_detail',
      method: 'GET',
      params,
    });
  }

  /**
   * 获取健康号审核变更对比查询
   * @param {*} task_id 审核单id
   * @returns {number} status 审核状态 1待审核 2通过 3驳回
   */
  getHospAuditStatus(params) {
    return this.fetch({
      url: '/healthynumber/douyin_hospital_audit_task_info',
      method: 'GET',
      params,
    });
  }

  /**
     * 健康号列表 审核 , 下线
     * @param {*} params
     * @id 医院
     * @audit_res 1 通过 2 拒绝
     * @status_content 理由
     */
  updateHospAuditStatus(params) {
    return this.fetch({
      url: '/healthynumber/douyin_hospital_audit',
      method: 'POST',
      params,
    });
  }
}

export default new ApiHospInfo();
